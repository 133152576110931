import React from 'react'
import { Message, Label, Checkbox, Menu, Divider, Dropdown, Header, Icon, Button, Segment, Grid, Tab, Form, Input, Radio, GridRow } from 'semantic-ui-react'
import HolidaysTable from './HolidaysTable'
import LeaveHistory from './LeaveHistory'
import LeaveApprovals from './LeaveApprovals'
import LeaveDelegations from './LeaveDelegations'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useEffect, useState } from "react";
import "./lms.css";
import { useAuth } from '../context/AuthContext'
import moment from 'moment';

function handleName(name) {
  if (name === "CL") {
    return "Casual Leaves";
  }
  else if (name === "HPL") {
    return "HalfPay Leaves";
  }
  else if (name === "EL") {
    return "Earned Leaves";
  }
  else if (name === "VL") {
    return "Vacation Leaves"
  }
  else if (name === "SCL") {
    return "Special Casual Leaves";
  }
  else if (name === "RH") {
    return "Restricted Holidays";
  }
  else if (name === "PROL") {
    return "Project Leaves";
  }
  else if (name === "COL") {
    return "Commuted Leaves";
  }
  else {
    return "No Leave Found"
  }
}

function LeaveBalance(props) {
  let isLeaveBalanceLoading = props.isLeaveBalanceLoading
  let LeaveBalanceList = props.LeaveBalanceList
  let CLRemaining = LeaveBalanceList.length == 0 ? 0 : LeaveBalanceList.find(x => x.leavetype == 'CL') != null ? LeaveBalanceList.find(x => x.leavetype == 'CL').remaining : null;
  let ELRemaining = LeaveBalanceList.length == 0 ? 0 : LeaveBalanceList.find(x => x.leavetype == 'EL') != null ? LeaveBalanceList.find(x => x.leavetype == 'EL').remaining : null;
  let HPLRemaining = LeaveBalanceList.length == 0 ? 0 : LeaveBalanceList.find(x => x.leavetype == 'HPL') != null ? LeaveBalanceList.find(x => x.leavetype == 'HPL').remaining : null;
  let COLRemaining = LeaveBalanceList.length == 0 ? 0 : LeaveBalanceList.find(x => x.leavetype == 'COL') != null ? LeaveBalanceList.find(x => x.leavetype == 'COL').remaining : null;
  // let VLRemaining = LeaveBalanceList.length == 0 ? 0 : LeaveBalanceList.find(x => x.leavetype == 'VL').remaining;
  // let HLRemaining = LeaveBalanceList.length == 0 ? 0 : LeaveBalanceList.find(x => x.leavetype == 'HL').remaining;
  const groupSize = 4;
  const balanceGroups = [];
  LeaveBalanceList = LeaveBalanceList.filter(function(item) {
    return item.leavetype !== 'COL'
})
  for (let i = 0; i < LeaveBalanceList.length; i += groupSize) {
   // if(LeaveBalanceList[i].leavetype!=='COL'){
      balanceGroups.push(LeaveBalanceList.slice(i, i + groupSize));
    //}
  }

//   balanceGroups = balanceGroups.filter(function(item) {
//     return item.leavetype !== 'COL'
// })
  return (
    //     <div class="ui label">
    //   <i class="mail icon"></i>
    //   23
    //   <a class="detail">View Mail</a>
    // </div>
    <Segment loading={isLeaveBalanceLoading}>
      {/* <Divider horizontal>
      <Header as='h4'>
        <Icon name='tag' />
        Leave Balance
      </Header>
    </Divider> */}
      <Header textAlign="center">
        Leave Balance
      </Header>
      <Grid stackable celled columns={4} textAlign="center">
        {balanceGroups.map((group, rowIndex) => (
          <Grid.Row key={rowIndex}>
            {group.map((balance, columnIndex) => ( balance.leavetype!=='COL' && (
              <Grid.Column key={columnIndex}>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>{handleName(balance.leavetype)}: </label>
                    <label>{balance.remaining == null ? 'Not Available' : balance.remaining}</label>
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            )
              
            ))}
            {/* Fill in empty columns if needed */}
            {group.length < groupSize && (
              Array.from({ length: groupSize - group.length }).map((_, columnIndex) => (
                <Grid.Column key={group.length + columnIndex} >-</Grid.Column>
              ))
            )}
          </Grid.Row>
        ))}
      </Grid>
    </Segment>
  )
}



export default LeaveBalance