import React, { useState, useEffect, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import AuthContext from '../context/AuthContext';
import LMSTab from './LMSTab';
import { handleLogError } from '../misc/Helpers';
import { iipeerpapi } from '../misc/iipeerpapi';

const LMSPage = () => {
  const { getUser, userIsAuthenticated } = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [orderDescription, setOrderDescription] = useState('');
  const [orderTextSearch, setOrderTextSearch] = useState('');
  const [userUsernameSearch, setUserUsernameSearch] = useState('');
  const [isAdmin, setIsAdmin] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isOrdersLoading, setIsOrdersLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isApprover, setIsApprover] = useState(true);
  const [isHolidaysLoading, setIsHolidaysLoading] = useState(false);
  const [HolidaysList, setHolidaysList] = useState([]);
  const [LeaveBalanceList, setLeaveBalanceList] = useState([]);
  const [isLeaveBalanceLoading, setIsLeaveBalanceLoading] = useState(false);
  const [userdetails, setUserdetails] = useState(null);

  useEffect(() => {
    const user = getUser();
    const isAdmin = user.data.rol[0] === 'ADMIN';
    setIsAdmin(isAdmin);
    setIsLoggedIn(userIsAuthenticated());

    handleGetLeaveBalance();
    handleGetUsers();
    handleGetHolidays();
    handleGetFulllUserDetails();
  }, [getUser, userIsAuthenticated]);

  const handleInputChange = (e, { name, value }) => {
    switch (name) {
      case 'orderDescription':
        setOrderDescription(value);
        break;
      case 'orderTextSearch':
        setOrderTextSearch(value);
        break;
      case 'userUsernameSearch':
        setUserUsernameSearch(value);
        break;
      default:
        break;
    }
  };

  const handleGetHolidays = () => {
    setIsHolidaysLoading(true);
    iipeerpapi.getHolidays()
      .then(response => setHolidaysList(response.data))
      .catch(handleLogError)
      .finally(() => setIsHolidaysLoading(false));
  };

  const handleGetFulllUserDetails = () => {
    const user = getUser();
    setIsUsersLoading(true);
    iipeerpapi.getFullUserDetails(user)
      .then(response => setUserdetails(response.data))
      .catch(handleLogError)
      .finally(() => setIsUsersLoading(false));
  };

  const handleGetLeaveBalance = () => {
    const user = getUser();
    setIsLeaveBalanceLoading(true);
    iipeerpapi.getLeaveBalance(user)
      .then(response => setLeaveBalanceList(response.data))
      .catch(handleLogError)
      .finally(() => setIsLeaveBalanceLoading(false));
  };

  const handleGetUsers = () => {
    const user = getUser();
    setIsUsersLoading(true);
    iipeerpapi.getUsers(user)
      .then(response => setUsers(response.data))
      .catch(handleLogError)
      .finally(() => setIsUsersLoading(false));
  };

  const handleDeleteUser = (username) => {
    const user = getUser();
    iipeerpapi.deleteUser(user, username)
      .then(() => handleGetUsers())
      .catch(handleLogError);
  };

  const handleSearchUser = () => {
    const user = getUser();
    const username = userUsernameSearch;
    iipeerpapi.getUsers(user, username)
      .then(response => {
        const data = response.data;
        setUsers(Array.isArray(data) ? data : [data]);
      })
      .catch(error => {
        handleLogError(error);
        setUsers([]);
      });
  };

  const handleGetOrders = () => {
    const user = getUser();
    setIsOrdersLoading(true);
    iipeerpapi.getOrders(user)
      .then(response => setOrders(response.data))
      .catch(handleLogError)
      .finally(() => setIsOrdersLoading(false));
  };

  const handleDeleteOrder = (isbn) => {
    const user = getUser();
    iipeerpapi.deleteOrder(user, isbn)
      .then(() => handleGetOrders())
      .catch(handleLogError);
  };

  const handleCreateOrder = () => {
    const user = getUser();
    const trimmedOrderDescription = orderDescription.trim();
    if (!trimmedOrderDescription) return;

    const order = { description: trimmedOrderDescription };
    iipeerpapi.createOrder(user, order)
      .then(() => {
        handleGetOrders();
        setOrderDescription('');
      })
      .catch(handleLogError);
  };

  const handleSearchOrder = () => {
    const user = getUser();
    const text = orderTextSearch;
    iipeerpapi.getOrders(user, text)
      .then(response => setOrders(response.data))
      .catch(error => {
        handleLogError(error);
        setOrders([]);
      });
  };

  if (!isApprover) {
    return <Navigate to='/' />;
  }

  return (
    <Container>
      <Routes>
        <Route path="/" element={
          <LMSTab
            isUsersLoading={isUsersLoading}
            users={users}
            userUsernameSearch={userUsernameSearch}
            handleDeleteUser={handleDeleteUser}
            handleSearchUser={handleSearchUser}
            isOrdersLoading={isOrdersLoading}
            orders={orders}
            orderDescription={orderDescription}
            orderTextSearch={orderTextSearch}
            handleCreateOrder={handleCreateOrder}
            handleDeleteOrder={handleDeleteOrder}
            handleSearchOrder={handleSearchOrder}
            handleInputChange={handleInputChange}
            HolidaysList={HolidaysList}
            LeaveBalanceList={LeaveBalanceList}
            isLeaveBalanceLoading={isLeaveBalanceLoading}
            userdetails={userdetails}
            handleGetLeaveBalance={handleGetLeaveBalance}
          />
        } />
        <Route path="/:tabName" element={
          <LMSTab
            isUsersLoading={isUsersLoading}
            users={users}
            userUsernameSearch={userUsernameSearch}
            handleDeleteUser={handleDeleteUser}
            handleSearchUser={handleSearchUser}
            isOrdersLoading={isOrdersLoading}
            orders={orders}
            orderDescription={orderDescription}
            orderTextSearch={orderTextSearch}
            handleCreateOrder={handleCreateOrder}
            handleDeleteOrder={handleDeleteOrder}
            handleSearchOrder={handleSearchOrder}
            handleInputChange={handleInputChange}
            HolidaysList={HolidaysList}
            LeaveBalanceList={LeaveBalanceList}
            isLeaveBalanceLoading={isLeaveBalanceLoading}
            userdetails={userdetails}
            handleGetLeaveBalance={handleGetLeaveBalance}
          />
        } />
      </Routes>
    </Container>
  );
};

export default LMSPage;
