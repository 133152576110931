import React, { useState, useRef, useContext } from 'react';
import { Modal, Header, Segment, Form, Grid, Divider, Button, Icon, Radio } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

import  AuthContext  from '../context/AuthContext';

import Toasts from '../misc/Toasts';
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'

const SubmitLeaveJoiningFormModal = ({ 
    leaveowner,
    handleShowSubmitLeaveJoiningFormModal,
    leaveId,
    userdetails,    
    handleGetLeaves
}) => {
  const [inputLeaveStartDate, setInputLeaveStartDate] = useState('');
  const [inputLeaveEndDate, setInputLeaveEndDate] = useState('');
  const [inputJoiningDate, setInputJoiningDate] = useState('');
  const [startdaysession, setStartDaySession] = useState('FN');
  const [lastdaysession, setLastDaySession] = useState('AN');
  const [joiningdaysession, setJoiningDaySession] = useState('FN');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [joiningPayload, setJoiningPayload] = useState({});
  const [showCurrentModal, setShowCurrentModal] = useState(true);
console.log(userdetails)
  const fileInputRef = useRef(null);
  const Auth = useContext(AuthContext);
  const handleCurrentModalClose = () => {
    setShowCurrentModal(false);
    handleShowSubmitLeaveJoiningFormModal(false)
  };

  const handleSubmit = (e) => {
    if (isFormValid()) {
      handleSubmitFormCall();
      //handleShowSubmitLeaveJoiningFormModal(false);
    }
  };

  const handleSubmitFormCall = async () => {
    const user = Auth.getUser();
    let fileIds = '';

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await iipeerpapi.fileUpload(user, formData);
        fileIds = response.data.fileId;
      } catch (error) {
        handleLogError(error);
        Toasts.notifyError("Error in uploading attachment!");
        return;
      }
    }

    const updatedJoiningPayload = {
      ...joiningPayload,
      attachments_details: fileIds,
      leavejoiningapprovals: userdetails.leavejoiningApprovalUsers.map((user, index) => ({
        username: user.username,
        status: '',
        approversequence: index + 1
      })),
      leaveids: [leaveId],
      startdate: inputLeaveStartDate,
      lastdate: inputLeaveEndDate,
      joiningdate: inputJoiningDate,
      startdaysession,
      lastdaysession,
      joiningdaysession
    };

    try {
      await iipeerpapi.leavejoiningform(user, updatedJoiningPayload);
      handleGetLeaves();
      Toasts.notifySuccess("Leave Joining Form Submitted Successfully!");
      handleCurrentModalClose()
      handleShowSubmitLeaveJoiningFormModal(false)
      

    } catch (error) {
      Toasts.notifyError("Something Went Wrong!");
    }
    
    
  };

  const isFormValid = () => {
    return inputLeaveStartDate && inputLeaveEndDate && inputJoiningDate;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      setFile(selectedFile);
    }
    e.target.value = null;
  };

  const clearFile = () => {
    setFileName('');
    setFile(null);
  };

  const RadioExampleRadioGroupJoiningDay = () => {
    const handleChange = (event, { value }) => {
      setJoiningDaySession(value);
    };

    return (
      <Form widths='equal'>
        <Form.Group>
          <Form.Field>
            <Radio
              label='From Fore Noon'
              name='radioGroup'
              value='FN'
              checked={joiningdaysession === 'FN'}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='From After Noon'
              name='radioGroup'
              value='AN'
              checked={joiningdaysession === 'AN'}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  const RadioExampleRadioGroupFirstDay = () => {
    const handleChange = (event, { value }) => {
      setStartDaySession(value);
    };

    return (
      <Form widths='equal'>
        <Form.Group>
          <Form.Field>
            <Radio
              label='From Fore Noon'
              name='radioGroup'
              value='FN'
              checked={startdaysession === 'FN'}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='From After Noon'
              name='radioGroup'
              value='AN'
              checked={startdaysession === 'AN'}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  const RadioExampleRadioGroupLastDay = () => {
    const handleChange = (event, { value }) => {
      setLastDaySession(value);
    };

    return (
      <Form widths='equal'>
        <Form.Group>
          <Form.Field>
            <Radio
              label='To Fore Noon End'
              name='radioGroup'
              value='FN'
              checked={lastdaysession === 'FN'}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='To After Noon End'
              name='radioGroup'
              value='AN'
              checked={lastdaysession === 'AN'}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  };

  return (
    <Modal closeIcon open={showCurrentModal} onClose={()=>handleCurrentModalClose()}>
      <Header textAlign='center' as='h2' color='blue'>Submit Leave Joining Form</Header>
      <Modal.Content image scrolling>
        <Modal.Description>
          <Form>
            <Grid stackable textAlign='center'>
              <Grid.Row>
                <Grid.Column>
                  <Segment>
                    <Grid columns={2} stackable textAlign='center'>
                      <Divider vertical> Duration </Divider>

                      <Grid.Row verticalAlign='top'>
                        <Grid.Column>
                          <SemanticDatepicker
                            label="Start date"
                            id="inputLeaveStartDate"
                            format="DD-MMMM-YYYY"
                            value={inputLeaveStartDate}
                            onChange={(e, data) => setInputLeaveStartDate(data.value)}
                            required
                          />
                          <RadioExampleRadioGroupFirstDay />
                        </Grid.Column>

                        <Grid.Column>
                          <SemanticDatepicker
                            label="End date"
                            id="inputLeaveEndDate"
                            format="DD-MMMM-YYYY"
                            value={inputLeaveEndDate}
                            onChange={(e, data) => setInputLeaveEndDate(data.value)}
                            required
                          />
                          <RadioExampleRadioGroupLastDay />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>

                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment.Group horizontal>
                          <Segment>
                            <SemanticDatepicker
                              label="Joining Date"
                              id="inputJoiningDate"
                              format="DD-MMMM-YYYY"
                              value={inputJoiningDate}
                              onChange={(e, data) => setInputJoiningDate(data.value)}
                              required
                            />
                            <RadioExampleRadioGroupJoiningDay />
                          </Segment>
                        </Segment.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  {/* File upload section */}
                  <Segment>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <label>Attachments</label>
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.Field>
                        <Button
                          content="Choose File"
                          labelPosition="left"
                          icon="file"
                          onClick={(e) => { e.preventDefault(); fileInputRef.current.click(); }}
                        />
                        <input
                          type="file"
                          ref={fileInputRef}
                          hidden
                          onChange={handleFileChange}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Button
                          content="Clear"
                          onClick={clearFile}
                        />
                      </Form.Field>
                    </Form.Group>
                    {fileName && <Form.Group><Form.Field><label>Chosen File: {fileName}</label></Form.Field></Form.Group>}
                  </Segment>

                  {/* Approvals */}
                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Divider horizontal>
                          <Header as='h4'>
                            <Icon name='tag' />
                            Approvals
                          </Header>
                        </Divider>
                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>1st Approver</label>
                            <Segment basic size='small'>{userdetails.leavejoiningApprovalUsers[0]?.fullname}</Segment>
                          </Form.Field>
                          <Form.Field>
                            <label>2nd Approver</label>
                            <Segment basic size='small'>{userdetails.leavejoiningApprovalUsers[1]?.fullname}</Segment>
                          </Form.Field>
                          <Form.Field>
                            <label>3rd Approver</label>
                            <Segment basic size='small'>{userdetails.leavejoiningApprovalUsers[2]?.fullname}</Segment>
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
      <Button positive onClick={()=>handleSubmit()}>Submit</Button>
        <Button negative onClick={()=>handleCurrentModalClose()}>Close</Button>
        
      </Modal.Actions>
    </Modal>
  );
};

export default SubmitLeaveJoiningFormModal;
