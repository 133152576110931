import React, { useState, useEffect, useContext } from 'react';
import { Modal, Button, Segment, Form , CommentText,CommentMetadata,CommentGroup,CommentContent, CommentAvatar,CommentActions,CommentAction,CommentAuthor,Comment, Icon,} from 'semantic-ui-react';
import AuthContext from '../context/AuthContext';
import { iipeerpapi } from '../misc/iipeerpapi';
import datetimeutils from '../utils/DateTimeUtils';
import Toasts from '../misc/Toasts';

const CommentsPage = ({ entityId, entity, onClose }) => {
    const [open, setOpen] = useState(!!entityId);
    const [getCommentData, setGetCommentData] = useState(false);
    const [pdfContent, setPdfContent] = useState('');
    const [showPdfView, setShowPdfView] = useState(false);
    const [showCommentInnerModal, setCommentInnerModal] = useState(false);
    const authContext = useContext(AuthContext);
    const user = authContext.getUser();
    const handleClose = () => {
        setOpen(false);
        onClose();
      };

    useEffect(() => { 
        iipeerpapi.getComment(user,entityId)
          .then(response => {
            setGetCommentData(response.data);
          })
          .catch(error => {
            console.error('Error comment data:', error);
          });
    }, [open, entityId]);

    const [commentData, setCommentData] = useState({
        type: '',
        entityName: entity,
        entityId: entityId,
        text: '',
        attachment: '',
        commentId: '',
        seq: 0
      });
    
      const handleFileChange = async (file) => {
        try {
          const Auth = authContext; 
          const user = Auth.getUser();
          const formData = new FormData();
          formData.append('file', file);
          const response = await iipeerpapi.fileUpload(user, formData);
          if (response && response.data && response.data.fileId) {
            setCommentData(prevCommentData => ({
              ...prevCommentData,
              attachment: response.data.fileId
            }));
          } else {
            console.error('File upload failed. Unexpected response:', response);
          }
        } catch (error) {
          console.error('Error uploading file:', error.message);
        }
      };
    
      const downloadAttachment = (fileName) =>{
        iipeerpapi.fileDownload(user, fileName)
          .then(response => {
            const filename = response.headers['content-disposition'].split('filename=')[1];
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
    
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch(error => {
            console.error("error in file download");
          });
    };

    // const handleFileView = (fileName) => {
    //     iipeerpapi.viewFile(user, fileName)
    //       .then(response => {
    //         const blob = new Blob([response.data], { type: 'application/pdf' });
    //         const url = URL.createObjectURL(blob);
    //         //setPdfContent(url);
    //         setShowPdfView(true);
    //       })
    //       .catch(error => {
    //         console.error('Error fetching PDF:', error);
    //       });
    //   };

      const closePdfView = () => {
        setPdfContent(null);
        setShowPdfView(false);
      };

      const handleCommentAndReplay = async (type, Uid) => {
        setCommentData(prevCommentData => ({
          ...prevCommentData,
          type: type,
          commentId: Uid,
          text: ''
        }));
        setCommentInnerModal(true);
      }

      const handleAddComment = async () => {
        try {
            const response = await iipeerpapi.addComment(user, commentData);
            Toasts.notifySuccess('Comment added successfully');
            const updatedComments = await iipeerpapi.getComment(user, entityId);
            setGetCommentData(updatedComments.data);
            setCommentInnerModal(false);
        } catch (error) {
            console.error('Error adding comment:', error);
        }
    };


    return (
        <Modal
        onClose={handleClose}
        open={open}
        size='small'
        >
            <Modal.Header style={{ textAlign: 'center',  color: 'black' }} icon='chat' content='Conversation' />
            <Modal.Content>
            <CommentGroup>
              {Array.isArray(getCommentData) && getCommentData.length > 0 ? (
              getCommentData.map((comment, index) => (
                  <Comment>
                    <CommentAvatar src={process.env.PUBLIC_URL + '/sitedocs/commentIcon.png'} />                 
                      <CommentContent>
                        <CommentAuthor as='a'>{comment.sentFrom}</CommentAuthor>
                        <CommentMetadata>
                            <div>{datetimeutils.formatDateTimeToDateTime(comment.createdTime)}</div>
                        </CommentMetadata>
                        <CommentText>
                        <p>{comment.text}</p>
                        </CommentText>
                        <CommentActions>
                            {comment.attachment && (
                                <>
                                    {/* <CommentAction onClick={() => handleFileView(comment.attachment)}>View Attachment</CommentAction> */}
                                    <CommentAction onClick={() => downloadAttachment(comment.attachment)}>Download Attachment</CommentAction>    
                                </>
                            )}
                        </CommentActions>
                        <Button content='Add reply' labelPosition='left' icon='paper plane outline' color="grey"  onClick={() => handleCommentAndReplay('reply', comment.uid)}/>
                      </CommentContent>
    
                      <CommentGroup>
                        {comment.replies.map((reply, replyIndex) => (
                                <Comment key={replyIndex}>
                                    <CommentAvatar src={process.env.PUBLIC_URL + '/sitedocs/commentIcon.png'} />
                                    <CommentContent>
                                        <CommentAuthor as='a'>{reply.sentFrom}</CommentAuthor>
                                        <CommentMetadata>
                                            <div>{datetimeutils.formatDateTimeToDateTime(reply.createdTime)}</div>
                                        </CommentMetadata>
                                        <CommentText>{reply.text}</CommentText>
                                        <CommentActions>
                                            {reply.attachment && (
                                                <>
                                                {/* <CommentAction onClick={() => handleFileView(reply.attachment)}>View Attachment</CommentAction> */}
                                                <CommentAction onClick={() => downloadAttachment(reply.attachment)}>Download Attachment</CommentAction>
                                                </>
                                            )}
                                        </CommentActions>
                                    </CommentContent>
                                </Comment>
                            ))}
                      </CommentGroup>
                </Comment>
              
            ))
            ) : (
            <div style={{ textAlign: 'center' }}>No Comments Available.</div>
            )}
            
            {showPdfView && (
              <Segment.Group>
                  <Segment style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Icon
                      name='close'
                      size='large'
                      onClick={closePdfView}
                      style={{ cursor: 'pointer' }}
                      />
                  </Segment>
              <Segment >
                  <embed src={pdfContent} type="application/pdf" width="100%" height="600px" />
              </Segment>
              </Segment.Group>
            )}
        </CommentGroup>
        </Modal.Content>
        <Modal.Actions>
            <Button content='Add Comment' labelPosition='left' icon='edit' color="green" onClick={() => handleCommentAndReplay('comment', null)}/>
            <Button color='blue'  onClick={handleClose}>
                Close <Icon name="chevron right" />
            </Button>
        </Modal.Actions>

        {showCommentInnerModal && (
          <Modal
            open={showCommentInnerModal}
            onClose={() => setCommentInnerModal(false)}
            size='small'
          >
          <Modal.Content>
            <Form>
                <Form.Field>
                    <label>Add Text<span style={{ color : 'red'}}>*</span></label>
                    <Form.TextArea
                        id="commentInput"
                        name="commentInput"
                        className="comment"
                        placeholder='Add Text'
                        value={commentData.text} 
                        onChange={(e, { value }) => setCommentData({ ...commentData, text: value })}
                        required
                    /> 
                </Form.Field>
                <Form.Field>
                    <label>Attach (.pdf,.doc,.docx) </label>
                    <input
                        type='file'
                        name='attachment'
                        onChange={(e) => handleFileChange(e.target.files[0])}
                        accept='.pdf,.doc,.docx'
                        multiple={false}
                    />
                </Form.Field>   
            </Form>      
          </Modal.Content>
          <Modal.Actions>
            <Button content='Send' labelPosition='left' icon='paper plane outline' color="green" onClick={handleAddComment}/>
            <Button color='blue' onClick={() => setCommentInnerModal(false)}>
              Close <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
        )}
        
    </Modal>
    );
};
export default CommentsPage;