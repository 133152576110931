import React, { Component } from 'react'
import { Grid, Form, Button, Table, Header, Divider, Radio, Pagination, Input, Message, TextArea, Container } from 'semantic-ui-react'
import { Dropdown, Segment, Modal, Icon } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import AuthContext from '../context/AuthContext'
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Slide } from "react-toastify";
import Toasts from '../misc/Toasts';
import moment from 'moment';
import { useState } from "react";
import datetimeutils from "../utils/DateTimeUtils";
import LeaveEdit from './LeaveEdit'
import LeaveEditHistoryTable from './LeaveEditHistoryTable'

class LeaveHistory extends Component {
  

  
  static contextType = AuthContext
  inputLeavetypeRef = React.createRef();
  fileInputRef = React.createRef();
  getYears() {
    const current = new Date()

    return [
      // { key: 1, text: 'Select', value: 1 },
      { key: 2, text: current.getFullYear() - 1, value: current.getFullYear() - 1 },
      { key: 3, text: current.getFullYear(), value: current.getFullYear() },
      { key: 4, text: current.getFullYear() + 1, value: current.getFullYear() + 1 },
    ]
  }

  constructor(props) {
    super(props);
    this.modalContentRef = React.createRef();
  }

  state = {
    LeavesList: this.props.LeaveList,
    users: this.props.users,
    HolidaysList: this.props.HolidaysList,
    userdetails: this.props.userdetails,
    LeaveBalanceList: this.props.LeaveBalanceList,
    Years: [],
    Year: new Date().getFullYear(),
    joiningPayload: {},
    approvals: [],
    isLeavesLoading: false,
    isLoggedIn: false,
    showModal: false,
    editModal: false,
    showModal2: false,
    showModal3: false,
    reloadLeaves: true,
    deleteModal: false,
    cancelModal: false,
    deleteModal3: false,
    isDropdownOpen: false,
    inputLeaveStartDate: null,
    leaveId: [],
    //    chainLeaves: [],
    disable: false,
    inputLeaveEndDate: null,
    inputJoiningDate: null,
    startdaysession: 'FN',
    lastdaysession: 'AN',
    joiningdaysession: 'FN',
    inputLeavetype: null,
    currentPage: 1,
    entriesPerPage: 10,
    joiningFormDetails: [],
    joiningFormLoading: false,
    joiningformid: '',
    file: null,
    fileId: '',
    fileName: '',
    cancelcomment: ''
  }


  handleDownload = async (fileName) => {
    const Auth = this.context
    const user = Auth.getUser()
    iipeerpapi.fileDownload(user, fileName)
      .then(response => {
        const filename = response.headers['content-disposition'].split('filename=')[1];
        const contenttype = response.headers['content-type'];
        const blob = new Blob([response.data]);
        //const blob = new Blob([response.data]);
        //blob.lastModifiedDate = new Date();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        handleLogError(error)
      })
      .finally(() => {

      })
  };

  renderAttachmentButton = (attachmentDetails) => {
    const { selectedLeave } = this.state;
    //const attachmentDetails = selectedLeave.attachements_details;
    if (attachmentDetails) {
      return (
        <Button
          size='small'
          content='Download Attachment'
          icon='download'
          onClick={() => this.handleDownload(attachmentDetails)}
        />
      );
    } else {
      return (
        <p>No attachment available</p>
      );
    }
  };


  renderTableRows = () => {
    const { currentPage, entriesPerPage, LeavesList, joiningFormList } = this.state;
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    const sortedLeaves = LeavesList.slice().sort((a, b) => new Date(b.leaveapplied_date) - new Date(a.leaveapplied_date));
    const displayedLeaves = sortedLeaves.slice(startIndex, endIndex);
    if (displayedLeaves.length === 0) {
      return (
        <Table.Row key='no-holidays'>
          <Table.Cell
            collapsing
            textAlign='center'
            colSpan='9'
          >
            No Leaves Found In Your Leave History
          </Table.Cell>
        </Table.Row>
      );
    }
    let counter = 1

    return displayedLeaves.map((Leave, index) => (
      <Table.Row textAlign='center' key={index}>
        <Table.Cell>{startIndex + (counter++)}</Table.Cell>
        <Table.Cell>{Leave.leavetype}</Table.Cell>
        <Table.Cell>{datetimeutils.formatDateToDate(Leave.start_date)}</Table.Cell>
        <Table.Cell>{datetimeutils.formatDateToDate(Leave.end_date)}</Table.Cell>
        <Table.Cell>{(Leave.leavetype === 'SL' || Leave.leavetype === 'LD') ? 'NA' : (<><b>{Leave.effectiveleaves} </b>Day(s)</>)}</Table.Cell>
        <Table.Cell>{Leave.leavetype === 'SL' || Leave.leavetype === 'LD' ? 'NA' : (<><b>{Leave.appliedDuration} </b>Day(s)</>)}</Table.Cell>
        <Table.Cell>{datetimeutils.formatDateTimeToDateTime(Leave.leaveapplied_date)} </Table.Cell>
        {/* <Table.Cell>{Leave.approvalPersonUsername}</Table.Cell> */}
        <Table.Cell
          className={this.getClassColorJoining(this.getClassStatus(Leave))}
        >

          {<Icon name={this.getClassIconJoining(this.getClassStatus(Leave))} />}
          {(Leave.leavejoiningform === null) ? !((Leave.leavetype === 'SL') || (Leave.leavetype === 'LD')) && ((Leave.leave_status.startsWith('APPROVED')) || !Leave.leave_status.startsWith('REJECTED') &&
            (!Leave.leave_status.includes('CANCELLATION APPROVED')) &&
            ((Leave.leave_status.includes('DELEGATION')) && !(Leave.leave_status.includes('PENDING')) && Leave.iscancelled == null || Leave.iscancelled === false)) ?
            (<Button color='blue' onClick={() => this.handleModal2Open(Leave)}>Submit Leave Joining Form</Button>) :
            (Leave.iscancelinitiated === true) || (Leave.leavetype === 'SL') || (Leave.leavetype === 'LD') ? ('NOT APPLICABLE') :
              (Leave.leave_status.startsWith('REJECTED BY')) ? ('NOT APPLICABLE') : ('Yet to be submitted') : Leave.leavejoiningform.status
          }
        </Table.Cell>
        <Table.Cell className={this.getClassColor(Leave.leave_status)}>
          <Icon name={this.getClassIcon(Leave.leave_status)} />
          {Leave.leave_status}</Table.Cell>
        <Table.Cell>
          <Button.Group color='blue'>
            <Dropdown
              icon='angle right'
              floating
              button
              className='icon'
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleModalOpen(Leave)}>View Leave Details</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleEditModalOpen(Leave)}>Edit Leave</Dropdown.Item>

                {/* {((Leave.leavetype!=='SL') && (Leave.leavetype!=='LD') &&(Leave.leave_status.includes('APPROVED') && !Leave.isleavejoiningformsubmitted) &&  (Leave.iscancelled===false))  && <Dropdown.Item onClick={() => this.handleModal2Open(Leave)} >Submit Joining Form</Dropdown.Item>} */}

                {Leave.leave_status.includes('APPROVED') && Leave.isleavejoiningformsubmitted && <Dropdown.Item onClick={() => this.handleModal3Open(Leave)}>View LeaveJoining Form</Dropdown.Item>}
              </Dropdown.Menu>
            </Dropdown>
          </Button.Group>

        </Table.Cell>
        {/* <Table.Cell>

              <Button type="submit" size='small' icon='trash' negative onClick={() => this.handleDelete(Leave)}
              ></Button>
            </Table.Cell> */}
      </Table.Row>
    )
    )
  };
  handlePageChange = (event, { activePage }) => {
    this.setState({ currentPage: activePage });
  };


  renderPaginationControls = () => {
    const { currentPage, entriesPerPage, LeavesList } = this.state;
    const totalPages = Math.ceil(LeavesList.length / entriesPerPage);

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  };

  leaveStartDateHandler = (e, { id, value }) => {
    this.setState({ [id]: value })
  }
  leaveEndDateHandler = (e, { id, value }) => {
    this.setState({ [id]: value })
  }
  joiningDateHandler = (e, { id, value }) => {
    this.setState({ [id]: value })
  }
  RadioExampleRadioGroupJoinigDay = () => {
    const [value, setValue] = useState('FN');
    const handleChange = (event, { value }) => {
      setValue(value);
      this.state.joiningdaysession = value;
    }

    return (
      <Form widths='equal'>
        <Form.Group>

          <Form.Field>
            <Radio
              label='From Fore Noon'
              name='radioGroup'
              value='FN'
              checked={value === 'FN'}

              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='From After Noon'
              name='radioGroup'
              value='AN'
              checked={value === 'AN'}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  };
  RadioExampleRadioGroupFirstDay = () => {
    const [value, setValue] = useState('FN');
    const handleChange = (event, { value }) => {
      setValue(value);
      this.state.startdaysession = value
    }

    return (
      <Form widths='equal'>
        <Form.Group>

          <Form.Field>
            <Radio
              label='From Fore Noon'
              name='radioGroup'
              value='FN'
              checked={value === 'FN'}

              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='From After Noon'
              name='radioGroup'
              value='AN'
              checked={value === 'AN'}
              onChange={handleChange}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  };
  RadioExampleRadioGroupLastDay = () => {
    const [value, setValue] = useState('AN');
    const handleChange = (event, { value }) => {
      setValue(value);
      this.state.lastdaysession = value
    }

    return (
      <Form widths='equal'>
        <Form.Group>

          <Form.Field>
            <Radio
              label='To Fore Noon End'
              name='radioGroup'
              value='FN'
              checked={value === 'FN'}
              onChange={handleChange}

            />
          </Form.Field>
          <Form.Field>
            <Radio
              label='To After Noon End'
              name='radioGroup'
              value='AN'
              checked={value === 'AN'}
              onChange={handleChange}
            // defaultChecked
            />
          </Form.Field>
        </Form.Group>
      </Form>
    )
  };
  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };
  handleAPICall() {
    iipeerpapi.submitJoiningForm()
  }
  handleModalOpen = (leave) => {
    this.setState({
      selectedLeave: leave,
      showModal: true
    });
  };

  handleEditModalOpen = (leave) => {
    this.setState({
      selectedLeave: leave,
      editModal: true
    });
  };
  handleEditModalClose = () => {
    this.setState({
      selectedLeave: null,
      editModal: false
    })
  }
  handleModalClose = () => {
    this.setState({
      selectedLeave: null,
      showModal: false
    })
  }
  handleModal2Open = (leave) => {
    this.setState({
      selectedLeave: leave,
      leaveid: leave.leaveid,
      showModal2: true
    });
  };
  handleModal2Close = async () => {
    this.setState({
      selectedLeave: null,
      showModal2: false
    })
    this.clearAllInputFields();
  }

  handleModal3Open = (leave) => {



    this.setState({
      selectedLeave: leave,
      joiningformid: leave.leavejoiningformid,
      showModal3: true,
    });
    this.getJoiningForm(leave.leavejoiningformid);
  };
  handleModal3Close = () => {
    this.setState({
      selectedLeave: null,
      showModal3: false
    })
  }
  clearAllInputFields = () => {
    this.setState({ inputLeaveStartDate: '' })
    this.setState({ inputLeaveEndDate: '' })
    this.setState({ inputJoiningDate: '' })
    this.setState({ leavejoiningapprovals: [] })
    this.setState({ leaveId: [] })
    this.setState({ leaveid: '' })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.LeaveList !== this.props.LeaveList)
      this.setState({ LeavesList: this.props.LeaveList });

  }
  componentDidMount() {
    if (this.props.loadLeaveHistory === true) {
      this.props.handleGetLeaves()

      this.props.handleLoadLeaveHistory(false)
    }
  }

  handleInputChange = (e, { id, value }) => {
    this.setState({ [id]: value })
  }

  handleStatus(status) {

    if (status === true) {
      return "SUBMITTED"
    }
    else if (status === null) {
      return "YET TO BE SUBMITTED"
    }
    else if (status === false) {
      return "YET TO BE SUBMITTED"
    }
    else {
      return "REJECTED"
    }
  }
  handleDelete = (leave,deleteLeavePayload) => {
    const Auth = this.context;
    const user = Auth.getUser();
    iipeerpapi.deleteLeaves(user, deleteLeavePayload)
      .then(response => {
        this.handleModalClose()
        this.props.handleGetLeaves();
        Toasts.notifySuccess("Leave Deleted Successfully!")
        if (leave.leavetype !== 'SL' && leave.leavetype !== 'LD') {
          this.props.handleGetLeaveBalance()
          Toasts.notifySuccess("Leave Balance Successfully Updated.")
        }
      })
      .catch(error => {
        handleLogError(error);
        Toasts.notifyError("Something went wrong. Please try again !")
      });
  }

  handleCancel = (selectedLeave, cancelLeavePayload) => {
    const Auth = this.context;
    const user = Auth.getUser();
    iipeerpapi.cancelLeave(user, cancelLeavePayload)
      .then(response => {
        this.handleModalClose()
        this.props.handleGetLeaves();
        Toasts.notifySuccess("Leave Cancellation Initiated Successfully!")
        //if (leave.leavetype !== 'SL' && leave.leavetype !== 'LD') {
        //this.props.handleGetLeaveBalance()
        if (selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD') {
          Toasts.notifySuccess("Leave Balance will be updated after approval of cancellation.")
        }
        // else{
        //   Toasts.notifySuccess("Leave Balance will be updated after approval of cancellation.")
        // }

        //}
      })
      .catch(error => {
        handleLogError(error);
        Toasts.notifyError("Something went wrong. Please try again !")
      });
  }

  handleJoiningFormDelete = (leave) => {
    const Auth = this.context;
    const user = Auth.getUser();
    iipeerpapi.deletejoiningform(user, leave.leavejoiningform.leavejoiningformid)
      .then(response => {
        this.props.handleGetLeaves();
        this.handleModal3Close()
        Toasts.notifySuccess("Joining form Deleted Successfully!")
      })
      .catch(error => {
        handleLogError(error);
        Toasts.notifyError("Something went wrong. Please try again !")
      });
  }

  formatDate = (dateString) => {
    if (dateString == null) {
      return ''
    }

    const date = new Date(dateString)
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear();

    return `${day}-${month}-${year}`
  }

  formatDateTime = (dateString) => {

    const date = new Date(dateString)
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear();
    return `${day}-${month}-${year}`
  }

  getClassColor = (color) => {
    if (color.includes("APPLIED") || color.includes("PENDING") || color.includes("SUBMITTED")) {
      return 'warning'
    }
    else if (color.includes("REJECTED")) {
      return 'negative'
    }
    else if (color === "") {
      return ''
    }
    else {
      return 'positive'
    }
  }

  getClassColorJoining = (color) => {
    if (color.includes("APPLIED") || color.includes("PENDING") || color.includes("Yet to be submitted")) {
      return 'warning'
    }
    else if (color.includes("REJECTED")) {
      return 'negative'
    }
    else if (color.includes("APPROVED")) {
      return 'positive'
    }
    else if (color === "") {
      return ''
    }
    else {
      return ''
    }
  }

  getStatusColor = (color) => {
    if (color === "SUBMITTED") {
      return 'positive'
    }
    else {
      return 'warning'
    }
  }

x

  getClassIcon = (details) => {

    if (details.includes("APPLIED") || details.includes("PENDING") || details.includes("SUBMITTED")) {
      return 'attention'
    }
    else if (details.includes("REJECTED")) {
      return 'close'
    }
    else if (details.includes("ACCEPT") || details.includes("APPROVE")) {
      return 'checkmark'
    }
    else if (details.includes("")) {
      return ''
    }
    else {
      return 'attention'
    }
  }

  getClassIconJoining = (details) => {

    if (details.includes("APPLIED") || details.includes("PENDING") || details.includes("Yet to be submitted")) {
      return 'attention'
    }
    else if (details.includes("REJECTED")) {
      return 'close'
    }
    else if (details.includes("ACCEPT") || details.includes("APPROVE")) {
      return 'checkmark'
    }
    else {
      return ''
    }
  }

  getClassStatus = (Leave) => {
    return ((Leave.leavejoiningform === null) ? !((Leave.leavetype === 'SL') || (Leave.leavetype === 'LD')) && ((Leave.leave_status.startsWith('APPROVED')) || !Leave.leave_status.startsWith('REJECTED') &&
      (!Leave.leave_status.includes('CANCELLATION APPROVED')) &&
      ((Leave.leave_status.includes('DELEGATION')) && !(Leave.leave_status.includes('PENDING')) && Leave.iscancelled == null || Leave.iscancelled === false)) ?
      ('') :
      (Leave.iscancelinitiated === true) || (Leave.leavetype === 'SL') || (Leave.leavetype === 'LD') ? ('NOT APPLICABLE') :
        (Leave.leave_status.startsWith('REJECTED BY')) ? ('NOT APPLICABLE') : ('Yet to be submitted') : Leave.leavejoiningform.status)

  }

  getLeavechain(leave) {
    const Auth = this.context
    const user = Auth.getUser()

    iipeerpapi.leaveChain(user, leave)
      .then(response => {
        this.setState({ leaveId: response.data })
      })
      .catch(error => {
      })
  }

  getJoiningForm(leave) {

    const Auth = this.context
    const user = Auth.getUser()
    iipeerpapi.getleavejoiningform(user, leave)
      .then(response => {
        let isDisabled = false;
        if (response.data != null) {



          if (response.data.status.includes("APPROVED")) {
            isDisabled = true;
          }
          else {
            isDisabled = false;
          }
        }
        this.setState({ joiningFormDetails: response.data, disable: isDisabled })
      })
      .catch(error => {
        handleLogError(error)
      })
      .finally(() => {
        this.setState({ joiningFormLoading: false })
      })
  }

  handleDate(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);

    //return req_date.toISOString().substring(0, 10)
    return req_date.toLocaleDateString()
  }

  handleTimeOld(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    //const timeComponent = req_date.toISOString().substring(11, 16);
    const timeComponent = req_date.toLocaleTimeString()
    const [hours, minutes] = timeComponent.split(":");
    let period = "am";
    let hours12 = parseInt(hours, 10);

    if (hours12 === 0) {
      hours12 = 12;
    } else if (hours12 >= 12) {
      period = "pm";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }
    return `${hours12}:${minutes} ${period}`;
  }

  handleTime(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    //const timeComponent = req_date.toISOString().substring(11, 16);
    const timeComponent = req_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return timeComponent;
  }
  isFormValid = () => {
    const {
      inputLeaveStartDate,
      inputLeaveEndDate,
      inputJoiningDate,
    } = this.state;
    return (inputLeaveStartDate && inputLeaveEndDate && inputJoiningDate);
  };
  handleSubmitFormCall = async (e) => {
    const { inputLeaveStartDate, inputLeaveEndDate, inputJoiningDate, startdaysession,
      lastdaysession, joiningdaysession, joiningPayload, approvals, leaveId, leaveid } = this.state
    const { userdetails } = this.props;

    let fileIds = ''
    if (this.state.file != null) {
      const Auth = this.context
      const user = Auth.getUser()
      let fileId
      const formData = new FormData();
      formData.append('file', this.state.file);
      // formData.append('fileName', file.name);
      await iipeerpapi.fileUpload(user, formData)
        .then(response => {
          fileIds = response.data.fileId
        })
        .catch(error => {
          fileIds = ''
          handleLogError(error)
          Toasts.notifyError("Error in uploading attachment!")
          return
        })
        .finally(() => {
        })

    }

    if (this.state.file != null && fileIds === '') {
      return
    }
    joiningPayload.attachments_details = fileIds

    let counter = 1;
    joiningPayload.leavejoiningapprovals = userdetails.leavejoiningApprovalUsers.length == 0 ? [] : userdetails.leavejoiningApprovalUsers.map(user => {
      return ({
        username: user.username,
        status: '',
        approversequence: counter++
      })
    })
    // this.getLeavechain(leaveid);
    // const Auth = this.context
    // const user = Auth.getUser()
    const Auth = this.context
    const user = Auth.getUser()
    let chainleaves = [];
    joiningPayload.leaveids = [];
    joiningPayload.leaveids.push(leaveid);
    
    joiningPayload.startdate = inputLeaveStartDate;
    joiningPayload.lastdate = inputLeaveEndDate;
    joiningPayload.joiningdate = inputJoiningDate;
    joiningPayload.startdaysession = startdaysession;
    joiningPayload.lastdaysession = lastdaysession;
    joiningPayload.joiningdaysession = joiningdaysession;
    


    iipeerpapi.leavejoiningform(user, joiningPayload)
      .then(
        response => {
          this.setState({ joiningResponse: response.data })
          this.props.handleGetLeaves()
          Toasts.notifySuccess("Leave Joining Form Submitted Successfully!")
        }
      )
      .catch(error => {
        Toasts.notifyError("Something Went Wrong!")
      })

    this.handleModal2Close();
  }

  handleName = (name) => {

    if (name === "CL") {
      return "Casual Leave";
    }
    else if (name === "HPL") {
      return "HalfPay Leave";
    }
    else if (name === "EL") {
      return "Earned Leave";
    }
    else if (name === "VL") {
      return "Vacation Leave"
    }
    else if (name === "SCL") {
      return "Special Casual Leave";
    }
    else if (name === "RH") {
      return "Restricted Holiday";
    }
    else if (name === "PROL") {
      return "Project Leave";
    }
    else if (name === "COL") {
      return "Commuted Leave";
    }
    else if (name === "SL") {
      return "Station Leave";
    }
    else if (name === "LD") {
      return "Leave on Duty";
    }
    else {
      return "No Leave Found"
    }
  };

  handleSubmit = (e) => {

    if (this.isFormValid()) {
      this.handleSubmitFormCall();
      this.handleModal2Close();
      //Toasts.notifySuccess("Leave Joining Form Submitted Successfully!");
    }
  };

  handleStatusColor(color) {
    color.toUpperCase()
    if (color.includes("PENDING") || color.includes("SUBMITTED") || color.includes("APPLIED")) {
      return "warning"
    }
    else if (color.includes("REJECTED")) {
      return "negative"
    }
    else if (color === '') {
      return '';
    }
    else {
      return "positive"
    }
  }



  ModalExampleScrollingContent = ({ LeavesList, counter }) => {
    const { selectedLeave, joiningFormDetails } = this.state;
    const officialDutyData = selectedLeave?.additionalfields?.officialdutydata?.fieldsandvalues || {};
    if (!selectedLeave) {
      return null;
    }
    const { userdetails } = this.props

    let approvals = []
    approvals = Object.keys(userdetails || {}).length === 0 ? [] : userdetails.leavejoiningApprovalUsers
    let approver1 = approvals.length === 0 ? "" : approvals[0].fullname;
    let approver2 = approvals.length <= 1 ? "" : approvals[1].fullname;
    let approver3 = approvals.length <= 2 ? "" : approvals[2].fullname;




    const [open, setOpen] = React.useState(false)

    const ModalDeleteConfirmation = () => {
      //const [open, setOpen] = React.useState(false)
      const deleteLeavePayload = {}
      deleteLeavePayload.leaveid = selectedLeave.leaveid
      const [dlcommenterror, setDlcommenterror] = useState(false)
      const [dlcomment, setDlcomment] = useState('')
      const handledlcommentchange = (event) => {
        // if (event.target.value == null || event.target.value.trim() === '') {
        //   setClcommenterror(true)

        // }
        // else {
        const newcomment = event.target.value
      
        setDlcommenterror(false)
        setDlcomment(newcomment)
        deleteLeavePayload.deletereason = newcomment;

        // }

      }

      return (
        <Modal
          onClose={() => { this.setState({ deleteModal: false }) }}
          open={this.state.deleteModal}
          size='small'
        >
          <Modal.Header>Delete Leave Confirmation</Modal.Header>
             <Modal.Content>
            <p>Please provide reason for deleting leave!</p>
            <Form.Group widths='equal'>
              <Form.Field >
              <TextArea
                  required
                  fluid
                  placeholder="Enter Reason of Deleting Leave..."
                  value={dlcomment}
                  onChange={handledlcommentchange}
                  style={{ width: '100%', boxSizing: 'border-box' }}
                />
              </Form.Field>
            </Form.Group>
            {
              dlcommenterror && <Message negative>Please provide reason</Message>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={() => {
              if (dlcomment === null || dlcomment.trim() === '') {
                setDlcommenterror(true)
              }
              else {
   
                setDlcommenterror(false)
                deleteLeavePayload.deletereason = dlcomment;
                this.handleDelete(selectedLeave, deleteLeavePayload);
              }

            }}>
              Confirm Delete Leave
            </Button>
            <Button negative onClick={() => this.setState({ deleteModal: false })}>
              Close
            </Button>
          </Modal.Actions>
          
        </Modal>
      )
    }

    const ModalCancelConfirmation = () => {
      //const [open, setOpen] = React.useState(false)
      const cancelLeavePayload = {}
      cancelLeavePayload.leaveid = selectedLeave.leaveid
      const [clcommenterror, setClcommenterror] = useState(false)
      const [clcomment, setClcomment] = useState('')
      const handlecommentchange = (event) => {
        // if (event.target.value == null || event.target.value.trim() === '') {
        //   setClcommenterror(true)

        // }
        // else {
        const newcomment = event.target.value
      
        setClcommenterror(false)
        setClcomment(newcomment)
        cancelLeavePayload.comments = newcomment;

        // }

      }
      return (
        <Modal
          onClose={() => { this.setState({ cancelModal: false }) }}
          open={this.state.cancelModal}
          size='small'
        >
          <Modal.Header>Cancel Confirmation</Modal.Header>
          <Modal.Content>
            <p>Please provide reason for cancellation!</p>
            <Form.Group widths='equal'>
              <Form.Field >
              <TextArea
                  required
                  fluid
                  placeholder="Enter Reason of Cancellation..."
                  value={clcomment}
                  onChange={handlecommentchange}
                  style={{ width: '100%', boxSizing: 'border-box' }}
                />
              </Form.Field>
            </Form.Group>
            {
              clcommenterror && <Message negative>Please provide comment</Message>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={() => {
              if (clcomment == null || clcomment.trim() === '') {
                setClcommenterror(true)
              }
              else {
                setClcommenterror(false)
                cancelLeavePayload.comments = clcomment;
                this.handleCancel(selectedLeave, cancelLeavePayload);
              }

            }}>
              Confirm Cancel Leave
            </Button>
            <Button negative onClick={() => this.setState({ cancelModal: false })}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      )
    }

    const DeleteJoiningFormConfirmation = () => {

      return (
        <Modal
          onClose={() => { this.setState({ deleteModal3: false }) }}
          open={this.state.deleteModal3}
          size='small'
        >
          <Modal.Header>Delete Confirmation</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete leave joining form?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button positive onClick={() => this.handleJoiningFormDelete(selectedLeave)}>
              Yes
            </Button>
            <Button negative onClick={() => this.setState({ deleteModal3: false })}>
              No
            </Button>
          </Modal.Actions>
        </Modal>
      )
    }

    const RadioExampleRadioGroupJoinigDay = () => {
      const [value, setValue] = useState('FN');
      const handleChange = (event, { value }) => {
        setValue(value);
        this.state.joiningdaysession = value;
      }

      return (
        <Form widths='equal'>
          <Form.Group>

            <Form.Field>
              <Radio
                label='From Fore Noon'
                name='radioGroup'
                value='FN'
                checked={value === 'FN'}

                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='From After Noon'
                name='radioGroup'
                value='AN'
                checked={value === 'AN'}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )
    };

    const RadioExampleRadioGroupLastDay = () => {
      const [value, setValue] = useState('AN');
      const handleChange = (event, { value }) => {
        setValue(value);
        this.state.lastdaysession = value
      }

      return (
        <Form widths='equal'>
          <Form.Group>

            <Form.Field>
              <Radio
                label='To Fore Noon End'
                name='radioGroup'
                value='FN'
                checked={value === 'FN'}
                onChange={handleChange}

              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='To After Noon End'
                name='radioGroup'
                value='AN'
                checked={value === 'AN'}
                onChange={handleChange}
              // defaultChecked
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )
    };

    const RadioExampleRadioGroupFirstDay = () => {
      const [value, setValue] = useState('FN');
      const handleChange = (event, { value }) => {
        setValue(value);
        this.state.startdaysession = value
      }

      return (
        <Form widths='equal'>
          <Form.Group>
            <Form.Field>
              <Radio
                label='From Fore Noon'
                name='radioGroup'
                value='FN'
                checked={value === 'FN'}

                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='From After Noon'
                name='radioGroup'
                value='AN'
                checked={value === 'AN'}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      )
    };
    return (
      <>
        <Modal closeIcon
          open={this.state.showModal3}
          onClose={this.handleModal3Close}
          size='fullscreen'
        >
          <Header textAlign='center' as='h2' color='blue'>Leave Joining Form</Header>
          <Modal.Content image scrolling>

            <Modal.Description>
              <Modal.Content>
                <Container>
                  <Segment.Group horizontal>
                    <Segment ><b>Leave Type: </b></Segment>
                    <Segment>
                      {this.handleName(selectedLeave.leavetype)} ({selectedLeave.leavetype})
                    </Segment>
                  </Segment.Group>
                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment.Group horizontal >
                          <Segment><b> Start Date  : </b></Segment>
                          <Segment>{datetimeutils.formatDateToDate(joiningFormDetails.startdate)} {(joiningFormDetails.startdaysession)}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <Segment.Group horizontal>
                          <Segment><b> End Date  : </b></Segment>
                          <Segment>{datetimeutils.formatDateToDate(joiningFormDetails.lastdate)} {(joiningFormDetails.lastdaysession)}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment.Group horizontal>
                          <Segment><b> Joining Date  : </b></Segment>
                          <Segment>{datetimeutils.formatDateToDate(joiningFormDetails.joiningdate)} {(joiningFormDetails.joiningdaysession)}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' dividing>Attachment: </Header>
                        {this.renderAttachmentButton(joiningFormDetails.attachments_details)}
                      </Grid.Column>
                    </Grid.Row>

                  </Grid>
                  <h3>Approvals</h3>
                  <Table compact striped celled selectable>
                    <Table.Header>
                      <Table.Row textAlign='center'>
                        <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Username</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {

                        (joiningFormDetails.leavejoiningapprovals) ? (joiningFormDetails.leavejoiningapprovals
                          .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                          .map((Approvals, index) => {
                            return <>
                              <Table.Row textAlign='center' >
                                <Table.Cell>{index + 1}</Table.Cell>
                                <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                                <Table.Cell className={this.handleStatusColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                                <Table.Cell>{Approvals.comments}</Table.Cell>
                                <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                              </Table.Row>
                            </>
                          })) : ("No Details Available")
                      }
                    </Table.Body>
                  </Table>
                </Container>
              </Modal.Content>
            </Modal.Description>

          </Modal.Content >
          <Modal.Actions style={{ backgroundColor: 'rgb(242, 242, 272)' }}>
            <DeleteJoiningFormConfirmation>

            </DeleteJoiningFormConfirmation>
            <Button disabled={this.state.disable} onClick={() => {

              this.setState({
                deleteModal3: true

              })
            }
            } negative>
              Delete <Icon name='trash' />
            </Button>
            <Button onClick={this.handleModal3Close} primary >
              Close <Icon name='chevron right' />
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal closeIcon
          open={this.state.showModal2}
          onClose={this.handleModal2Close}>
          <Header textAlign='center' as='h2' color='blue'>Submit Leave Joining Form</Header>
          <Modal.Content image scrolling>
            <Modal.Description>
              <Modal.Content>
                <Segment.Group horizontal>
                  <Segment ><b>Leave Type: </b></Segment>
                  <Segment>
                    {this.handleName(selectedLeave.leavetype)} ({selectedLeave.leavetype})
                  </Segment>
                </Segment.Group>

                <Form>
                  <Grid stackable textAlign='center'>
                    <Grid.Row>
                      <Grid.Column>
                        <Segment >
                          <Grid columns={2} stackable textAlign='center'>
                            <Divider vertical> Duration  </Divider>

                            <Grid.Row verticalAlign='top' >
                              <Grid.Column>
                                <SemanticDatepicker
                                  label="Start date"
                                  id="inputLeaveStartDate"
                                  format="DD-MMMM-YYYY"
                                  value={this.state.inputLeaveStartDate}
                                  onChange={this.leaveStartDateHandler}
                                  required
                                />

                                <RadioExampleRadioGroupFirstDay></RadioExampleRadioGroupFirstDay>


                              </Grid.Column>


                              <Grid.Column>
                                <SemanticDatepicker
                                  label="End date"
                                  id="inputLeaveEndDate"
                                  format="DD-MMMM-YYYY"
                                  value={this.state.inputLeaveEndDate}
                                  onChange={this.leaveEndDateHandler}
                                  required
                                />
                                <RadioExampleRadioGroupLastDay></RadioExampleRadioGroupLastDay>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>

                        <Grid columns='equal' stackable>
                          <Grid.Row>
                            <Grid.Column>
                              <Segment.Group horizontal >
                                <Segment>
                                  <SemanticDatepicker
                                    label="Joining Date"
                                    id="inputJoiningDate"
                                    format="DD-MMMM-YYYY"
                                    value={this.state.inputJoiningDate}
                                    onChange={this.joiningDateHandler}
                                    required
                                  />
                                  <RadioExampleRadioGroupJoinigDay></RadioExampleRadioGroupJoinigDay>

                                </Segment>
                              </Segment.Group>
                            </Grid.Column>
                          </Grid.Row>

                        </Grid>
                        <Segment>
                          <Form.Group widths='equal'>
                            <Form.Field >
                              <label >Attachments</label >
                            </Form.Field>
                          </Form.Group >
                          <Form.Group widths='equal' >
                            <Form.Field >

                              <Button
                                content="Choose File"
                                labelPosition="left"
                                icon="file"
                                onClick={(e) => { e.preventDefault(); this.fileInputRef.current.click() }}
                              />
                              <input
                                type="file"
                                ref={this.fileInputRef}
                                hidden
                                onChange={(e) => {
                                  const fileInput = e.target;
                                  if (e.target.files[0] != null) {
                                    this.setState({ fileName: e.target.files[0].name })
                                    this.setState({ file: e.target.files[0] })
                                    fileInput.value = null
                                  }
                                  else {
                                    // this.setState({fileName:'e.target.files[0].name'})
                                    // this.setState({ file: null})
                                  }
                                }
                                }
                              />
                            </Form.Field>
                            <Form.Field >
                              <Button
                                content="Clear"
                                //icon='close'
                                // labelPosition="left"
                                onClick={(e) => {
                                  e.preventDefault()
                                  this.setState({ fileName: '' })
                                  this.setState({ file: null })
                                }}
                              />
                            </Form.Field>

                            {/* <Form.Field >
                            <Button
                              content="Upload file"
                              // labelPosition="left"
                              onClick={(e)=>{e.preventDefault();
                                this.handleFileUpload}}
                            />
                          </Form.Field> */}
                          </Form.Group>

                          <Form.Group >
                            <Form.Field>
                              {this.state.fileName != null && this.state.fileName !== '' && <label>Chosen FileName: {this.state.fileName}</label>}
                            </Form.Field>
                            {/* <Form.Field>
                            {this.state.fileName!=null && this.state.fileName!=='' && <label>Uploaded succsflajsdlfaksjd;flak sjdf;lasjkdfl sdkfj</label> }
                            </Form.Field> */}
                          </Form.Group>
                        </Segment>
                        <Grid columns='equal' stackable>
                          <Grid.Row>
                            <Grid.Column>
                              <Divider horizontal>
                                <Header as='h4'>
                                  <Icon name='tag' />
                                  Approvals
                                </Header>
                              </Divider>
                              <Form.Group widths='equal'>
                                <Form.Field>
                                  <label>1st Approver</label>
                                  <Segment basic size='small'>{approver1}</Segment>

                                </Form.Field>
                                <Form.Field>
                                  <label>2nd Approver</label>
                                  <Segment basic size='small'>{approver2}</Segment>

                                </Form.Field>
                                <Form.Field>
                                  <label>3rd Approver</label>
                                  <Segment basic size='small'>{approver3}</Segment>

                                </Form.Field>
                              </Form.Group>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>

                  </Grid>

                </Form>
              </Modal.Content>
            </Modal.Description >
          </Modal.Content >
          <Modal.Actions>
            <Button type="submit" onClick={this.handleSubmit} disabled={!this.isFormValid()} primary>Submit<Icon name='chevron right' /></Button>
          </Modal.Actions>
        </Modal>
        <Modal
          closeIcon
          open={this.state.showModal}
          onClose={this.handleModalClose}
          size='fullscreen'

        >
          <Header textAlign='center' as='h2' color='blue'>Leave Details</Header>
          <Modal.Content image scrolling>
            <Container>
            <br />
                  {<LeaveEditHistoryTable
                        leaveid={selectedLeave.leaveid}
                       
                      />}
                      <br />
              <Modal.Description>
                <Modal.Content>
                  <Segment.Group horizontal>
                    <Segment ><b>Leave Type: </b></Segment>
                    <Segment>
                      {this.handleName(selectedLeave.leavetype)} ({selectedLeave.leavetype})
                    </Segment>
                  </Segment.Group>
                  <Segment.Group horizontal>
                    <Segment><b>Leave Status:</b></Segment>
                    <Segment>
                      {
                        selectedLeave.leave_status
                      }
                    </Segment>
                  </Segment.Group>

                  {selectedLeave.leavetype !== 'LD' &&
                    <Segment.Group horizontal>
                      <Segment><b>Start Date: </b></Segment>
                      <Segment>{datetimeutils.formatDateToDate(selectedLeave.start_date)}  {(selectedLeave.startdaysession)}</Segment>
                      <Segment><b>End Date: </b></Segment>
                      <Segment>{datetimeutils.formatDateToDate(selectedLeave.end_date)}  {(selectedLeave.lastdaysession)}</Segment>
                    </Segment.Group>}
                  {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Grid columns='equal'>
                    <Grid.Row>
                      <Grid.Column>
                        <Table compact striped celled selectable>
                          <Table.Header>
                            <Table.Row textAlign='center'>
                              <Table.HeaderCell >Prefix</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {
                              (selectedLeave.prefixes !== null && selectedLeave.prefixes.length !== 0) ? (selectedLeave.prefixes && selectedLeave.prefixes.map((pref, index) => {
                                return <>
                                  <Table.Row textAlign='center' key={index}>
                                    <Table.Cell>{pref}</Table.Cell>
                                  </Table.Row>
                                </>
                              })) : (<Table.Cell textAlign='center'>No Prefixes Found</Table.Cell>)
                            }
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                      <Grid.Column>
                        <Table compact striped celled selectable>
                          <Table.Header>
                            <Table.Row textAlign='center' >
                              <Table.HeaderCell >Suffix</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {
                              (selectedLeave.suffixes !== null && selectedLeave.suffixes.length !== 0) ? (selectedLeave.suffixes && selectedLeave.suffixes.map((suff, index) => {
                                return <>
                                  <Table.Row textAlign='center' key={index}>
                                    <Table.Cell>{suff}</Table.Cell>
                                  </Table.Row>
                                </>
                              })) : (<Table.Cell textAlign='center'>No Suffixes Found</Table.Cell>)
                            }
                          </Table.Body>

                        </Table>

                      </Grid.Column>

                    </Grid.Row>

                  </Grid>}
                  {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                    <Segment><b>Saturday/Sunday: </b></Segment>
                    <Segment>{selectedLeave.satandsundays}</Segment>
                    <Segment><b>National Holidays: </b></Segment>
                    <Segment>{selectedLeave.holidays}</Segment>
                  </Segment.Group>}
                  {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                    <Segment><b> Working Days  : </b></Segment>
                    <Segment>{selectedLeave.appliedDuration}</Segment>
                    <Segment><b> Applied Leaves: </b></Segment>
                    <Segment>{selectedLeave.effectiveleaves}</Segment>
                  </Segment.Group>}
                  <br></br>
                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as='h5' dividing>Out of Station Start Time: </Header>
                        <Segment.Group horizontal >
                          <Segment><b> Date  : </b></Segment>
                          <Segment>{
                            selectedLeave.outofstationstartdate == null ? 'NA' : datetimeutils.formatDateTimeToDate(selectedLeave.outofstationstartdate)
                          }</Segment>
                          <Segment><b> Time : </b></Segment>
                          <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationstartdate)}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' dividing>Out of Station End Time: </Header>
                        <Segment.Group horizontal>
                          <Segment><b> Date  : </b></Segment>
                          <Segment>{selectedLeave.outofstationenddate == null ? 'NA' : datetimeutils.formatDateTimeToDate(selectedLeave.outofstationenddate)}</Segment>
                          <Segment><b> Time : </b></Segment>
                          <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationenddate)}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Header as='h5'>Official Tour Details: </Header>
                  <Table celled stackable textAlign='center'>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell><b>Start Date Of Official Engagement</b></Table.Cell>
                        <Table.Cell >{officialDutyData.LDStartDate != null ? (datetimeutils.formatDateToDate(officialDutyData.LDStartDate) + ' ' + (officialDutyData.ldstartdaysession) || 'NA') : 'NA'}</Table.Cell>
                        <Table.Cell><b>End Date Of Official Engagement</b></Table.Cell>
                        <Table.Cell >{officialDutyData.LDStartDate != null ? (datetimeutils.formatDateToDate(officialDutyData.LDEndDate) + ' ' + (officialDutyData.ldlastdaysession) || 'NA') : 'NA'}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Places to be visited</b></Table.Cell>
                        <Table.Cell >{officialDutyData.placestobevisited || 'NA'}</Table.Cell>


                        <Table.Cell><b>Purpose of visit</b></Table.Cell>
                        <Table.Cell>{officialDutyData.purposeofvisit || 'NA'}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Estimated Budget</b></Table.Cell>
                        <Table.Cell>{officialDutyData.estimatedbudget || 'NA'}</Table.Cell>
                        <Table.Cell><b>Budget Head from which<br />expenditure is to be met</b></Table.Cell>
                        <Table.Cell>{officialDutyData.budgethead || 'NA'}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell><b>Mode of Journey</b></Table.Cell>
                        <Table.Cell>{officialDutyData.modeofjourney || 'NA'}</Table.Cell>
                        <Table.Cell><b>Class of Journey</b></Table.Cell>
                        <Table.Cell>{officialDutyData.classofjourney || 'NA'}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <h4>Reason for leave : </h4>
                  <Segment padded='very'>{selectedLeave.comments ? (selectedLeave.comments) : ("No Comments")}</Segment>
                  {/* <h4>Address : </h4>
                <Segment.Group horizontal>
                  <Header as='h5' dividing>Phone Number: </Header>
                <Segment className="ui modal-content" padded='very'>{selectedLeave.addressduringleave}</Segment>
          
                </Segment.Group> */}
                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as='h5' >Address : </Header>
                        <Segment.Group horizontal >
                          <Segment>{selectedLeave.addressduringleave}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' >Availing LTC?: </Header>
                        <Segment.Group horizontal >
                          <Segment>{selectedLeave.isavailingltc ? 'Yes' : 'No'}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns='equal' stackable>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as='h5' dividing>Phone Number: </Header>
                        <Segment.Group horizontal >
                          <Segment>{selectedLeave.phonenoduringleave}</Segment>
                        </Segment.Group>
                      </Grid.Column>
                      <Grid.Column>
                        <Header as='h5' dividing>Attachment: </Header>
                        {this.renderAttachmentButton(selectedLeave.attachements_details)}


                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <h4>Remarks By Establishment if any : </h4>
                  <Segment padded='very'>{selectedLeave.remarks == null ? ('NA') : (selectedLeave.remarks)}</Segment>
                  <h3>Delegations</h3>
                  <Table compact striped celled selectable>
                    <Table.Header>
                      <Table.Row textAlign='center'>
                        <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Nature of Work</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Start Day</Table.HeaderCell>
                        <Table.HeaderCell width={1}>End Day</Table.HeaderCell>
                        <Table.HeaderCell width={1}>comments</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status Updated On</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {

                        selectedLeave.leaveDelegations && selectedLeave.leaveDelegations.length > 0 ? (
                          selectedLeave.leaveDelegations && selectedLeave.leaveDelegations
                            .sort((p1, p2) => (p1.delegationsequence > p2.delegationsequence) ? 1 : (p1.delegationsequence < p2.delegationsequence) ? -1 : 0)
                            .map((delegations, index) => {
                              return <>
                                <Table.Row textAlign='center' key={index}>
                                  <Table.Cell>{index + 1}</Table.Cell>
                                  <Table.Cell>{delegations.userDetailsDTO.fullname}</Table.Cell>
                                  <Table.Cell>{delegations.natureofwork}</Table.Cell>
                                  <Table.Cell>{datetimeutils.formatDateToDate(delegations.startday)}</Table.Cell>
                                  <Table.Cell>{datetimeutils.formatDateToDate(delegations.lastday)}</Table.Cell>
                                  <Table.Cell>{(delegations.comments === null) ? ("-") : (delegations.comments)}</Table.Cell>
                                  <Table.Cell className={this.getClassColor(delegations.status)}>{delegations.status}</Table.Cell>
                                  <Table.Cell >{datetimeutils.formatDateTimeToDateTime(delegations.updatedtime)}</Table.Cell>
                                </Table.Row>
                              </>
                            })) : (

                          <Table.Row>
                            <Table.Cell collapsing textAlign='center' colSpan='8'>No Delegations Are Found.</Table.Cell>
                          </Table.Row>

                        )}
                    </Table.Body>
                  </Table>
                  <h3>Leave Application Approvals</h3>
                  <Table compact striped celled selectable>
                    <Table.Header>
                      <Table.Row textAlign='center'>
                        <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        Object.values(selectedLeave.leaveApprovals != null ? selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'APPLIED') : false) ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'APPLIED')
                          .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                          .map((Approvals, index) => {
                            return <>

                              <Table.Row textAlign='center' key={index}>
                                <Table.Cell>{Approvals.approversequence}</Table.Cell>
                                <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                                <Table.Cell className={this.getClassColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                                <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments)}</Table.Cell>
                                <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                              </Table.Row>
                            </>
                          })) : ("No Details Available")
                      }
                    </Table.Body>
                  </Table>
                  {(selectedLeave.leaveApprovals != null ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'CANCELLED').length > 0 ? true : false) : false) &&
                    <>

                      <h3>Leave Cancellation Approvals</h3>

                      <Segment.Group horizontal>
                        <Segment ><b>Cancellation Initiation Time: </b></Segment>
                        <Segment>
                          {datetimeutils.formatDateTimeToDateTime(selectedLeave.cancelinittime)}
                        </Segment>
                      </Segment.Group>
                      <Segment.Group horizontal>
                        <Segment ><b>Reason for Cancellation Request: </b></Segment>
                        <Segment>
                          {selectedLeave.cancelinitncomments}
                        </Segment>
                      </Segment.Group>
                      <Table compact striped celled selectable>
                        <Table.Header>
                          <Table.Row textAlign='center'>
                            <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                            Object.values(selectedLeave.leaveApprovals != null ? selectedLeave.leaveApprovals.filter(t => (t.approvaltype === 'CANCELLED')).length > 0 : false) ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'CANCELLED')
                              .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                              .map((Approvals, index) => {
                                return <>

                                  <Table.Row textAlign='center' key={index}>
                                    <Table.Cell>{Approvals.approversequence}</Table.Cell>
                                    <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                                    <Table.Cell className={this.getClassColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                                    <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments)}</Table.Cell>
                                    <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                                  </Table.Row>
                                </>
                              })) : ("No Details Available")
                          }

                        </Table.Body>
                      </Table>
                      
                    </>
                  }
                  
                </Modal.Content>
              </Modal.Description >
            </Container>
          </Modal.Content >
          <Modal.Actions style={{ backgroundColor: 'rgb(242, 242, 272)' }}>
            <ModalCancelConfirmation></ModalCancelConfirmation>
            <ModalDeleteConfirmation></ModalDeleteConfirmation>
            
            {(!selectedLeave.leave_status.includes('APPROVED') && !selectedLeave.leave_status.includes('REJECTED') && (!selectedLeave.leave_status.includes('CANCELLATION'))) ? (
              <Button onClick={() => this.setState({ deleteModal: true })} negative>
                Delete Leave<Icon name='trash' />
              </Button>
            ) : null}

            {(selectedLeave.leave_status.includes('APPROVED')) && (selectedLeave.iscancelinitiated == null || selectedLeave.iscancelinitiated === false) ? (
              <Button onClick={() => this.setState({ cancelModal: true })} secondary>
                Request for Leave Cancellation <Icon />
              </Button>
            ) : null}
            <Button onClick={this.handleModalClose} primary>
              Close <Icon name='chevron right' />
            </Button>
          </Modal.Actions>

        </Modal >
        <Modal
          closeIcon
          open={this.state.editModal}
          onClose={this.handleEditModalClose}
          size='fullscreen'
          closeOnDimmerClick={false}

        >
          <Header textAlign='center' as='h2' color='blue'>Edit Leave Details</Header>
          <Modal.Content image scrolling ref={this.modalContentRef}>
            <Container>

              <Modal.Description>
              {this.state.HolidaysList && this.props.LeaveBalanceList ? (
          <LeaveEdit 
            leaveid = {this.state.selectedLeave.leaveid}
            userdetails={userdetails}
            users={this.state.users}
            HolidaysList={this.state.HolidaysList}
            LeaveBalanceList={this.props.LeaveBalanceList}
            handleGetLeaveBalance={this.props.handleGetLeaveBalance}
            handleLoadLeaveHistory={this.props.handleLoadLeaveHistory}
            handleEditModalClose= {this.handleEditModalClose}
          />
        ) : (
          <p>Loading...</p>
        )}
              </Modal.Description >
            </Container>
          </Modal.Content >
          <Modal.Actions style={{ backgroundColor: 'rgb(242, 242, 272)' }}>
            <ModalCancelConfirmation></ModalCancelConfirmation>
            </Modal.Actions>
            </Modal>
      </>
    )
  }

  render() {
 
    return (
      <>

        <Grid stackable >
          <Grid.Row columns={3} centered>
            <Grid.Column >
            </Grid.Column>

          </Grid.Row>
        </Grid>
        <this.ModalExampleScrollingContent />
        <Table compact striped celled selectable>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
              <Table.HeaderCell width={1}>Leave Type</Table.HeaderCell>
              <Table.HeaderCell width={2}>Start Date</Table.HeaderCell>
              <Table.HeaderCell width={2}>End Date</Table.HeaderCell>
              <Table.HeaderCell width={1}>Effective Leaves</Table.HeaderCell>
              <Table.HeaderCell width={1}>Working days </Table.HeaderCell>
              <Table.HeaderCell width={2}>Applied Date </Table.HeaderCell>
              {/* <Table.HeaderCell width={2}>ApprovalPerson</Table.HeaderCell> */}
              <Table.HeaderCell width={3}>JoiningForm Status</Table.HeaderCell>
              <Table.HeaderCell width={4}>Leave Status</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderTableRows()}
          </Table.Body>
        </Table>
        {this.renderPaginationControls()}
      </>
    )
  }


}

export default LeaveHistory