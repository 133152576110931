import React, { useState, useContext, useEffect ,useMemo} from 'react';
import { Grid, Form, Table } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import AuthContext from '../context/AuthContext';
import datetimeutils from "../utils/DateTimeUtils";

const HolidaysTable = ({ HolidaysList }) => {
  const authContext = useContext(AuthContext);
  const [sortColumn, setSortColumn] = useState('Date');
  const [sortDirection, setSortDirection] = useState('ascending');

  const handleSort = (clickedColumn) => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection('ascending');
    } else {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
    }
  };

  const getYears = () => {
    const current = new Date();
    return [
      { key: 2, text: current.getFullYear() - 1, value: current.getFullYear() - 1 },
      { key: 3, text: current.getFullYear(), value: current.getFullYear() },
      { key: 4, text: current.getFullYear() + 1, value: current.getFullYear() + 1 }
    ];
  };

  const getHolidayTypes = () => {
    return [
      { key: 2, text: 'All', value: 'All' },
      { key: 3, text: 'Regular', value: 'Regular' },
      { key: 4, text: 'Restricted', value: 'Restricted' },
    ];
  };

  const [state, setState] = useState({
    HolidaysList: HolidaysList,
    Years: getYears(),
    Year: new Date().getFullYear(),
    isHolidaysLoading: false,
    isLoggedIn: false,
    HolidayType: 'All'
  });

  const sortedHolidaysList = useMemo(() => {
    return [...HolidaysList]
      .filter(Holiday => 
        (new Date(Holiday.date).getFullYear() === state.Year) &&
        (state.HolidayType === 'All' || Holiday.type === state.HolidayType)
      )
      .sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'ascending' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'ascending' ? 1 : -1;
        return 0;
      });
  }, [HolidaysList, sortColumn, sortDirection, state.Year, state.HolidayType]);

 

  const handleInputChange = (e, { id, value }) => {
    setState(prevState => ({ ...prevState, [id]: value }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const RenderHolidaysTable = () => {

    if (sortedHolidaysList.length === 0) {
      return (
        <Table.Row>
          <Table.Cell collapsing textAlign='center' colSpan='5'>No Holidays Are Found.</Table.Cell>
        </Table.Row>
      );
    }

    return sortedHolidaysList.map((Holiday, index) => (
      <Table.Row textAlign='center' key={index}>
      <Table.Cell>{index+1}</Table.Cell>
      <Table.Cell>{datetimeutils.formatDateToDate(Holiday.date)}</Table.Cell>
      <Table.Cell>{Holiday.type}</Table.Cell>
      <Table.Cell>{Holiday.name}</Table.Cell>
      <Table.Cell>{Holiday.remarks}</Table.Cell>
    </Table.Row>
  
    ));
  };

  let HolidaysListTemp = HolidaysList.filter((Holiday) => {
    return (
      (new Date(Holiday.date).getFullYear() === state.Year) &&
      (state.HolidayType === 'All' ? true : (Holiday.type === state.HolidayType))
    );
  });

  if (HolidaysListTemp.length === 0) {
    HolidaysListTemp = (
      <Table.Row key={1}>
        <Table.Cell collapsing textAlign='center' colSpan='5'>No Holidays Are Found.</Table.Cell>
      </Table.Row>
    );
  } else {
    let counter = 1;
    HolidaysListTemp = HolidaysListTemp.map((Holiday, index) => {
      return (
        <Table.Row textAlign='center' key={index}>
          <Table.Cell>{counter++}</Table.Cell>
          <Table.Cell>{datetimeutils.formatDateToDate(Holiday.date)}</Table.Cell>
          <Table.Cell>{Holiday.type}</Table.Cell>
          <Table.Cell>{Holiday.name}</Table.Cell>
          <Table.Cell>{Holiday.remarks}</Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={3} centered>
          <Grid.Column>
            <Form>
              <Form.Group widths='equal'>
                <Form.Field inline>
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>Year</label>
                  </div>
                  <Dropdown
                    closeOnEscape
                    id='Year'
                    selection
                    options={getYears()}
                    defaultValue={getYears()[1].value}
                    onChange={handleInputChange}
                  />
                </Form.Field>
                <Form.Field inline>
                  <div style={{ textAlign: 'center' }}>
                    <label style={{ fontWeight: 'bold' }}>Holiday Type</label>
                  </div>
                  <Dropdown
                    closeOnEscape
                    id='HolidayType'
                    selection
                    options={getHolidayTypes()}
                    defaultValue={getHolidayTypes()[0].value}
                    onChange={handleInputChange}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table compact striped celled selectable sortable>
        <Table.Header>
          <Table.Row textAlign='center'>
            <Table.HeaderCell width={1}>S.No. </Table.HeaderCell>
            <Table.HeaderCell sorted={sortColumn === 'date' ? sortDirection : null} onClick={() => handleSort('date')} width={5}>Date</Table.HeaderCell>
            <Table.HeaderCell sorted={sortColumn === 'type' ? sortDirection : null} onClick={() => handleSort('type')} width={2}>HolidayType</Table.HeaderCell>
            <Table.HeaderCell sorted={sortColumn === 'name' ? sortDirection : null} onClick={() => handleSort('name')} width={5}>Reason</Table.HeaderCell>
            <Table.HeaderCell sorted={sortColumn === 'remarks' ? sortDirection : null} onClick={() => handleSort('remarks')} width={5}> Remarks </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <RenderHolidaysTable/>
        </Table.Body>
      </Table>
    </>
  );
};

export default HolidaysTable;