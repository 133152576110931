import axios from 'axios'
import { config } from '../../Constants'
import { parseJwt } from './Helpers'

export const iipeerpapi = {
  authenticate,
  getUsers,
  getLeaves,
  getUserMe,
  getleaveEditHistory,
  getleaveEditHistoryWithApprovals,
  getHolidays,
  getLeaveBalance,
  getLeavesHistory,
  getFullUserDetails,
  updateApproval,
  updateDelegation,
  applyLeave,
  calculateleaves,
  updateLeave,
  cancelLeave,
  applySLLeave,
  submitJoiningForm,
  getApprovalLeaves,
  getLeavesforAdmin,
  getDelegatedLeaves,
  deleteLeaves,
  fileUpload,
  fileDownload,
  changepassword,
  leavejoiningform,
  leavejoiningformsforapprover,
  getleavejoiningforms,
  getleavejoiningform,
  deletejoiningform,
  leavejoiningApprovals,
  leaveChain,
  submitfeedback,
  getfeedback,
  getLeaveBalanceByUsername,
  updateLeaveBalance,
  previewupdateleavebalance,
  updateremarksbyadmin,
  getLeaveBalanceByHistory,
  getLeaveBalanceByHistoryForAdmin,
  getLeavesforAdminV2,
  getLeavesforAppliedPerson,
  getleavejoiningformV2,
  sendOTP,
  verifyOTP,
  changePassword,
  getleaveDetails,
  getleavebalanceforadmin,
  addComment,
  getComment,
  viewFile
}

function viewFile(user, fileId) {
  const url = `/api/tbafile/view/${fileId}`;
  return instance.get(url, {
    headers: {
      Authorization: bearerAuth(user),
    },
    responseType: 'arraybuffer' 
  });
}

function submitJoiningForm(user, payLoad) {
  return instance.post('/', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}
function addComment(user, comment)  {
  const url = '/api/comments/add';
  return instance.post(url, comment, {
    headers: { 'Authorization': bearerAuth(user) },
  });
};

function getComment(user, claimId) {
  const url = `api/comments/getcomments/${claimId}`; 
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  });
}

function authenticate(username, password) {
  return instance.post('/auth/authenticate', { username, password }, {
    headers: { 'Content-type': 'application/json' }
  })
}

function fileUpload(user, formData) {
  return instance.post('/file/upload', formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': bearerAuth(user)
    }
  })
}

function fileDownload(user, filename) {
  return instance.get(`/file/download/${filename}`, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': bearerAuth(user),
    },
    responseType: 'blob'
  })
}

// function applyLeave(user, leavePayload) {
//   return instance.post('/api/leaves/applyleave', leavePayload, {
//     headers: { 'Authorization': bearerAuth(user) }
//   })
// }

function applyLeave(user, leavePayload) {
  return instance.post('/api/leaves/v2/applyleave', leavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function calculateleaves(user, leavePayload) {
  return instance.post('/api/leaves/calculateleaves', leavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function updateLeave(user, leavePayload) {
  return instance.post('/api/leaves/leaveedit', leavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}



function cancelLeave(user , cancelLeavePayload) {
  return instance.put(`/api/leaves/cancelleave`, cancelLeavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function applySLLeave(user, leavePayload) {
  return instance.post('/api/leaves/applystationleave', leavePayload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function changepassword(user, payLoad) {
  return instance.post('/api/users/changepassword', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function submitfeedback(user, payLoad) {
  return instance.post('/api/users/feedback', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getfeedback(user) {
  return instance.get('/api/users/getfeedback', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leavejoiningformsforapprover(user) {
  return instance.get('api/leaves/leavejoiningformsforapprover', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leavejoiningform(user, payLoad) {
  return instance.post('/api/leaves/leavejoiningform', payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleavejoiningforms(user) {
  return instance.get('/api/leaves/getleavejoiningforms', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleavejoiningform(user, leavejoiningformid) {
  return instance.get(`/api/leaves/leavejoiningform/${leavejoiningformid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function deletejoiningform(user, leavejoiningformid) {
  return instance.delete(`/api/leaves/deletejoiningform/${leavejoiningformid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leaveChain(user, leaveID) {
  return instance.get(`api/leaves/leavechain/${leaveID}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}





function getUsers(user, username) {
  const url = username ? `/api/users/${username}` : '/api/users/allactiveusers'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}




function getHolidays() {
  const url = '/api/holidays'
  return instance.get(url, {
  })
}

function getLeaveBalance(user) {
  const url = 'api/leaves/leavebalance'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleavebalanceforadmin(user,payload) {
  const url = `api/leaves/getleavebalanceforadmin`
  return instance.post(url,payload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}
// new
function getLeaveBalanceByUsername(user,username) {
  const url = `api/leaves/leavebalancebyusername/${username}`
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeaveBalanceByHistory(user,leavetype) {
  const url = `api/leaves/leavebalancehistory?leavetype=${leavetype}`
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeaveBalanceByHistoryForAdmin(user,username,leavetype) {
  const url = `api/leaves/leavebalancehistoryforadmin?username=${username}&leavetype=${leavetype}`
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function updateremarksbyadmin(user,payload) {
  const url = `api/leaves/updateremarksbyadmin`
  return instance.post(url, payload,{
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function updateLeaveBalance(user,payload) {
  const url = `api/leaves/v2/updateleavebalance`
  return instance.post(url, payload,{
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function previewupdateleavebalance(user,payload) {
  const url = `api/leaves/previewupdateleavebalance`
  return instance.post(url, payload,{
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getFullUserDetails(user) {
  const url = 'api/users/newfullme'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeavesHistory(user) {
  const url = '/api/leaves/newleaves'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getDelegatedLeaves(user) {
  const url = '/api/leaves/delegatedleaves'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getApprovalLeaves(user) {
  const url = '/api/leaves/leavesforapprover'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeavesforAdmin(user) {
  const url = '/api/leaves/getleavesforadmin'
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getLeaves(user, text) {
  const url = `api/users/leaves`
  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}



function deleteLeaves(user, deleteLeavePayload) {
  return instance.delete(`/api/leaves/deleteleave`, {
    headers: { 'Authorization': bearerAuth(user) },
    data: deleteLeavePayload
  })
}

function updateApproval(user, payload, userId) {
  return instance.put(`/api/leaves/updateapproval/${userId}`, payload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function leavejoiningApprovals(user, payLoad, joinApprovalId) {
  return instance.put(`/api/leaves/leavejoiningapproval/${joinApprovalId}`, payLoad, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function updateDelegation(user, payload, userId) {
  return instance.put(`/api/leaves/updatedelegation/${userId}`, payload, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}


function getUserMe(user) {
  return instance.get('/api/users/fullme', {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function sendOTP(payLoad) {
  return instance.post('/api/forgotPassword/sendOTP', payLoad, {
    headers: { 'Content-type': 'application/json' }
  })
}

function verifyOTP(payLoad) {
  return instance.post('/api/forgotPassword/verifyOTP', payLoad, {
    headers: { 'Content-type': 'application/json' }
  })
}

function changePassword(payLoad) {
  return instance.post('/api/forgotPassword/changePassword', payLoad, {
    headers: { 'Content-type': 'application/json' }
  })
}

function getLeavesforAppliedPerson(user, queryParams) {
  let url = 'api/leaves/v2/getleavesforappliedperson';
  if (queryParams) {
    url += '?';
    Object.keys(queryParams).forEach((key, index) => {
      if (index !== 0) {
        url += '&';
      }
      url += `${key}=${queryParams[key]}`;
    });
  }

  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  });
}
function getLeavesforAdminV2(user, queryParams) {
  let url = 'api/leaves/v2/getleavesforadmin';
  if (queryParams) {
    url += '?';
    Object.keys(queryParams).forEach((key, index) => {
      if (index !== 0) {
        url += '&';
      }
      url += `${key}=${queryParams[key]}`;
    });
  }

  return instance.get(url, {
    headers: { 'Authorization': bearerAuth(user) }
  });
}

function getleavejoiningformV2(user, leavejoiningformid) {
  return instance.get(`/api/leaves/v2/leavejoiningform/${leavejoiningformid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleaveDetails(user, leaveid) {
  return instance.get(`/api/leaves/getleave/${leaveid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleaveEditHistory(user, leaveid) {
  return instance.get(`/api/leaves/getleaveedithistory/${leaveid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

function getleaveEditHistoryWithApprovals(user, leaveid) {
  return instance.get(`/api/leaves/getleaveedithistorywithapprovals/${leaveid}`, {
    headers: { 'Authorization': bearerAuth(user) }
  })
}

const instance = axios.create({
  baseURL: config.url.API_BASE_URL
})

instance.interceptors.request.use(function (config) {
  // If token is expired, redirect user to login
  if (config.headers.Authorization) {
    const token = config.headers.Authorization.split(' ')[1]
    const data = parseJwt(token)
    if ((Date.now() )> data.exp * 1000) {
      window.location.href = process.env.PUBLIC_URL+"/login";
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

// -- Helper functions

function bearerAuth(user) {
  return `Bearer ${user.accessToken}`
}