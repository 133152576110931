import React from 'react'
import { Link } from 'react-router-dom';
import {  Divider, Header, Segment, Tab, List } from 'semantic-ui-react'
import HolidaysTable from './HolidaysTable'
import LeaveHistory from './LeaveHistory'
import LeaveApprovals from './LeaveApprovals'

import CompleteLeaveHistoryNew from './CompleteLeaveHistoryNew';
import LeaveDelegations from './LeaveDelegations'
import JoiningForm from './JoiningForm'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { useEffect, useState,useCallback  } from "react";
import "./lms.css";
import { useAuth } from '../context/AuthContext'

import LeaveApply from './LeaveApply'
import LeaveBalance from './LeaveBalance'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import CompleteLeaveBalance from './CompleteLeaveBalance';
import LeaveBalanceHistory from './LeaveBalanceHistory';
import UpdateLeaveBalance from './UpdateLeaveBalance';
import ViewLeaveBalance from './ViewLeaveBalance';
import AppliedLeaveHistory from './AppliedLeaveHistory';
import { useParams, useNavigate } from 'react-router-dom'

function LMSTab(props) {
  const { tabName } = useParams();
  const navigate = useNavigate();
  const {  HolidaysList } = props
  const { isUsersLoading, userdetails, LeaveBalanceList, isLeaveBalanceLoading, users  } = props
 
  const { isOrdersLoading} = props

  const { getUser } = useAuth()

  const { handleGetLeaveBalance } = props
  const [LeaveList, setLeaveList] = useState([])


  const [loadLeaveHistory, setLoadLeaveHistory] = useState(true)
  const [activeIndex, setActiveIndex] = useState((props.userdetails == null ? 0 : props.userdetails.usertype === 'Employee' ? 0 : 2))
  let isAdmin = getUser().data.rol[0] === 'ADMIN'?true:false
  let regularHolidays = HolidaysList.filter(t=> t.type==='Regular')
  let restrictedHolidays = HolidaysList.filter(holiday => {
    // Check if this holiday is Restricted
    if (holiday.type !== 'Restricted') {
      return false;
    }
    
    // Check if there's any Regular holiday on the same date
    const hasRegularOnSameDate = HolidaysList.some(otherHoliday => 
      otherHoliday.type === 'Regular' && 
      otherHoliday.date === holiday.date
    );
    
    // Keep this holiday only if there's no Regular holiday on the same date
    return !hasRegularOnSameDate;
  });

  const handleLoadLeaveHistory = (isLoad) => {
    setLoadLeaveHistory(isLoad);
  }
  const handleGetLeaves = () => {
    const user = getUser()

    iipeerpapi.getLeavesHistory(user)
      .then(response => {
        const sortedData = response.data.sort((a, b) => {
          return b.start_date - a.start_date;
        })
        setLeaveList(sortedData)
      })
      .catch(error => {
        handleLogError(error)
      })
      .finally(() => {
      })
  }
  
  const getPanes = useCallback(() => [
    
    {
      ...((props.userdetails == null ? false : props.userdetails.usertype === 'Employee') && {
        menuItem: { key: 'applyleave', icon: 'pen square', content: 'Apply Leave' },
        render: () => (
          <Tab.Pane loading={isUsersLoading} >
            <LeaveApply
              userdetails={userdetails}
              users={users}
              restrictedHolidays={restrictedHolidays}
              HolidaysList={regularHolidays}
              handleGetLeaveBalance={handleGetLeaveBalance}
              loadLeaveHistory={loadLeaveHistory}
              LeaveBalanceList={LeaveBalanceList}
              handleLoadLeaveHistory={handleLoadLeaveHistory}
            />
          </Tab.Pane >
        )
      }),
    },
   {

    ...((props.userdetails == null ? false : props.userdetails.usertype === 'Employee') && {
         menuItem: { key: 'appliedleaveshistory', icon: 'laptop', content: 'LeaveHistory' },
        render: () => (
          <Tab.Pane loading={isUsersLoading} >
            <AppliedLeaveHistory
               userdetails={userdetails}
               HolidaysList={regularHolidays}
               LeaveBalanceList={LeaveBalanceList}
               handleGetLeaveBalance={handleGetLeaveBalance}
               users={users}
               restrictedHolidays={restrictedHolidays}
               
              />
          </Tab.Pane >
          
        ),
          
   
      
    }),
    },
    // {
    //   ...((props.userdetails == null ? false : props.userdetails.usertype === 'Employee') && {
    //   menuItem: { key: 'appliedleaveshistory', icon: 'laptop', content: 'LeaveHistory' },
    //   render: () => (
    //     <Tab.Pane loading={isOrdersLoading}>
    //       <LeaveHistory
    //         userdetails={userdetails}
    //         HolidaysList={regularHolidays}
    //         loadLeaveHistory={loadLeaveHistory}
    //         handleLoadLeaveHistory={handleLoadLeaveHistory}
    //         handleGetLeaves={handleGetLeaves}
    //         LeaveBalanceList={LeaveBalanceList}
    //         LeaveList={LeaveList}
    //         users={users}
    //         handleGetLeaveBalance={handleGetLeaveBalance}
          
    //       />
    //     </Tab.Pane>
    //   )
    // }),
    // },
    {
      menuItem: { key: 'leaveapprovals', icon: 'check', content: 'Approval for Leaves' },



      render: () => (
        <Tab.Pane loading={isUsersLoading}>
          <LeaveApprovals
          />
        
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: 'leavejoiningapprovals', icon: 'wordpress forms', content: 'Approvals for LeaveJoining Form' },



      render: () => (
        <Tab.Pane loading={isUsersLoading}>
          <JoiningForm />
          
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: 'leavedelegations', icon: 'handshake', content: 'Delegations' },
      render: () => (
        <Tab.Pane loading={isOrdersLoading}>
          <LeaveDelegations
          />
   
        </Tab.Pane>
      )
    },
    {
      ...((isAdmin===true ) && {
        menuItem: { key: 'completeleavehistory', icon: 'pen square', content: 'Complete Leave History' },
        render: () => (
          <Tab.Pane loading={isUsersLoading} >
            <CompleteLeaveHistoryNew
              
              />
          </Tab.Pane >
          
        ),
          
   
      }),
      
    },
    
    {
      menuItem: { key: 'holidayslist', icon: 'gift', content: 'Holidays' },
      render: () => (
        <Tab.Pane >
          
          <HolidaysTable
            HolidaysList={HolidaysList}
          
          />
        </Tab.Pane>
      )
    },  

    (isAdmin===true ) &&   {
      menuItem: { key: 'completeleavebalance', icon: 'balance', content: 'Complete Leave Balance' },
      render: () => (
        <Tab.Pane >
          <CompleteLeaveBalance
          users={users}
          />
       
        </Tab.Pane>
      )
    },
    (isAdmin===true ) && {
      menuItem: { key: 'updateleavebalance', icon: 'upload', content: 'Update Leave Balance' },
      render: () => (
        <Tab.Pane >
          <UpdateLeaveBalance 
          users={users}
          />
       
        </Tab.Pane>
      )
    },
    (isAdmin===true ) && {
      menuItem: { key: 'viewleavebalance', icon: 'list', content: 'View Leave Balance' },
      render: () => (
        <Tab.Pane >
          <ViewLeaveBalance 
          users={users}
          />
        
        </Tab.Pane>
      )
    },
    {
      menuItem: { key: 'leavebalancetracker', icon: 'history', content: 'Leave Balance Tracker' },
      render: () => (
        <Tab.Pane >
          <LeaveBalanceHistory 
          users={users}
          LeaveBalanceList={LeaveBalanceList}
          />
          {/* <HolidaysTable
            HolidaysList={HolidaysList}
          
          /> */}
        </Tab.Pane>
      )
    }
  ], [props.userdetails, isUsersLoading, userdetails, users, regularHolidays, handleGetLeaveBalance, loadLeaveHistory, LeaveBalanceList, handleLoadLeaveHistory, isOrdersLoading, isAdmin]);

  useEffect(() => {
    setActiveIndex((props.userdetails == null ? 0 : props.userdetails.usertype === 'Employee' ? 0 : 2));
  }, [props.userdetails]);

  useEffect(() => {
    if (tabName) {
      const panes = getPanes();
      const index = panes.findIndex(pane => pane && pane.menuItem && pane.menuItem.key === tabName);
      if (index !== -1) {
        setActiveIndex(index);
      }
    }
  }, [tabName, getPanes]);

  const handleTabChange = (e, { activeIndex }) => {
    const panes = getPanes();
    const newTabName = panes[activeIndex].menuItem.key
    navigate(`/lms/${newTabName}`)
  }


  

 

  
  const sitedocsPath = '/sitedocs/'; // The path to your sitedocs folder
  const fileName = 'MEDICAL CERTIFICATE.pdf';

  
  return (

    <>
      <Segment inverted color='violet'textAlign='center' fluid size='huge' >Leave Management System</Segment>

      {
        (props.userdetails == null ? false : props.userdetails.usertype === 'Employee') &&
        (<LeaveBalance
          LeaveBalanceList={LeaveBalanceList}
          isLeaveBalanceLoading={isLeaveBalanceLoading}

        />)}
      <Divider horizontal></Divider>
      <Segment loading={isLeaveBalanceLoading}>

        <Header textAlign="center">
          Leave Information
        </Header>

        <List as='ol'>
          <List.Item as='li'>A half-day leave is classified as a half-day under Casual Leaves and as a full-day leave under other leave types.</List.Item>
          <List.Item as='li'>If a Commuted leave is taken, twice the number of days availed shall be debited from the Half pay leave count.</List.Item>
          <List.Item as='li'>While applying a Commuted leave, attaching required documents is mandatory. Please download <Link to={sitedocsPath + fileName} target="_blank" download>
        Medical & Fitness Certificate.
      </Link></List.Item>
        </List>
      </Segment>
      <Divider horizontal></Divider>
      <Tab menu={{
        attached: "top",
        tabular: true,
        style: {
          display: "flex",
          justifyContent: "center"
        },
        className: "wrapper",

      }} panes={getPanes()}  activeIndex={activeIndex}
        onTabChange={handleTabChange}
      />
    </>
  )
}

export default LMSTab