import React, { useState, useEffect, useContext, useRef } from 'react';
import { Message, Divider, Dropdown, TextArea } from 'semantic-ui-react';
import { Container, Header, Icon, Button, Segment, Grid, Tab, Form, Input, Radio, GridRow } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import AuthContext from '../context/AuthContext';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Toasts from '../misc/Toasts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetimeutils from "../utils/DateTimeUtils";
import FileAttachmentUtils from '../utils/FileAttachmentUtils';


const LeaveEdit = ({ handleEditModalClose, userdetails, leaveid, users, HolidaysList, restrictedHolidays, LeaveBalanceList, handleGetLeaveBalance, handleGetLeaves, modalContentRef }) => {

  //const modalContentRef = useRef(null);
  const [shouldCalculateLeaves, setShouldCalculateLeaves] = useState(false);



  const inputLeavetypeRef = useRef();
  const fileInputRef = useRef();
  const Auth = useContext(AuthContext);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [officialDutyData, setOfficialDutyData] = useState([]);
  const [detailsstate, setDetailsState] = useState({
    leaveid,
    disableleaveextension:false,
    isleaveextension: false,
    userdetails,
    users,
    HolidaysList,
    LeaveBalanceList,
    handleGetLeaveBalance,
    orderDescription: '',
    orderTextSearch: '',
    userUsernameSearch: '',
    isAdmin: true,
    isUsersLoading: false,
    isOrdersLoading: false,
    isLoggedIn: false,
    isApprover: true,
    encashedleaves: 0,
    isLeaveBalanceLoading: false,
    inputLeaveStartDate: null,
    inputLeaveEndDate: null,
    LDStartDate: null,
    LDEndDate: null,
    startdaysession: 'FN',
    lastdaysession: 'AN',
    ldstartdaysession: 'FN',
    ldlastdaysession: 'AN',
    numDays: 0,
    numSatandSundays:0,
    inputLeaveTypeError: false,
    error: false,
    error2: false,
    error3: false,
    inputLeavetype: '',
    leavePayload: {},
    numLeaveAppliedDays: 0,
    numleaveEffectiveDays: 0,
    numNationalHolidays: 0,
    inputLeaveComments: '',
    addressduringleave: '',
    inputLeavePhone: '',
    inputLeaveOutOfStation: false,
    inputLeaveOutOfStationStartDate: null,
    inputLeaveOutOfStationEndDate: null,
    inputLeaveDelegations: [],
    leaveApprovals: [],
    disableoutofstationdates: true,
    delegation1username: '',
    delegation1natureofwork: '',
    Delegation1StartDate: null,
    Delegation1EndDate: null,
    delegation2username: '',
    delegation2natureofwork: '',
    Delegation2StartDate: null,
    Delegation2EndDate: null,
    delegation3username: '',
    delegation3natureofwork: '',
    Delegation3StartDate: null,
    Delegation3EndDate: null,
    selectedValue: null,
    applySuccess: false,
    attachmentid: null,
    file: null,
    fileId: '',
    fileName: '',
    inputLeaveOutOfStationError: false,
    inputLeaveOutOfStationStartDateError: false,
    inputLeaveOutOfStationEndDateError: false,
    inputLeaveCommentsError: false,
    inputLeaveAddressError: false,
    inputLeavePhoneError: false,
    inputLeavePhoneLengthError: false,
    inputLeaveDelegation1Error: false,
    inputLeaveDelegation2Error: false,
    inputLeaveDelegation3Error: false,
    fillAllFieldsError: false,
    leavePrefixes: [],
    leaveSuffixes: [],
    isstationLeave: false,
    applyingforltc: false,
    officialtour: false,
    disableofficialtour: false,
    outofstationnotproper: false,
    offcialengdatesnotproper: false
  });

  const updateDetailsState = (apiResponse) => {
    console.log('apiResponse.attachements_details' + apiResponse.attachements_details)
    setDetailsState(prevState => ({
      ...prevState,
      leaveid: apiResponse.leaveid,
      isleaveextension: apiResponse.isleaveextension==null?false:apiResponse.isleaveextension,
      isstationLeave: apiResponse.leavetype === 'SL',
      inputLeavetype: apiResponse.leavetype,
      numLeaveAppliedDays: apiResponse.appliedDuration,
      numleaveEffectiveDays: apiResponse.effectiveleaves,
      inputLeaveStartDate: new Date(datetimeutils.formatDateTimeToDateWithOutConversion(apiResponse.start_date)),
      inputLeaveEndDate: new Date(datetimeutils.formatDateTimeToDateWithOutConversion(apiResponse.end_date)),
      startdaysession: apiResponse.startdaysession,
      lastdaysession: apiResponse.lastdaysession,
      inputLeaveComments: apiResponse.comments,
      inputLeaveOutOfStation: apiResponse.isoutofstation,
      inputLeaveOutOfStationStartDate: apiResponse.isoutofstation ? new Date(datetimeutils.formatDateTimeToDateTimeWithConversion(apiResponse.outofstationstartdate)) : null,
      inputLeaveOutOfStationEndDate: apiResponse.isoutofstation ? new Date(datetimeutils.formatDateTimeToDateTimeWithConversion(apiResponse.outofstationenddate)) : null,
      addressduringleave: apiResponse.addressduringleave,
      phonenoduringleave: apiResponse.phonenoduringleave,
      numSatandSundays: apiResponse.satandsundays, // This might need adjustment if it includes both Saturdays and Sundays
      
      numNationalHolidays: apiResponse.holidays,
      leavePrefixes: apiResponse.prefixes,
      leaveSuffixes: apiResponse.suffixes,
      attachmentid: apiResponse.attachements_details===''?null:apiResponse.attachements_details,
      encashedleaves: apiResponse.encashedleaves,
      // Mapping delegations (assuming up to 3 delegations)
      // ...(apiResponse.leaveDelegations.length > 0 && {
      //   delegation1username: apiResponse.leaveDelegations[0].username,
      //   delegation1natureofwork: apiResponse.leaveDelegations[0].natureofwork,
      //   Delegation1StartDate: new Date(apiResponse.leaveDelegations[0].startday),
      //   Delegation1EndDate: new Date(apiResponse.leaveDelegations[0].lastday),
      // }),
      // ...(apiResponse.leaveDelegations.length > 1 && {
      //   delegation2username: apiResponse.leaveDelegations[1].username,
      //   delegation2natureofwork: apiResponse.leaveDelegations[1].natureofwork,
      //   Delegation2StartDate: new Date(apiResponse.leaveDelegations[1].startday),
      //   Delegation2EndDate: new Date(apiResponse.leaveDelegations[1].lastday),
      // }),
      // ...(apiResponse.leaveDelegations.length > 2 && {
      //   delegation3username: apiResponse.leaveDelegations[2].username,
      //   delegation3natureofwork: apiResponse.leaveDelegations[2].natureofwork,
      //   Delegation3StartDate: new Date(apiResponse.leaveDelegations[2].startday),
      //   Delegation3EndDate: new Date(apiResponse.leaveDelegations[2].lastday),
      // }),

      // Fields that might need additional logic or aren't directly mapped
      numDays: apiResponse.appliedDuration, // This might need to be calculated differently
      disableoutofstationdates: !apiResponse.isoutofstation,
      //isstationLeave: apiResponse.isoutofstation,
      applyingforltc: apiResponse.isavailingltc, // This information isn't in the API response
      officialtour: apiResponse.additionalfields.officialdutydata != null ? apiResponse.additionalfields.officialdutydata.isOnOfficialDuty === true : false,
      disableofficialtour: apiResponse.additionalfields.officialdutydata == null ? false : apiResponse.additionalfields.officialdutydata.isOnOfficialDuty === true
      //disableofficialtour: !apiResponse.additionalfields.isOnOfficialDuty,
    }));

    if ((apiResponse.additionalfields.officialdutydata != null && apiResponse.additionalfields.officialdutydata.isOnOfficialDuty === true) || apiResponse.inputLeavetype === 'LD') {

      const officialdutydata = apiResponse.additionalfields.officialdutydata.fieldsandvalues
      setDetailsState(prevState => ({
        ...prevState,

        isOnOfficialDuty: officialdutydata.officialtour,
        LDStartDate: new Date(datetimeutils.formatDateTimeToDateWithOutConversion(officialdutydata.LDStartDate)),
        ldstartdaysession: officialdutydata.ldstartdaysession,
        LDEndDate: new Date(datetimeutils.formatDateTimeToDateWithOutConversion(officialdutydata.LDEndDate)),
        ldlastdaysession: officialdutydata.ldlastdaysession,
        placestobevisited: officialdutydata.placestobevisited,
        purposeofvisit: officialdutydata.purposeofvisit,
        estimatedbudget: officialdutydata.estimatedbudget,
        budgethead: officialdutydata.budgethead,
        modeofjourney: officialdutydata.modeofjourney,
        classofjourney: officialdutydata.classofjourney,
      }));
    }
  };

  // Usage


  const authContext = useContext(AuthContext);
  const user = authContext.getUser();
  const handleGetLeaveDetailsForLeaveID = async (leaveId) => {
    try {
      iipeerpapi.getleaveDetails(user, leaveId)
        .then(response => {
          setSelectedLeave(response.data);
          updateDetailsState(response.data);
          (response.data.additionalfields === null || response.data.additionalfields.officialdutydata === null) ? setOfficialDutyData([]) :
            setOfficialDutyData(response.data.additionalfields.officialdutydata);
          //setOpen(true);
        })
        .catch(error => {
          handleLogError(error);
        })
    }
    catch (error) {

    }
  };


  //const { userdetails } = props




  const { isstationLeave } = detailsstate;
  const { inputLeavetype } = detailsstate;
  const { isleaveextension } = detailsstate;

  const { inputLeaveStartDate, inputLeaveEndDate } = detailsstate;
  const handleFileChange = (e) => {
    e.preventDefault();
    const fileInput = e.target;

    if (fileInput.files && fileInput.files.length > 0) {
      console.log(fileInput.files[0]);
      const selectedFile = fileInput.files[0];
      setDetailsState(prevState => ({
        ...prevState,
        fileName: selectedFile.name,
        file: selectedFile
      }));
      fileInput.value = null;
    }
  };

  const handleClear = (e) => {
    e.preventDefault();
    setDetailsState(prevState => ({
      ...prevState,
      fileName: '',
      file: null
    }));
  };
  // const renderFileUpload = () => (
  //   <Form.Group widths='equal'>
  //     <Form.Field>
  //       <Button
  //         fluid
  //         content="Choose File"
  //         // labelPosition="left"
  //         icon="file"
  //         onClick={(e) => { e.preventDefault(); fileInputRef.current.click(); }}
  //       />
  //       <input
  //         type="file"
  //         ref={fileInputRef}
  //         hidden
  //         onChange={handleFileChange}
  //       />
  //     </Form.Field>
  //     <Form.Field>
  //       <Button
  //         content="Clear"
  //         fluid
  //         onClick={handleClear}
  //       />
  //     </Form.Field>
  //   </Form.Group>
  // );


  const renderFileUpload = () => {
    return (
        <>
            <Form.Group widths='equal'>
                <Form.Field>
                    <Button
                        content="Choose File"
                        labelPosition="left"
                        icon="file"
                        onClick={(e) => { e.preventDefault(); fileInputRef.current.click(); }}
                    />
                    <input
                        type="file"
                        ref={fileInputRef}
                        hidden
                        onChange={handleFileChange}
                    />
                </Form.Field>
                <Form.Field>
                    <Button
                        content="Clear"
                        onClick={handleClear}
                    />
                </Form.Field>
            </Form.Group>
        </>

    );
}

  const error = ('Error state', false);

  useEffect(() => {
    // Call the API to get leave details
    handleGetLeaveDetailsForLeaveID(leaveid)
    //.then(() => {
    // After the details are fetched, set the flag to indicate that we can calculate leaves
    //setShouldCalculateLeaves(true);
    // });
  }, []);

  useEffect(() => {
    const { inputLeavetype, inputLeaveStartDate, startdaysession, inputLeaveEndDate, lastdaysession } = detailsstate;

    // Only calculate leaves if the flag is true and input parameters are valid
    if (
      inputLeavetype !== 'LD' &&
      inputLeavetype !== 'SL' &&
      inputLeaveStartDate &&
      startdaysession &&
      inputLeaveEndDate &&
      lastdaysession) {

      calculateLeaves();
      // Reset the flag to prevent re-calculating on subsequent updates
    }
    else {
      setDetailsState(prevState => ({
        ...prevState,
        isError: false,
        numSatandSundays: '',
        numNationalHolidays: '',
        numLeaveAppliedDays: '',
        numleaveEffectiveDays: '',
        leavePrefixes: [],
        leaveSuffixes: [],
      }));
    }
  }, [detailsstate.inputLeavetype, detailsstate.inputLeaveStartDate, detailsstate.startdaysession, detailsstate.inputLeaveEndDate, detailsstate.lastdaysession]);
  // useEffect(() => {
  //   const { inputLeavetype, inputLeaveStartDate, startdaysession, inputLeaveEndDate, lastdaysession } = detailsstate;
  //   if (inputLeavetype && inputLeaveStartDate && startdaysession && inputLeaveEndDate && lastdaysession) {
  //     calculateLeaves();
  //   }
  // }, [detailsstate.inputLeavetype, detailsstate.inputLeaveStartDate, detailsstate.startdaysession, detailsstate.inputLeaveEndDate, detailsstate.lastdaysession]);



  useEffect(() => {
    const user = Auth.getUser();
    const isAdmin = user.data.rol[0] === 'ADMIN';
    const isLoggedIn = Auth.userIsAuthenticated();
    setDetailsState(prevState => ({ ...prevState, isAdmin, isLoggedIn }));
  }, [Auth]);

  const leaveTypeHandler = (e, { id, value }) => {
    setDetailsState(prevState => {
      let newState = { ...prevState, [id]: value };
      if (value === 'SL') {
        newState = {
          ...newState,
          inputLeaveOutOfStation: true,
          isstationLeave: true,
          disableoutofstationdates: false,
          officialtour: false,
          disableofficialtour: false,
          disableleaveextension: true
        };
      } else if (value === 'LD') {
        newState = {
          ...newState,
          officialtour: true,
          disableofficialtour: true,
          disableleaveextension: true
        };
      } else {
        newState = {
            ...newState,
            disableleaveextension:false
        }
        // newState = {
        //   ...newState,
        //   officialtour: false,
        //   disableofficialtour: false,
        //   outofstationstartdate: null,
        //   outofstationenddate: null,
        //   isstationLeave: false,
        //   // inputLeaveStartDate: null,
        //   // inputLeaveEndDate: null,
        //   // numSundays: 0,
        //   // numSaturdays: 0,
        //   // numNationalHolidays: 0,
        //   // numleaveEffectiveDays: 0,
        //   disableoutofstationdates: newState.inputLeaveOutOfStation ? false : true
        // };
      }
      newState.inputLeaveTypeError = (value == null || value === '');
      //calculateNumDays();
      return newState;
    });
  };

  const RadioExampleRadioGroupLDFirstDay = () => {
    const [value, setValue] = useState('' + detailsstate.ldstartdaysession);
    const handleChange = (event, { value }) => {
      setValue(value);
      setDetailsState(prevState => ({
        ...prevState,
        ldstartdaysession: value
      }))


      //calculateNumDays(HolidaysList,detailsstate.inputLeavetype, detailsstate.inputLeaveStartDate, detailsstate.inputLeaveEndDate);
    }

    return (

      <Form.Group style={{ marginRight: '40px', marginTop: '30px', marginBottom: '0px' }} disabled={!detailsstate.officialtour}>

        <Form.Field disabled={!detailsstate.officialtour}>
          <Radio
            label='FN'
            name='ldstartdaysession'
            value='FN'
            checked={value === 'FN'}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field disabled={!detailsstate.officialtour}>
          <Radio
            label='AN'
            name='ldstartdaysession '
            value='AN'
            checked={value === 'AN'}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>

    )
  };

  const handleSLSubmit = async (e) => {
    e.preventDefault();

    const user = Auth.getUser()
    let { inputLeavetype, inputLeaveComments, addressduringleave, phonenoduringleave } = detailsstate
    let { startdaysession, lastdaysession } = detailsstate
    let { inputLeaveOutOfStation, inputLeaveOutOfStationStartDate, inputLeaveOutOfStationEndDate } = detailsstate
    let { delegation1username, delegation1natureofwork, Delegation1StartDate, Delegation1EndDate } = detailsstate
    let { delegation2username, delegation2natureofwork, Delegation2StartDate, Delegation2EndDate } = detailsstate
    let { delegation3username, delegation3natureofwork, Delegation3StartDate, Delegation3EndDate } = detailsstate

    if (errorsForSLFound()) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
      return
    }

    //leavetype = leavetype.trim()

    let leavePayload = detailsstate.leavePayload
    let fileIds = ''
    if (detailsstate.file != null) {

      const user = Auth.getUser()
      let fileId
      const formData = new FormData();
      formData.append('file', detailsstate.file);
      // formData.append('fileName', file.name);
      await iipeerpapi.fileUpload(user, formData)
        .then(response => {
          fileIds = response.data.fileId

        })
        .catch(error => {
          fileIds = ''
          handleLogError(error)
          Toasts.notifyError("Error in uploading attachment!")
          return
        })
        .finally(() => {
        })

    }

    if (detailsstate.file != null && fileIds === '') {
      return
    }
    let officialdutypayload = {};
    let officialdutydata = {}
    officialdutydata.officialdutydata = {}
    if (detailsstate.officialtour === true || detailsstate.inputLeavetype === 'LD') {
      officialdutydata.officialdutydata.isOnOfficialDuty = detailsstate.officialtour
      officialdutypayload.LDStartDate = detailsstate.LDStartDate
      officialdutypayload.ldstartdaysession = detailsstate.ldstartdaysession
      officialdutypayload.LDEndDate = detailsstate.LDEndDate
      officialdutypayload.ldlastdaysession = detailsstate.ldlastdaysession
      officialdutypayload.placestobevisited = detailsstate.placestobevisited
      officialdutypayload.purposeofvisit = detailsstate.purposeofvisit
      officialdutypayload.estimatedbudget = detailsstate.estimatedbudget
      officialdutypayload.budgethead = detailsstate.budgethead
      officialdutypayload.modeofjourney = detailsstate.modeofjourney
      officialdutypayload.classofjourney = detailsstate.classofjourney
    }
    else {
      officialdutydata.isOnOfficialDuty = detailsstate.officialtour
    }
    officialdutydata.officialdutydata.fieldsandvalues = officialdutypayload
    officialdutydata.officialdutydata = officialdutydata.officialdutydata
    leavePayload.additionalfields = officialdutydata
    leavePayload.attachements_details = fileIds
    leavePayload.leavetype = inputLeavetype
    leavePayload.leavestatus = 'PENDING AT DELEGATIONS'
    leavePayload.appliedPersonUsername = user.data.preferred_username

    if (inputLeavetype === 'SL') {

      leavePayload.isoutofstation = true
      leavePayload.start_date = detailsstate.inputLeaveStartDate
      leavePayload.end_date = detailsstate.inputLeaveEndDate
      leavePayload.startdaysession = startdaysession
      leavePayload.startdaysession = lastdaysession
      //format(inputLeaveOutOfStationEndDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX");
      leavePayload.outofstationstartdate = ''
      leavePayload.outofstationstartdate = inputLeaveOutOfStationStartDate.toISOString().replace(/\.\d{3}Z$/, '');
      //format(inputLeaveOutOfStationStartDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX");

      leavePayload.outofstationenddate = ''
      leavePayload.outofstationenddate = inputLeaveOutOfStationEndDate.toISOString().replace(/\.\d{3}Z$/, '');
      //format(inputLeaveOutOfStationEndDate, "yyyy-MM-dd'T'HH:mm:ss.SSSX");

    }
    else if (inputLeavetype === 'LD') {
      leavePayload.start_date = detailsstate.LDStartDate
      leavePayload.end_date = detailsstate.LDEndDate
      leavePayload.startdaysession = detailsstate.ldstartdaysession
      leavePayload.lastdaysession = detailsstate.ldlastdaysession
      //calculateNumDays('LD',detailsstate.LDStartDate,detailsstate.LDEndtDate )
      //leavePayload.appliedduration = numleaveEffectiveDays


      leavePayload.isoutofstation = inputLeaveOutOfStation
      if (leavePayload.isoutofstation === true) {
        leavePayload.outofstationstartdate = inputLeaveOutOfStationStartDate
        leavePayload.outofstationenddate = inputLeaveOutOfStationEndDate
      }
    }
    leavePayload.isleaveextension = detailsstate.disableleaveextension?false:detailsstate.isleaveextension
    leavePayload.comments = inputLeaveComments
    leavePayload.addressduringleave = addressduringleave
    leavePayload.phonenoduringleave = phonenoduringleave
    leavePayload.leaveDelegations = []
    leavePayload.leaveApprovals = []
    leavePayload.isavailingltc = detailsstate.applyingforltc
    if (!(delegation1natureofwork == null || delegation1natureofwork.trim() === '' || delegation1username == null ||
      Delegation1StartDate == null || Delegation1EndDate == null)) {

      let inputLeaveDelegation = {}
      inputLeaveDelegation.username = delegation1username
      inputLeaveDelegation.natureofwork = delegation1natureofwork
      inputLeaveDelegation.startday = Delegation1StartDate
      inputLeaveDelegation.lastday = Delegation1EndDate
      inputLeaveDelegation.status = 'PENDING'
      leavePayload.leaveDelegations.push(inputLeaveDelegation)
    }
    if (!(delegation2natureofwork == null || delegation2natureofwork.trim() === '' || delegation2username == null ||
      Delegation2StartDate == null || Delegation2EndDate == null)) {

      let inputLeaveDelegation = {}
      inputLeaveDelegation.username = delegation2username
      inputLeaveDelegation.natureofwork = delegation2natureofwork
      inputLeaveDelegation.startday = Delegation2StartDate
      inputLeaveDelegation.lastday = Delegation2EndDate
      inputLeaveDelegation.status = 'PENDING'
      leavePayload.leaveDelegations.push(inputLeaveDelegation)
    }
    if (!(delegation3natureofwork == null || delegation3natureofwork.trim() === '' || delegation3username == null ||
      Delegation3StartDate == null || Delegation3EndDate == null)) {

      let inputLeaveDelegation = {}
      inputLeaveDelegation.username = delegation3username
      inputLeaveDelegation.natureofwork = delegation3natureofwork
      inputLeaveDelegation.startday = Delegation3StartDate
      inputLeaveDelegation.lastday = Delegation3EndDate
      inputLeaveDelegation.status = 'PENDING'
      leavePayload.leaveDelegations.push(inputLeaveDelegation)
    }
    let approvals = []
    approvals = Object.keys(userdetails || {}).length === 0 ? [] : userdetails.allApprovalUsers
    let counter = 1;
    leavePayload.leaveApprovals = approvals.length === 0 ? [] : approvals.map(user => {
      return ({
        username: user.username,
        status: '',
        approversequence: counter++
      })
    })

    iipeerpapi.applySLLeave(user, leavePayload)

      .then(response => {
        setDetailsState({
          isError: false,

        })
        // scrollToTop();

        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: "smooth"
        // });
        // window.location.reload();
        Toasts.notifySuccess("Leave Application Submitted Successfully!")
        // props.handleGetLeaveBalance()
        // Toasts.notifySuccess("Leave Balance Successfully Updated.")
        handleGetLeaves()
      })
      .catch(error => {
        handleLogError(error)
        setDetailsState({ isError: true })
        Toasts.notifyError("Error During Leave Submission")
      }).finally(() => {
      });

  }

  const errorsFound = () => {
    console.log('inno1');
    let errorsFound = false
    if (detailsstate.error === true ||
      detailsstate.error2 === true ||
      detailsstate.inputLeavetype == null ||
      (detailsstate.inputLeavetype === 'COL' && (detailsstate.fileName == null || detailsstate.fileName === '')) ||
      detailsstate.inputLeavetype.trim() === '' ||
      detailsstate.inputLeaveStartDate == null ||
      detailsstate.inputLeaveEndDate == null ||
      detailsstate.inputLeaveOutOfStation == null ||
      (detailsstate.inputLeaveOutOfStation === true && (detailsstate.inputLeaveOutOfStationStartDate == null || detailsstate.inputLeaveOutOfStationEndDate == null)) ||
      (detailsstate.inputLeaveComments == null || detailsstate.inputLeaveComments.trim() === '') ||
      (detailsstate.addressduringleave == null || detailsstate.addressduringleave.trim() === '') ||
      (detailsstate.phonenoduringleave == null || detailsstate.phonenoduringleave.trim() === '') ||
      // (detailsstate.delegation1natureofwork == null || detailsstate.delegation1natureofwork.trim() === '' || detailsstate.delegation1username == null ||
      //   detailsstate.Delegation1StartDate == null || detailsstate.Delegation1EndDate == null) ||
      ((detailsstate.officialtour === true) &&
        (detailsstate.LDStartDate == null || detailsstate.LDStartDate === '' ||
          detailsstate.LDEndDate == null || detailsstate.LDEndDate === '' ||
          detailsstate.placestobevisited == null || detailsstate.placestobevisited === '' ||
          detailsstate.purposeofvisit == null || detailsstate.purposeofvisit === '' ||
          detailsstate.estimatedbudget == null || detailsstate.estimatedbudget === '' ||
          detailsstate.budgethead == null || detailsstate.budgethead === '' ||
          detailsstate.modeofjourney == null || detailsstate.modeofjourney === '' ||
          detailsstate.classofjourney == null || detailsstate.classofjourney === '')
        )
    ) {
 
      setDetailsState(prevState => ({ ...prevState, fillAllFieldsError: true }))
      errorsFound = true
    }
    else {
      setDetailsState(prevState => ({ ...prevState, fillAllFieldsError: false }))
    }
    if (detailsstate.phonenoduringleave != null && detailsstate.phonenoduringleave.trim() !== '' && detailsstate.phonenoduringleave.trim().length !== 10) {
  
      setDetailsState(prevState => ({ ...prevState, inputLeavePhoneLengthError: true }))
    }
    else {
      
      setDetailsState(prevState => ({ ...prevState, inputLeavePhoneLengthError: false }))
    }
    // if ((errorsFound === false) && (detailsstate.delegation1natureofwork == null || detailsstate.delegation1natureofwork.trim() === '' || detailsstate.delegation1username == null || detailsstate.delegation1username === ''
    //   || detailsstate.Delegation1StartDate == null || detailsstate.Delegation1EndDate == null)) {
    //   // setDetailsState(prevState => ({ ...prevState, inputLeaveDelegation1Error: true }))
    //   // errorsFound = true
    //   //return true;
    // }
    // else {
    //   setDetailsState(prevState => ({ ...prevState, inputLeaveDelegation1Error: false }))
    // }
    if (detailsstate.applyingforltc === true && (detailsstate.encashedleaves > 10)) {
      errorsFound = true;
      
      setDetailsState(prevState => ({ ...prevState, showltcencashErrorMessage: true }));
      return true;
    } else {
      setDetailsState(prevState => ({ ...prevState, showltcencashErrorMessage: false }));
    }

    if (errorsFound === true) {
      return true
    }

    setDetailsState(prevState => ({ ...prevState, fillAllFieldsError: false }))

    return false;
  }

  const calculateLeaves = async () => {

    const leavePayload = {
      leaveType: detailsstate.inputLeavetype,
      startDate: detailsstate.inputLeaveStartDate,
      //startDate: datetimeutils.formatDateToDate(detailsstate.inputLeaveStartDate),
      startDaySession: detailsstate.startdaysession,
      endDate: detailsstate.inputLeaveEndDate,
      //endDate: datetimeutils.formatDateToDate(detailsstate.inputLeaveEndDate),
      lastDaySession: detailsstate.lastdaysession,
    };
    const user = Auth.getUser();
    iipeerpapi.calculateleaves(user, leavePayload)
      .then(response => {
        const calculatedLeave = response.data;
        setDetailsState(prevState => ({
          ...prevState,
          isError: false,
          numSatandSundays: calculatedLeave.numSatandSundays,
          numNationalHolidays: calculatedLeave.numNationalHolidays,
          numLeaveAppliedDays: calculatedLeave.numLeaveAppliedDays,
          numleaveEffectiveDays: calculatedLeave.effectiveLeaves,
          leavePrefixes: calculatedLeave.leavePrefixes,
          leaveSuffixes: calculatedLeave.leaveSuffixes,
        }));

        //Toasts.notifySuccess("Leave calculation completed successfully!");
        //handleGetLeaves(true);
      })
      .catch(error => {
        setDetailsState(prevState => ({
          ...prevState,
          isError: true,
          numSatandSundays:'',
          numNationalHolidays: '',
          numLeaveAppliedDays: '',
          numleaveEffectiveDays: '',
          leavePrefixes: [],
          leaveSuffixes: [],
        }));
        handleLogError(error);
        setDetailsState(prevState => ({ ...prevState, isError: true }));
        Toasts.notifyError(error.response.data.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const user = Auth.getUser()
    let { inputLeavetype, numLeaveAppliedDays, inputLeaveStartDate, inputLeaveEndDate, numDays, numSatandSundays, startdaysession, lastdaysession, numNationalHolidays } = detailsstate
    let { inputLeaveOutOfStation, inputLeaveOutOfStationStartDate, inputLeaveOutOfStationEndDate } = detailsstate
    let { inputLeaveComments, addressduringleave, phonenoduringleave } = detailsstate
    let { delegation1username, delegation1natureofwork, Delegation1StartDate, Delegation1EndDate } = detailsstate
    let { delegation2username, delegation2natureofwork, Delegation2StartDate, Delegation2EndDate } = detailsstate
    let { delegation3username, delegation3natureofwork, Delegation3StartDate, Delegation3EndDate } = detailsstate


    

    if (errorsFound()) {
      //scrollToTop();
      Toasts.notifyError("Please fill all the required fields!")
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      //   behavior: "smooth"
      // });
      return
    }

    //leavetype = leavetype.trim()

    let leavePayload = detailsstate.leavePayload

    let fileIds = ''
    if (detailsstate.attachmentid != null) {
      fileIds = detailsstate.attachmentid
    }
    console.log(detailsstate.file)
    console.log(detailsstate.attachmentid == null && detailsstate.file != null)
    if (detailsstate.attachmentid == null && detailsstate.file != null) {

      const user = Auth.getUser()

      const formData = new FormData();
      formData.append('file', detailsstate.file);
      // formData.append('fileName', file.name);
      await iipeerpapi.fileUpload(user, formData)
        .then(response => {
          fileIds = response.data.fileId

        })
        .catch(error => {
          fileIds = ''
          handleLogError(error)
          Toasts.notifyError("Error in uploading attachment!")
          return
        })
        .finally(() => {
        })

    }

    if (detailsstate.file != null && fileIds === '') {
      console.log('her5');
      return
    }
    let officialdutypayload = {};
    let officialdutydata = {}
    officialdutydata.officialdutydata = {}
    if (detailsstate.officialtour === true || detailsstate.inputLeavetype === 'LD') {
      officialdutydata.officialdutydata.isOnOfficialDuty = detailsstate.officialtour
      officialdutypayload.LDStartDate = detailsstate.LDStartDate
      officialdutypayload.ldstartdaysession = detailsstate.ldstartdaysession
      officialdutypayload.LDEndDate = detailsstate.LDEndDate
      officialdutypayload.ldlastdaysession = detailsstate.ldlastdaysession
      officialdutypayload.placestobevisited = detailsstate.placestobevisited
      officialdutypayload.purposeofvisit = detailsstate.purposeofvisit
      officialdutypayload.estimatedbudget = detailsstate.estimatedbudget
      officialdutypayload.budgethead = detailsstate.budgethead
      officialdutypayload.modeofjourney = detailsstate.modeofjourney
      officialdutypayload.classofjourney = detailsstate.classofjourney
    }
    else {
      officialdutydata.isOnOfficialDuty = detailsstate.officialtour
    }
    officialdutydata.officialdutydata.fieldsandvalues = officialdutypayload
    // officialdutydata.officialdutydata = officialdutydata.officialdutydata
    leavePayload.additionalfields = officialdutydata
    leavePayload.attachements_details = fileIds
    leavePayload.leaveid = detailsstate.leaveid
    leavePayload.leavetype = inputLeavetype
    leavePayload.appliedPersonUsername = user.data.preferred_username
    leavePayload.leavestatus = 'PENDING AT DELEGATIONS'
    leavePayload.start_date = inputLeaveStartDate
    leavePayload.end_date = inputLeaveEndDate
    leavePayload.startdaysession = startdaysession
    leavePayload.lastdaysession = lastdaysession
    leavePayload.satandsundays = numSatandSundays
    leavePayload.appliedduration = numLeaveAppliedDays
    leavePayload.holidays = detailsstate.numNationalHolidays
    leavePayload.effectiveleaves = detailsstate.numleaveEffectiveDays
    leavePayload.prefixes = detailsstate.leavePrefixes
    leavePayload.suffixes = detailsstate.leaveSuffixes
    leavePayload.isavailingltc = detailsstate.applyingforltc
    leavePayload.encashedleaves = detailsstate.applyingforltc === true ? detailsstate.encashedleaves : 0
    leavePayload.isoutofstation = inputLeaveOutOfStation
    if (leavePayload.isoutofstation === true) {
      leavePayload.outofstationstartdate = inputLeaveOutOfStationStartDate
      leavePayload.outofstationenddate = inputLeaveOutOfStationEndDate
    }

    leavePayload.comments = inputLeaveComments
    leavePayload.addressduringleave = addressduringleave
    leavePayload.phonenoduringleave = phonenoduringleave
    leavePayload.leaveDelegations = []
    leavePayload.leaveApprovals = []
    if (!(delegation1natureofwork == null || delegation1natureofwork.trim() === '' || delegation1username == null ||
      Delegation1StartDate == null || Delegation1EndDate == null)) {

      let inputLeaveDelegation = {}
      inputLeaveDelegation.username = delegation1username
      inputLeaveDelegation.natureofwork = delegation1natureofwork
      inputLeaveDelegation.startday = Delegation1StartDate
      inputLeaveDelegation.lastday = Delegation1EndDate
      inputLeaveDelegation.status = 'PENDING'
      leavePayload.leaveDelegations.push(inputLeaveDelegation)
    }
    if (!(delegation2natureofwork == null || delegation2natureofwork.trim() === '' || delegation2username == null ||
      Delegation2StartDate == null || Delegation2EndDate == null)) {

      let inputLeaveDelegation = {}
      inputLeaveDelegation.username = delegation2username
      inputLeaveDelegation.natureofwork = delegation2natureofwork
      inputLeaveDelegation.startday = Delegation2StartDate
      inputLeaveDelegation.lastday = Delegation2EndDate
      inputLeaveDelegation.status = 'PENDING'
      leavePayload.leaveDelegations.push(inputLeaveDelegation)
    }
    if (!(delegation3natureofwork == null || delegation3natureofwork.trim() === '' || delegation3username == null ||
      Delegation3StartDate == null || Delegation3EndDate == null)) {

      let inputLeaveDelegation = {}
      inputLeaveDelegation.username = delegation3username
      inputLeaveDelegation.natureofwork = delegation3natureofwork
      inputLeaveDelegation.startday = Delegation3StartDate
      inputLeaveDelegation.lastday = Delegation3EndDate
      inputLeaveDelegation.status = 'PENDING'
      leavePayload.leaveDelegations.push(inputLeaveDelegation)
    }
    let approvals = []
    approvals = Object.keys(userdetails || {}).length === 0 ? [] : userdetails.allApprovalUsers
    let counter = 1;
    leavePayload.leaveApprovals = approvals.length === 0 ? [] : approvals.map(user => {
      return ({
        username: user.username,
        status: '',
        approversequence: counter++
      })
    })
    iipeerpapi.updateLeave(user, leavePayload)

      .then(response => {
        setDetailsState(prevState => ({ ...prevState, isError: false }))

        // window.location.reload();
        Toasts.notifySuccess("Leave Application Updated Successfully!")
        handleGetLeaveBalance()
        Toasts.notifySuccess("Leave Balance Successfully Updated.")
        handleGetLeaves()
        handleEditModalClose();
      })
      .catch(error => {
        handleLogError(error)
        setDetailsState(prevState => ({ ...prevState, isError: true }));
        Toasts.notifyError(error.response.data.message)
      }).finally(() => {
      });
  }

  const RadioExampleRadioGroupLDLastDay = () => {
    const [value, setValue] = useState('' + detailsstate.ldlastdaysession);
    const handleChange = (event, { value }) => {
      setValue(value);
      setDetailsState(prevState => ({
        ...prevState,
        ldlastdaysession: value
      }));

      //calculateNumDays(HolidaysList,detailsstate.inputLeavetype, detailsstate.inputLeaveStartDate, detailsstate.inputLeaveEndDate);
    }

    return (

      <Form.Group style={{ marginRight: '40px', marginTop: '30px', marginBottom: '0px' }}>

        <Form.Field disabled={!detailsstate.officialtour}>
          <Radio
            label='FN'
            name='ldlastdaysession'
            value='FN'
            checked={value === 'FN'}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field disabled={!detailsstate.officialtour}>
          <Radio
            label='AN'
            name='ldlastdaysession '
            value='AN'
            checked={value === 'AN'}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>

    )
  };

  const leaveStartDateHandler = (e, { id, value }) => {

    setDetailsState(prevState => {
      const newState = { ...prevState, [id]: value };
      //calculateNumDays();
      return newState;
    });
  };

  const leaveEndDateHandler = (e, { id, value }) => {


    setDetailsState(prevState => {
      const newState = { ...prevState, [id]: value };
      //calculateNumDays();
      return newState;
    });
  };

  const LDDateHandler = (e, { id, value }) => {
    setDetailsState(prevState => {
      let newState = { ...prevState, [id]: value };
      const ldstart = id === 'LDStartDate' ? value : newState.LDStartDate;
      const ldend = id === 'LDEndDate' ? value : newState.LDEndDate;

      if (ldstart != null && ldend != null) {
        const startMoment = moment(ldstart);
        const endMoment = moment(ldend);
        newState.offcialengdatesnotproper = startMoment.isAfter(endMoment);
      } else {
        newState.offcialengdatesnotproper = false;
      }

      return newState;
    });
  };

  const customStyles = {
    input: {
      width: '220px', // Adjust the width as needed
    },
  };

  const handleInputChange = (e, { id, value }) => {
    setDetailsState(prevState => ({ ...prevState, [id]: value }));
  };

  const handleName = (name) => {
    if (name === "CL") {
      return "Casual Leave";
    }
    else if (name === "HPL") {
      return "HalfPay Leave";
    }
    else if (name === "EL") {
      return "Earned Leave";
    }
    else if (name === "VL") {
      return "Vacation Leave"
    }
    else if (name === "SCL") {
      return "Special Casual Leave";
    }
    else if (name === "RH") {
      return "Restricted Holiday";
    }
    else if (name === "PROL") {
      return "Project Leave";
    }
    else if (name === "COL") {
      return "Commuted Leave";
    }
    else if (name === "SL") {
      return "Station Leave";
    }
    else if (name === "LD") {
      return "Leave On Duty";
    }
    else {
      return "No Leave Found"
    }
  };

  const EncashedleavesField = ({ detailsstate, setDetailsState }) => {
    const handleInputChange = (event) => {
      if (detailsstate.applyingforltc) {
        const input = event.target.value;
        const numericValue = input.replace(/\D/g, '');
        if (numericValue.length <= 2) {
          setDetailsState(prevState => ({
            ...prevState,
            encashedleaves: numericValue == null || isNaN(parseFloat(numericValue)) ? 0 : parseFloat(numericValue),
            //showltcencashErrorMessage: false
          }));
        }
      }
    };

    return (
      <Grid.Row>
        <Grid.Column>
          <Form.Field required={detailsstate.applyingforltc}>
            <Grid>
              <Grid.Column width={8} verticalAlign="middle">
                <Form.Field required={detailsstate.applyingforltc}>
                  <label>Number of leaves to en-cash:</label>
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={8}>
              <Form.Input
                                    placeholder="Enter number of EL leaves"
                                    maxLength={2}
                                    required={detailsstate.applyingforltc}
                                    disabled={!detailsstate.applyingforltc}
                                    value={detailsstate.encashedleaves}
                                    onChange={handleInputChange}
                                    //   onKeyPress={(event) => {
                                    //     if (!/[0-9]/.test(event.key)) {
                                    //       event.preventDefault();
                                    //     }
                                    //   }}
                                    onBlur={(event) => {
                                        if (detailsstate.applyingforltc && (parseInt(event.target.value) >= 10)) {
                                            setDetailsState(prevState => ({
                                                ...prevState,
                                                showltcencashErrorMessage: true
                                            }));
                                        } else {
                                            setDetailsState(prevState => ({
                                                ...prevState,
                                                showltcencashErrorMessage: false
                                            }));
                                        }
                                    }}
                                />
              </Grid.Column>

            </Grid>
            {detailsstate.showltcencashErrorMessage && (
              <Message
                error
                // header="Invalid Entry"
                visible
                list={["Encashed leaves must be less than or equal to 10"]}
                onDismiss={() => setDetailsState(prevState => ({
                  ...prevState,
                  showltcencashErrorMessage: false
                }))}
              />
            )}
          </Form.Field>
        </Grid.Column>
      </Grid.Row>
    );
  };

  const LTC = () => {
    const handleChange = (event, { value }) => {
      setDetailsState(prevState => ({
        ...prevState,
        applyingforltc: value === 'true'
      }));
    };

    return (
      <Grid >
        <Grid.Row columns={2}>
          <Grid.Column width={11}>
            <Form.Field>
              <label>Are you Applying for LTC? <br />
                <span style={{ fontWeight: 'normal' }}>
                  Whether proposes to avail LTC during the ensuing leave. If so, please provide details.
                  (Separate Application for LTC is also required to be filled up).
                </span>
              </label>
            </Form.Field>
          </Grid.Column>

          <Grid.Column width={3} style={{ marginTop: '35px' }}>
            <Form.Group>

              <Form.Field>
                <Radio
                  label='Yes'
                  name='radioGroupltc'
                  value='true'
                  checked={detailsstate.applyingforltc === true}
                  onChange={handleChange}
                />
              </Form.Field>

              <Form.Field>
                <Radio
                  label='No'
                  name='radioGroupltc'
                  value='false'
                  checked={detailsstate.applyingforltc === false}
                  onChange={handleChange}
                /></Form.Field>
            </Form.Group>
          </Grid.Column>
        </Grid.Row>
        <EncashedleavesField
          detailsstate={detailsstate}
          setDetailsState={setDetailsState}
        />

      </Grid>
    );
  };

  const Applyleaveextension = () => {
    const handleChange = (event, { value }) => {
      setDetailsState(prevState => ({
        ...prevState,
        isleaveextension: value === 'true'
      }));
    };

    return (
      <>


        <Form.Field disabled={detailsstate.disableleaveextension}>
          <Radio
            label='Yes'
            name='isleaveextension'
            value='true'
            checked={detailsstate.isleaveextension === true}
            onChange={handleChange}
          />
        </Form.Field>

        <Form.Field disabled={detailsstate.disableleaveextension}>
          <Radio
            label='No'
            name='isleaveextension'
            value='false'
            checked={detailsstate.isleaveextension === false}
            onChange={handleChange}
          /></Form.Field>

      </>



    );
  };

  const handleNewInputChange = (event) => {
    const { id, value } = event.target;
    setDetailsState(prevState => ({ ...prevState, [id]: value }));
  };

  const RadioExampleRadioGroupLastDay = () => {
    const [value, setValue] = useState(detailsstate.lastdaysession);
    const handleChange = (event, { value }) => {
      setValue(value);
      setDetailsState(prevState => ({
        ...prevState,
        lastdaysession: value
      }))
      //calculateNumDays(HolidaysList,detailsstate.inputLeavetype, detailsstate.inputLeaveStartDate, detailsstate.inputLeaveEndDate);
    }

    return (

      <Form.Group widths={2}>

        <Form.Field>
          <Radio
            label='To Fore Noon End'
            name='radioGroup'
            value='FN'
            checked={value === 'FN'}
            onChange={handleChange}

          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='To After Noon End'
            name='radioGroup'
            value='AN'
            checked={value === 'AN'}
            onChange={handleChange}
          // defaultChecked
          />
        </Form.Field>
      </Form.Group>

    )
  };

  // useEffect(() => {
  //   calculateNumDays(
  //     HolidaysList,
  //     detailsstate.inputLeavetype,
  //     detailsstate.inputLeaveStartDate,
  //     detailsstate.inputLeaveEndDate
  //   );
  // }, [detailsstate.inputLeavetype, detailsstate.inputLeaveStartDate, detailsstate.inputLeaveEndDate, detailsstate.startdaysession]);

  const RadioExampleRadioGroupFirstDay = () => {

    const handleChange = (event, { value }) => {
      setDetailsState(prevState => ({
        ...prevState,
        startdaysession: value
      }));
    };

    return (
      <Form.Group widths={2}>
        <Form.Field>
          <Radio
            label='From Fore Noon'
            name='radioGroup1'
            value='FN'
            checked={detailsstate.startdaysession === 'FN'}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='From After Noon'
            name='radioGroup1'
            value='AN'
            checked={detailsstate.startdaysession === 'AN'}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
    );
  };


  const OfficialTour = () => {

    const [value, setValue] = useState('' + detailsstate.officialtour);

    const handleChange = (event, { value }) => {
      setValue(value);
      setDetailsState(prevState => ({
        ...prevState,
        officialtour: value === 'true'
      }));

    };
    useEffect(() => {
      console.log('value updated:', value);
    }, [value]);

    return (
      <Form.Group widths={2} style={{ marginTop: '20px', marginLeft: '40px', marginRight: '20px' }}>
        <Form.Field disabled={detailsstate.disableofficialtour}>
          <Radio
            label='Yes'
            name='radioGroupofficialtour'
            value='true'
            checked={(detailsstate.disableofficialtour === true) ? true : (value === 'true' && detailsstate.officialtour === false ? false : (value === 'true' && detailsstate.officialtour === true) ? true : value === 'true')}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field disabled={detailsstate.disableofficialtour}>
          <Radio
            label='No'
            name='radioGroupofficialtour'
            value='false'
            checked={(detailsstate.disableofficialtour === true) ? false : (value === 'false' && detailsstate.officialtour === true ? true : (value === 'true' && detailsstate.officialtour === false) ? true : value === 'false')}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>

    )
  };

  useEffect(() => {
    console.log('detailsstate updated:', detailsstate);
  }, [detailsstate]);

  const OutofStation = () => {

    const handleChange = (e, { value }) => {
      const newValue = (value === 'true') ? true : false;
      setDetailsState(prevState => ({
        ...prevState,
        inputLeaveOutOfStation: newValue,
        disableoutofstationdates: !newValue,
        inputLeaveOutOfStationStartDate: !newValue ? null : prevState.inputLeaveOutOfStationStartDate,
        inputLeaveOutOfStationEndDate: !newValue ? null : prevState.inputLeaveOutOfStationEndDate,
        inputLeaveOutOfStationStartDateError: !newValue ? false : prevState.inputLeaveOutOfStationStartDateError,
        inputLeaveOutOfStationEndDateError: !newValue ? false : prevState.inputLeaveOutOfStationEndDateError,
        outofstationnotproper: !newValue ? false : prevState.outofstationnotproper
      }));
    };

    return (
      <Form.Group widths={2}>
        <Form.Field>
          <Radio
            label='YES'
            name='radioGroupoutofstation'
            value='true'
            checked={detailsstate.inputLeaveOutOfStation === true}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label='NO'
            name='radioGroupoutofstation'
            value='false'
            checked={detailsstate.inputLeaveOutOfStation === false}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
    );
  };
  // ... (other methods like LTC, OfficialTour, etc. would be converted similarly)

  const errorsForSLFound = () => {
    let errorsFound = false;
    if (
      ((detailsstate.inputLeavetype === 'SL') && (detailsstate.inputLeaveStartDate == null || detailsstate.inputLeaveEndDate == null)) ||
      (detailsstate.inputLeavetype === 'SL' && (detailsstate.inputLeaveOutOfStationStartDate == null || detailsstate.inputLeaveOutOfStationEndDate == null)) ||
      (detailsstate.inputLeaveComments == null || detailsstate.inputLeaveComments.trim() === '') ||
      (detailsstate.addressduringleave == null || detailsstate.addressduringleave.trim() === '') ||
      (detailsstate.inputLeavePhone == null || detailsstate.inputLeavePhone.trim() === '') ||
      ((detailsstate.officialtour === true || detailsstate.inputLeavetype === 'LD') &&
        (detailsstate.LDStartDate == null || detailsstate.LDStartDate === '' ||
          detailsstate.LDEndDate == null || detailsstate.LDEndDate === '' ||
          detailsstate.placestobevisited == null || detailsstate.placestobevisited === '' ||
          detailsstate.purposeofvisit == null || detailsstate.purposeofvisit === '' ||
          detailsstate.estimatedbudget == null || detailsstate.estimatedbudget === '' ||
          detailsstate.budgethead == null || detailsstate.budgethead === '' ||
          detailsstate.modeofjourney == null || detailsstate.modeofjourney === '' ||
          detailsstate.classofjourney == null || detailsstate.classofjourney === ''))
    ) {
      setDetailsState(prevState => ({ ...prevState, fillAllFieldsError: true }));
      errorsFound = true;
      return true;
    } else {
      setDetailsState(prevState => ({ ...prevState, fillAllFieldsError: false }));
    }
    return false;
  };


  const handleFileUpload = async () => {
    const user = Auth.getUser();
    const formData = new FormData();
    formData.append('file', detailsstate.file);
    try {
      const response = await iipeerpapi.fileUpload(user, formData);
      setDetailsState(prevState => ({ ...prevState, fileId: response.fileId }));
      return response.fileId;
    } catch (error) {
      handleLogError(error);
    }
  };

  // ... (other methods would be converted similarly)

  const getUsersList = () => {
    const currentusername = Auth.getUser().data.preferred_username;
    return users
      .filter(user => user.username !== currentusername)
      .map(user => ({
        key: user.username,
        text: user.fullname,
        value: user.username,
      }));
  };

  // Render method becomes return statement of functional component
  const approvals = ((userdetails && userdetails.allApprovalUsers === null) || Object.keys(userdetails || {}).length === 0) ? [] : userdetails.allApprovalUsers;
  const approver1 = approvals.length === 0 ? "" : approvals[0].fullname;
  const approver2 = approvals.length <= 1 ? "" : approvals[1].fullname;
  const approver3 = approvals.length <= 2 ? "" : approvals[2].fullname;

  const options = LeaveBalanceList.map(item => ({
    key: item.uid,
    text: handleName(item.leavetype),
    value: item.leavetype,
  }));
  console.log(LeaveBalanceList);
  options.push({ key: options.length, text: 'Station Leave', value: 'SL' });
  options.push({ key: options.length, text: 'Leave On Duty', value: 'LD' });

  const disabledDates = HolidaysList.map(dateObj => moment(dateObj.date).toDate());
  const disabledotherthanrestricted = restrictedHolidays.map(dateObj => moment(dateObj.date).toDate());
  // For 'SL' leave type - only Saturdays, Sundays, and disabled dates are active.
  const isSLDateActive = (date) => {
    const day = moment(date).day();
    const bro = moment(date).toDate();
    const isDisabled = disabledDates.some(disabledDate => disabledDate.getTime() === bro.getTime());
    return isDisabled || day === 0 || day === 6;
  };

  // For 'RH' leave type - only restricted holidays and weekends are active.
  const isRHDateActive = (date) => {
    const day = moment(date).day();
    const bro = moment(date).toDate();
    const isRestricted = disabledotherthanrestricted.some(disabledDate => {
      const disabledDay = moment(disabledDate).day();
      return disabledDate.getTime() === bro.getTime() && disabledDay !== 0 && disabledDay !== 6;
    });
    return isRestricted;
  };

  // For other leave types - only weekdays that are not disabled dates are active.
  const isWeekdayActive = (date) => {
    const day = moment(date).day();
    const bro = moment(date).toDate();
    const isDisabled = disabledDates.some(disabledDate => disabledDate.getTime() === bro.getTime());
    return !isDisabled && day > 0 && day < 6;
  };


  const usersList = getUsersList();

  return (
    <>
      {/* {detailsstate.fillAllFieldsError && (
  Toasts.notifyError("Please fill all the required fields!")
)} */}
      {detailsstate.fillAllFieldsError && (
        <Message
          onDismiss={() => setDetailsState(prevState => ({ ...prevState, fillAllFieldsError: false }))}
          error
          size='large'
          visible
          list={["Please fill all the required fields!"]}
        />
      )}
      <div>
        {<Form >
          <Grid stackable textAlign='center'>
            <Grid.Row columns={3} centered >
              <Grid.Column textAlign="center">
                <Form.Group widths='equal'>

                  <Form.Select
                    closeOnEscape
                    label={"Leave Type"}
                    clearable
                    placeholder='Select '
                    required={true}
                    id='inputLeavetype'
                    options={options}
                    value={detailsstate.inputLeavetype}
                    onChange={leaveTypeHandler}

                  />

                </Form.Group>
                {detailsstate.inputLeaveTypeError && <Message
                  onDismiss={() => setDetailsState({ inputLeaveTypeError: false })}
                  error
                  visible
                  // header="Error!"
                  list={[
                    "Select Leave Type"
                  ]}
                />}
                {/* </Form.Field> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Form.Group inline style={{ justifyContent: 'center', alignItems: 'center', margin: '0' }}>
                <Form.Field required>
                  <label  >Are you applying for Leave Extension?</label>
                </Form.Field>
                <Applyleaveextension />
              </Form.Group>
            </Grid.Row>


            <Grid.Row>
              <Grid.Column>
                <Segment >
                  <Form.Group inline style={{ justifyContent: 'center', alignItems: 'center', margin: '0' }}>

                    <Form.Field required>
                      <label  >Are you going for an Official Tour?</label>
                    </Form.Field>
                    <OfficialTour></OfficialTour>
                  </Form.Group>
                  <br /><br />
                  <Grid columns={2} stackable divided >
                    <Grid.Row >
                      {/* <Grid.Column >

                          <Form.Group widths='equal'>
                            <Form.Field required>
                              <label>Are you going for an Official Tour?</label>
                            </Form.Field>

                          </Form.Group>
                          <OfficialTour></OfficialTour>

                        </Grid.Column> */}
                      <Grid.Column>
                        <Form.Group >

                          <Form.Field required inline >
                            <label required>Start date of Official Engagement</label>
                            <SemanticDatepicker
                              disabled={!detailsstate.officialtour}
                              //label="Start date of Official Engagement"
                              id="LDStartDate"
                              format="DD-MMMM-YYYY"
                              onChange={LDDateHandler}

                              value={detailsstate.LDStartDate}
                              //   onChange={onChange}
                              required

                            />
                          </Form.Field>
                          <RadioExampleRadioGroupLDFirstDay />
                        </Form.Group>
                      </Grid.Column>


                      <Grid.Column>
                        <Form.Group >

                          <Form.Field required inline>
                            <label required>Last date of Official Engagement</label>
                            <SemanticDatepicker
                              disabled={!detailsstate.officialtour}
                              //label="Last date of Official Engagement"
                              id="LDEndDate"
                              format="DD-MMMM-YYYY"
                              onChange={LDDateHandler}
                              value={detailsstate.LDEndDate}
                              required
                            // onChange={onChange}
                            />
                          </Form.Field>
                          <RadioExampleRadioGroupLDLastDay />
                        </Form.Group>

                      </Grid.Column>

                    </Grid.Row>
                    {(detailsstate.offcialengdatesnotproper) && <Grid.Row>
                      <Grid.Column width={16}>
                        {(detailsstate.offcialengdatesnotproper) && <Message
                          onDismiss={() => setDetailsState(prevState => ({ ...prevState, offcialengdatesnotproper: false }))}
                          error visible list={['Select Dates properly']} />}
                      </Grid.Column>
                    </Grid.Row>}
                    <Grid.Row >

                      <Grid.Column>
                        <Form.Group inline widths={2}>

                          <Form.Field required inline >
                            <label>Places to be visited</label>

                          </Form.Field>
                          <Form.Field>
                            <TextArea rows={2} id='placestobevisited' placeholder='Places'
                              onBlur={handleNewInputChange}
                              value={detailsstate.placestobevisited}
                              disabled={!detailsstate.officialtour}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>



                      <Grid.Column>
                        <Form.Group inline widths={2}>

                          <Form.Field required inline >
                            <label>Purpose of visit<br />(Attach support document)</label>

                          </Form.Field>
                          <Form.Field>
                            <TextArea rows={2} id='purposeofvisit' placeholder='Purpose' onBlur={handleNewInputChange}
                              disabled={!detailsstate.officialtour}
                              value={detailsstate.purposeofvisit}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>


                    </Grid.Row>
                    <Grid.Row >

                      <Grid.Column>
                        <Form.Group inline widths={2}>

                          <Form.Field required inline >
                            <label>Estimated Budget</label>

                          </Form.Field>
                          <Form.Field>
                            <TextArea rows={2} id='estimatedbudget' placeholder='Estimated Budget' onBlur={handleNewInputChange}
                              disabled={!detailsstate.officialtour}
                              value={detailsstate.estimatedbudget}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>



                      <Grid.Column>
                        <Form.Group inline widths={2}>

                          <Form.Field required inline >
                            <label>Budget Head from which<br />expenditure is to be met</label>

                          </Form.Field>
                          <Form.Field>
                            <TextArea rows={2} id='budgethead' placeholder='Budget Head' onBlur={handleNewInputChange}
                              disabled={!detailsstate.officialtour}
                              value={detailsstate.budgethead}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>


                    </Grid.Row>

                    <Grid.Row >

                      <Grid.Column>
                        <Form.Group inline widths={2}>

                          <Form.Field required inline >
                            <label>Mode of Journey</label>

                          </Form.Field>
                          <Form.Field>
                            <TextArea rows={2} id='modeofjourney' placeholder='Mode of Journey' onBlur={handleNewInputChange}
                              disabled={!detailsstate.officialtour}
                              value={detailsstate.modeofjourney}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>



                      <Grid.Column>
                        <Form.Group inline widths={2}>

                          <Form.Field required inline >
                            <label>Class of Journey</label>

                          </Form.Field>
                          <Form.Field>
                            <TextArea rows={2} id='classofjourney' placeholder='Class of Journey' onBlur={handleNewInputChange}
                              disabled={!detailsstate.officialtour}
                              value={detailsstate.classofjourney}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Grid.Column>


                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            {(!(detailsstate.inputLeavetype === 'LD')) && <Grid.Row>
              <Grid.Column>
                <Segment >
                  <Grid columns={2} stackable textAlign='center'>
                    <Divider vertical> Duration  </Divider>

                    <Grid.Row verticalAlign='top' >
                      <Grid.Column>
                        <SemanticDatepicker
                          error={error}
                          label="Start date"
                          id="inputLeaveStartDate"
                          format="DD-MMMM-YYYY"
                          onChange={leaveStartDateHandler}
                          filterDate={inputLeavetype === 'SL' ? isSLDateActive : ((inputLeavetype === 'RH') ?
                            isRHDateActive : (isleaveextension === false ? isWeekdayActive : () => true))}
                          value={inputLeaveStartDate}
                          //   onChange={onChange}
                          required

                        />
                        <RadioExampleRadioGroupFirstDay />


                        {detailsstate.inputLeaveStartDateError && <Message
                          onDismiss={() => {
                            setDetailsState({
                              inputLeaveStartDateError: false
                            });
                          }}

                          error
                          visible
                          // header="Error!"
                          list={[
                            "Select Start Date"
                          ]}
                        />}

                      </Grid.Column>


                      <Grid.Column>
                        <SemanticDatepicker
                          error={error}
                          label="End date"
                          id="inputLeaveEndDate"
                          format="DD-MMMM-YYYY"
                          onChange={leaveEndDateHandler}
                          filterDate={inputLeavetype === 'SL' ? isSLDateActive : ((inputLeavetype === 'RH') ?
                            isRHDateActive : (isleaveextension === false ? isWeekdayActive : () => true))}
                          value={inputLeaveEndDate}
                          required
                        // onChange={onChange}
                        />
                        <RadioExampleRadioGroupLastDay></RadioExampleRadioGroupLastDay>

                        {detailsstate.inputLeaveEndDateError && <Message
                          onDismiss={() => setDetailsState(prevState => ({ ...prevState, inputLeaveEndDateError: false }))}
                          error
                          visible
                          // header="Error!"
                          list={[
                            "Select End Date"
                          ]}
                        />}

                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>}
            {(!(detailsstate.inputLeavetype === 'LD')) && <Grid.Row>
              {!(detailsstate.error) && detailsstate.error2 && <Message
                onDismiss={() => setDetailsState(prevState => ({ ...prevState, error2: false }))}
                error visible list={['Select the start and end date properly']} />}
              {detailsstate.error && detailsstate.inputLeavetype === 'CL' && (
                <Message error
                  onDismiss={() => setDetailsState(prevState => ({ ...prevState, error: false }))}
                  visible list={['CL cannot be more than 5 days']} />
              )
              }
              {detailsstate.error3 && (
                <Message error
                  onDismiss={() => setDetailsState(prevState => ({ ...prevState, error3: false }))}
                  visible list={['Select the session properly']} />
              )
              }
              {/* {!(detailsstate.inputLeavetype == 'CL') && <Message info>
                  <Icon name='info' />
                  Only Casual Leave have half day leaves
                </Message>} */}
            </Grid.Row>}
            {(!isstationLeave && !(detailsstate.inputLeavetype === 'LD')) && <Grid.Row>
              <Grid.Column>
                <Segment >
                  <Grid columns={6} stackable divided>
                    <Grid.Row>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>Prefixes</label>
                            {detailsstate.leavePrefixes && detailsstate.leavePrefixes.length > 0 ? (
                              detailsstate.leavePrefixes.map(t => (
                                <label key={t}>{t}</label>
                              ))
                            ) : (
                              <label>NA</label>
                            )}
                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>Suffixes</label>

                            {detailsstate.leaveSuffixes && detailsstate.leaveSuffixes.length > 0 ? (
                              detailsstate.leaveSuffixes.map(t => (
                                <label key={t}>{t}</label>
                              ))
                            ) : (
                              <label>NA</label>
                            )}
                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>Saturdays/Sundays </label>
                            {!detailsstate.error && !detailsstate.error2 && !detailsstate.error3 && <label >{detailsstate.numSatandSundays }</label>}
                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>National Holidays</label>
                            {!detailsstate.error && !detailsstate.error2 && !detailsstate.error3 && <label>{detailsstate.numNationalHolidays}</label>}
                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>Working days </label>
                            {!detailsstate.error && !detailsstate.error2 && !detailsstate.error3 && <label>{detailsstate.numLeaveAppliedDays}</label>}
                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <label>Applied Leaves </label>
                            {!detailsstate.error && !detailsstate.error2 && !detailsstate.error3 && <label>{detailsstate.numleaveEffectiveDays}</label>}
                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>

                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>}
            <Grid.Row>
              <Grid.Column>
                <Segment >
                  <Grid columns={isstationLeave === true ? 2 : 3} stackable divided >
                    <Grid.Row >
                      {!isstationLeave && <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field required>
                            <label>Out of Station</label>
                          </Form.Field>

                        </Form.Group>
                        <OutofStation />


                      </Grid.Column>}
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field required={!detailsstate.disableoutofstationdates}>
                            <label required>Out of Station Start time</label>
                            <DatePicker
                              customInput={<input style={customStyles.input} />}
                              id="inputLeaveOutOfStationStartDate"
                              selected={(detailsstate.inputLeaveOutOfStationStartDate == null) ? null : detailsstate.inputLeaveOutOfStationStartDate}
                              onChange={(date) => {
                                //  if(date!=null){
                                //  date = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSX");
                                //  }
                                setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationStartDate: date }))
                                if (detailsstate.inputLeaveOutOfStation != null && detailsstate.inputLeaveOutOfStation === true && date != null && detailsstate.inputLeaveOutOfStationEndDate != null) {

                                  const startMoment = moment(date);
                                  const endMoment = moment(detailsstate.inputLeaveOutOfStationEndDate);
                                  if (startMoment.isAfter(endMoment)) {
                                    setDetailsState(prevState => ({ ...prevState, outofstationnotproper: true }))
                                  }
                                  else {
                                    setDetailsState(prevState => ({ ...prevState, outofstationnotproper: false }))
                                  }
                                }
                                else {
                                  setDetailsState(prevState => ({ ...prevState, outofstationnotproper: false }))
                                }

                                if ((detailsstate.disableoutofstationdates === false) & (date == null || date === '')) {
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationStartDateError: true }))
                                }
                                else if (date != null) {
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationStartDate: date }))
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationEndDateError: false }))
                                }

                              }}
                              showTimeSelect
                              // required={!detailsstate.disableoutofstationdates}
                              disabled={detailsstate.disableoutofstationdates}
                              dateFormat="d-MMMM-yyyy h:mm aa"
                            // timeFormat="h:mm aa"
                            />


                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field required={!detailsstate.disableoutofstationdates} >
                            <label required>Out of Station End time</label>
                            <DatePicker
                              customInput={<input style={customStyles.input} />}
                              id="inputLeaveOutOfStationEndDate"
                              selected={(detailsstate.inputLeaveOutOfStationEndDate == null) ? null : detailsstate.inputLeaveOutOfStationEndDate}
                              onChange={(date) => {
                                setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationEndDate: date }));
                                if (detailsstate.inputLeaveOutOfStation != null && detailsstate.inputLeaveOutOfStation === true &&
                                  detailsstate.inputLeaveOutOfStationStartDate != null && date != null) {
                                  const startMoment = moment(detailsstate.inputLeaveOutOfStationStartDate);
                                  const endMoment = moment(date);
                                  if (startMoment.isAfter(endMoment)) {
                                    setDetailsState(prevState => ({ ...prevState, outofstationnotproper: true }));
                                  }
                                  else {
                                    setDetailsState(prevState => ({ ...prevState, outofstationnotproper: false }));
                                  }
                                }
                                else {
                                  setDetailsState(prevState => ({ ...prevState, outofstationnotproper: false }));
                                }
                                if ((detailsstate.disableoutofstationdates === false) & (date == null || date === '')) {
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationEndDateError: true }));
                                }
                                else if (date != null) {
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveOutOfStationEndDate: date, inputLeaveOutOfStationEndDateError: false }));
                                }
                              }}
                              showTimeSelect
                              // required={!detailsstate.disableoutofstationdates}
                              disabled={detailsstate.disableoutofstationdates}
                              dateFormat="d-MMMM-yyyy h:mm aa"
                            // timeFormat="h:mm aa"
                            />

                          </Form.Field>

                        </Form.Group>

                      </Grid.Column>

                    </Grid.Row>

                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>
            {(detailsstate.outofstationnotproper) && <Grid.Row>
              {(detailsstate.outofstationnotproper) && <Message
                onDismiss={() => setDetailsState(prevState => ({ ...prevState, outofstationnotproper: false }))}
                error visible list={['Select out of station datetimes properly']} />}
            </Grid.Row>}
            <Grid.Row>
              <Grid.Column>
                <Segment >
                  <Grid columns={2} stackable divided>
                    <Grid.Row>
                      <Grid.Column >

                        <Form.Group widths='equal'>
                          <Form.Field>
                            <Form.TextArea rows={8} label='Reason for leave or any comments'
                              value={detailsstate.inputLeaveComments}
                              id='inputLeaveComments'
                              placeholder='Reason for leave' required
                              onChange={(event) => {
                                setDetailsState(prevState => ({ ...prevState, inputLeaveComments: event.target.value }));
                              }}
                              onBlur={(event) => {
                                if (event.target.value == null || event.target.value.trim() === '') {
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveCommentsError: true }));
                                }
                                else {
                                  setDetailsState(prevState => ({ ...prevState, inputLeaveComments: event.target.value.trim(), inputLeaveCommentsError: false }));
                                }
                              }
                              }

                            />



                            {detailsstate.inputLeaveCommentsError && <Message
                              onDismiss={() => setDetailsState(prevState => ({ ...prevState, inputLeaveCommentsError: false }))}
                              error
                              visible
                              // header="Error!"
                              list={[
                                "Please mention reason of leave."
                              ]}
                            />}
                          </Form.Field>

                        </Form.Group>
                        <Segment>
                          <Form.Group widths='equal' required={detailsstate.inputLeavetype === 'COL' ? true : false}>
                            <Form.Field required={detailsstate.inputLeavetype === 'COL' ? true : false}>
                              <label >Attachments</label >
                            </Form.Field>
                          </Form.Group >
                          <Form.Group widths='equal' required={detailsstate.inputLeavetype === 'COL' ? true : false}>
                            <Form.Field required={detailsstate.inputLeavetype === 'COL' ? true : false}>


                              {detailsstate.attachmentid != null && detailsstate.attachmentid !== ''
                                ? (
                                  <>
                                    {FileAttachmentUtils.renderAttachmentButton(detailsstate.attachmentid, user)}
                                    <Button
                                      size='small'
                                      content='Remove'
                                      icon='trash'
                                      onClick={() => setDetailsState(prevState => ({ ...prevState, attachmentid: null }))}
                                    />


                                  </>
                                )
                                : renderFileUpload()
                              }


                            </Form.Field>
                            {/* <Form.Field >
                            <Button
                              content="Upload file"
                              // labelPosition="left"
                              onClick={(e)=>{e.preventDefault();
                                handleFileUpload}}
                            />
                          </Form.Field> */}
                          </Form.Group>
                          <Form.Group >
                            <Form.Field>
                              {detailsstate.fileName != null && detailsstate.fileName !== '' && <label>Chosen FileName: {detailsstate.fileName}</label>}
                            </Form.Field>
                            {/* <Form.Field>
                            {detailsstate.fileName!=null && detailsstate.fileName!=='' && <label>Uploaded succsflajsdlfaksjd;flak sjdf;lasjkdfl sdkfj</label> }
                            </Form.Field> */}
                          </Form.Group>
                        </Segment>

                      </Grid.Column>

                      <Grid.Column >
                        <Grid.Row>
                          <Form.Group widths='equal'>
                            <Form.Field required>
                              <Form.TextArea rows={2} id='addressduringleave'
                                label='Address during leave'
                                placeholder='Address'
                                required
                                value={detailsstate.addressduringleave}
                                onChange={(event) => {
                                  setDetailsState(prevState => ({ ...prevState, addressduringleave: event.target.value }));
                                }}
                                onBlur={(event) => {
                                  if (event.target.value == null || event.target.value.trim() === '') {
                                    setDetailsState(prevState => ({ ...prevState, inputLeaveAddressError: true }));
                                  }
                                  else {
                                    setDetailsState(prevState => ({ ...prevState, addressduringleave: event.target.value.trim(), inputLeaveAddressError: false }));
                                  }
                                }}
                              />
                            </Form.Field>
                          </Form.Group>
                          {detailsstate.inputLeaveAddressError && <Message
                            onDismiss={() => setDetailsState(prevState => ({ ...prevState, inputLeaveAddressError: false }))}
                            error
                            visible
                            list={[
                              "Please mention Address"
                            ]}
                          />}
                        </Grid.Row>

                        <Grid.Row>
                          <Form.Group widths='equal'>
                            <Form.Field >
                              <Form.Input
                                label="Phone Number"
                                placeholder="Enter phone number"
                                maxLength={10}
                                required={true}
                                id='inputLeavePhone'
                                value={detailsstate.phonenoduringleave}
                                onChange={(event) => {
                                  setDetailsState(prevState => ({ ...prevState, phonenoduringleave: event.target.value }));
                                }}
                                onBlur={(event) => {
                                  if (event.target.value == null || event.target.value === '') {
                                    setDetailsState(prevState => ({ ...prevState, inputLeavePhoneError: true, inputLeavePhoneLengthError: false }));
                                  }
                                  else if (event.target.value != null && event.target.value.length !== 10) {
                                    setDetailsState(prevState => ({ ...prevState, inputLeavePhoneLengthError: true, inputLeavePhoneError: false }));
                                  }
                                  else {
                                    setDetailsState(prevState => ({ ...prevState, inputLeavePhoneLengthError: false, inputLeavePhoneError: false, phonenoduringleave: event.target.value }));
                                  }
                                }}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Field>
                          </Form.Group>
                          {detailsstate.inputLeavePhoneLengthError && <Message
                            error
                            visible
                            onDismiss={() => setDetailsState(prevState => ({ ...prevState, inputLeavePhoneLengthError: false }))}
                            list={[
                              "Enter Proper Phone No."
                            ]}
                          />}
                          {detailsstate.inputLeavePhoneError && <Message
                            error
                            visible
                            onDismiss={() => setDetailsState(prevState => ({ ...prevState, inputLeavePhoneError: false }))}
                            list={[
                              "Please Enter Phone No."
                            ]}
                          />}
                        </Grid.Row>


                        <LTC></LTC>
                      </Grid.Column>

                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid.Row>


            {/* <Grid.Row id='delegations'>
              <Grid.Column stretched>
                <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='tag' />
                    Delegations
                  </Header>
                </Divider>
                <Form.Group widths='equal'>
                  <Form.Field
         
                  >
                    <label
    
                    > Delegation-1 </label>
                    <Input
                      fluid placeholder='Nature of Work'
                      //value={detailsstate.delegation1natureofwork} 
                      id='delegation1natureofwork' onChange={handleInputChange} />

                    <Dropdown
                      closeOnEscape
                      id='delegation1username'
                      onChange={handleInputChange}
                      clearable
                      search
                      placeholder='Person Name  '
                      fluid
                      selection
                  
                      options={usersList}
                    />
                    <SemanticDatepicker
                      error={error}
                      label="Start date"
                      id="Delegation1StartDate"
                      format="DD-MMMM-YYYY"
               
                      onChange={(e, data) => { setDetailsState(prevState => ({ ...prevState, [data.id]: data.value })) }}
                    />

                    <SemanticDatepicker
                      error={error}
                      label="End date"
                      id="Delegation1EndDate"
                      format="DD-MMMM-YYYY"
               
                      onChange={(e, data) => { setDetailsState(prevState => ({ ...prevState, [data.id]: data.value })) }}
                    />
               
                  </Form.Field>
                  <Form.Field>
                    <label>Delegation-2</label>
                    <Input fluid placeholder='Nature of Work' value={detailsstate.delegation2natureofwork} id='delegation2natureofwork' onChange={handleInputChange} />
                    <Dropdown
                      closeOnEscape
                      id='delegation2username'
                      onChange={handleInputChange}
                      clearable
                      placeholder='Person Name  '
                      search
                      fluid
                      selection
                      options={usersList}
                    />
                    <SemanticDatepicker
                      error={error}
                      label="Start date"
                      id="Delegation2StartDate"
                      format="DD-MMMM-YYYY"
                      onChange={(e, data) => { setDetailsState(prevState => ({ ...prevState, [data.id]: data.value })) }}
                    />

                    <SemanticDatepicker
                      error={error}
                      label="End date"
                      id="Delegation2EndDate"
                      format="DD-MMMM-YYYY"
                      onChange={(e, data) => { setDetailsState(prevState => ({ ...prevState, [data.id]: data.value })) }}
                    />

                  </Form.Field>
                  <Form.Field>
                    <label>Delegation-3</label>
                    <Input fluid placeholder='Nature of Work' value={detailsstate.delegation3natureofwork} id='delegation3natureofwork' onChange={handleInputChange} />
                    <Dropdown
                      closeOnEscape
                      id='delegation3username'
                      onChange={handleInputChange}
                      clearable
                      search
                      placeholder='Person Name '
                      fluid
                      selection
                      options={usersList}
                    />
                    <SemanticDatepicker
                      error={error}
                      label="Start date"
                      id="Delegation3StartDate"
                      format="DD-MMMM-YYYY"
                      onChange={(e, data) => { setDetailsState(prevState => ({ ...prevState, [data.id]: data.value })) }}
                    />

                    <SemanticDatepicker
                      error={error}
                      label="End date"
                      id="Delegation3EndDate"
                      format="DD-MMMM-YYYY"
                      onChange={(e, data) => { setDetailsState(prevState => ({ ...prevState, [data.id]: data.value })) }}
                    />
               
                  </Form.Field>
                </Form.Group>
                {detailsstate.inputLeaveDelegation1Error && <Message
                  onDismiss={() => setDetailsState(prevState => ({ ...prevState, inputLeaveDelegation1Error: false }))}
                  error
                  visible
              
                  list={[
                    "At least one delegation details are required"
                  ]}
                />}
              </Grid.Column>
            </Grid.Row> */}
            <Grid.Row>
              <Grid.Column stretched>
                <Divider horizontal>
                  <Header as='h4'>
                    <Icon name='tag' />
                    Approvals
                  </Header>
                </Divider>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>1st Approver</label>
                    <Segment basic size='small'>{approver1}</Segment>

                  </Form.Field>
                  <Form.Field>
                    <label>2nd Approver</label>
                    <Segment basic size='small'>{approver2}</Segment>

                  </Form.Field>
                  <Form.Field>
                    <label>3rd Approver</label>
                    <Segment basic size='small'>{approver3}</Segment>

                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>

          </Grid>
          {false && <Message negative>Errors</Message>}
          <Button type="submit" color='violet' onClick={(detailsstate.isstationLeave === true) || (detailsstate.inputLeavetype === 'LD') ? handleSLSubmit : handleSubmit} fluid size='large' >Submit Leave Form</Button>
        </Form>}
        <br></br>
        <br></br>
      </div>
    </>
  );
};

export default LeaveEdit;