import React, { Component, useContext } from 'react';

const AuthContext = React.createContext();

class AuthProvider extends Component {
  state = {
    user: null,
  };

  componentDidMount() {
    const user = getCookie('user');
    if (user) {
      this.setState({ user: JSON.parse(user) });
    }
  }

  getUser = () => {
    const user = getCookie('user');
    return user ? JSON.parse(user) : null;
  };

  userIsAuthenticated = () => {
    let user = getCookie('user');
    if (!user) {
      return false;
    }
    user = JSON.parse(user);

    // if user has token expired, logout user
    if (Date.now() > user.data.exp * 1000) {
      this.userLogout();
      return false;
    }
    return true;
  };

  userLogin = (user) => {
    setCookie('user', JSON.stringify(user), 7); // setting the cookie for 7 days
    this.setState({ user: user });
  };

  userLogout = () => {
    
    eraseCookie('user');
    this.setState({ user: null });
  };

  render() {
    const { children } = this.props;
    const { user } = this.state;
    const { getUser, userIsAuthenticated, userLogin, userLogout } = this;

    return (
      <AuthContext.Provider value={{ user, getUser, userIsAuthenticated, userLogin, userLogout }}>
        {children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;

export function useAuth() {
  return useContext(AuthContext);
}

function setCookie(name, value, days) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const expires = "expires=" + date.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  document.cookie = name + '=; Max-Age=0; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
}


export { AuthProvider };
