import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React from 'react';
import { useParams } from 'react-router-dom';
import LeaveDetailsModal from './LeaveDetailsModal';
// Other imports...

const LeaveModalPage = () => {
    const { leaveId } = useParams();
  
    const handleLeaveid = () => {
      // Implement this function if you need it
    };
  
    return (
      <LeaveDetailsModal
        leaveId={leaveId}
        onClose={() => window.close()}
        fromleaveviewmodal={false}
      />
    );
  };

  export default LeaveModalPage;
