
import React, { Component, } from 'react'
import { Grid, Form, Button, Table, Header, Divider, Radio, Pagination, Input, Message, TextArea, Container } from 'semantic-ui-react'
import { Dropdown, Segment, Modal, Icon } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Slide } from "react-toastify";
import Toasts from '../misc/Toasts';
import datetimeutils from "../utils/DateTimeUtils";
import { useState,useContext } from "react";


const DeleteJoiningFormConfirmationModal = ({currentLeaveJoiningId,handleshowDeleteLeaveJoiningConfirmModal,handleClose,handleGetLeaves}) => {
    console.log("currentLeaveJoiningId"+currentLeaveJoiningId)
    const [deleteleavejoiningmodal, setDeleteleavejoiningmodal] = useState(true);
    const authContext = useContext(AuthContext);
    const user = authContext.getUser();

    const handleJoiningFormDelete = (currentLeaveJoiningId) => {
        
        iipeerpapi.deletejoiningform(user, currentLeaveJoiningId)
          .then(response => {
            handleGetLeaves();
            handleshowDeleteLeaveJoiningConfirmModal(null);
            handleClose();
            Toasts.notifySuccess("Joining form Deleted Successfully!")
          })
          .catch(error => {
            handleLogError(error);
            Toasts.notifyError("Something went wrong. Please try again !")
          });
      }

    return (
      <Modal
        onClose={() => { setDeleteleavejoiningmodal( false) }}
        open={deleteleavejoiningmodal}
        size='small'
      >
        <Modal.Header>Delete Confirmation</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete leave joining form?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive onClick={() => handleJoiningFormDelete(currentLeaveJoiningId)}>
            Yes
          </Button>
          <Button negative onClick={() => {setDeleteleavejoiningmodal( false);handleshowDeleteLeaveJoiningConfirmModal(null)}}>
            No
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

export default DeleteJoiningFormConfirmationModal;