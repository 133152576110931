import React, { useContext, useState } from 'react';

import AuthContext from '../context/AuthContext'

import LeaveDetailsModal from './LeaveDetailsModal';

const LeaveUtils = ({ leaveId ,handleLeaveid,fromleaveviewmodal}) => {
  
  //const { leaveId } = useParams();


  const [showLeaveDetails, setShowLeaveDetails] = useState(leaveId !== null);
  const [currentLeaveId, setLeaveId] = useState(leaveId);
  
  const handleCloseLeaveViewModal = () => {
    setLeaveId(null);
    setShowLeaveDetails(false);
    handleLeaveid(null);
  };

  return (
    <>
      {showLeaveDetails && (
        <LeaveDetailsModal
          leaveId={currentLeaveId}
          onClose={handleCloseLeaveViewModal}
          fromleaveviewmodal={fromleaveviewmodal}
        />
      )}
    </>
  )
}

export default LeaveUtils;