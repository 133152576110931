import React, { useContext, useEffect, useState } from 'react';
import { Grid, Form, Button, Table, Header, Divider, Radio, Pagination, Container, Message, MessageHeader } from 'semantic-ui-react'
import { Dropdown, Segment, Modal, Icon } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import datetimeutils from "../utils/DateTimeUtils";
import FileAttachmentUtils from "../utils/FileAttachmentUtils";
import LMSUtils from "./LMSUtils";
import LeaveEditHistoryTable from './LeaveEditHistoryTable';
import { useNavigate } from 'react-router-dom';
import CommentsPage from '../misc/CommentsPage';
import { LeaveOwnerOperations } from './LeaveOwnerOperations';


const LeaveDetailsModalToOperate = ({ leaveId, onClose, delegationid, isdelegation, fromleaveviewmodal, leaveowner,handleGetLeaves,handleGetLeaveBalance }) => {
  const [open, setOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [officialDutyData, setOfficialDutyData] = useState([]);
  const authContext = useContext(AuthContext);
  const user = authContext.getUser();
  const [selectedLeaveId, setSelectedLeaveId] = useState(null);
  const [modalchatOpen, setChatModalOpen] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleGetLeaveDetailsForLeaveID = async (leaveId) => {

    try {
      iipeerpapi.getleaveDetails(user, leaveId)
        .then(response => {
          (response.data.additionalfields === null || response.data.additionalfields.officialdutydata === null) ? setOfficialDutyData([]) :
            setOfficialDutyData(response.data.additionalfields.officialdutydata.fieldsandvalues);
          setSelectedLeave(response.data);
          setOpen(true);
        })
        .catch(error => {
          handleLogError(error);
        })
    }
    catch (error) {

    }
  };

  const handleGetLeaveDetailsForDelegationID = async (delegationid) => {
    try {
      iipeerpapi.getleaveDetails(user, leaveId)
        .then(response => {
          setSelectedLeave(response.data);
          (response.data.additionalfields === null || response.data.additionalfields.officialdutydata === null) ? setOfficialDutyData([]) :
            setOfficialDutyData(response.data.additionalfields.officialdutydata);
          setOpen(true);
        })
        .catch(error => {
          handleLogError(error);
        })
    }
    catch (error) {

    }
  };





  useEffect(() => {
    if (delegationid != null || delegationid !== undefined) {
      handleGetLeaveDetailsForDelegationID(delegationid)
    } else {
      console.log("helloss");
      handleGetLeaveDetailsForLeaveID(leaveId)
    }
  }, []);

  const handleViewInNewTab = (leaveId) => {
    window.open(`/estb/viewleave/${leaveId}`, '_blank');
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleChatOpenModal = (leaveId) => {
    console.log(leaveId)
    setSelectedLeaveId(leaveId);
    setChatModalOpen(true);
  }

  const handleChatCloseModal = () => {
    setChatModalOpen(false);
  };

  

  const handleCancelModal = (value) => {
    setCancelModalOpen(value);
  };

  const handleDeleteModal = (value) => {
    setDeleteModalOpen(value);
  };

  

  return (
    <Modal
      closeIcon
      open={open}
      onClose={handleClose}
      size='fullscreen'
    >
      <Header textAlign='center' as='h2' color='blue'>Leave Details</Header>
      <Modal.Content image scrolling >


        <Modal.Description>
          <Modal.Content>
            <Container>

              {(selectedLeave.isdeleted === true) ? <Message negative><MessageHeader>Leave has been deleted with below reason</MessageHeader>
                <p>{selectedLeave.deletereason}</p>
              </Message> : ''}

              {(fromleaveviewmodal === false) && (
                <>
                  <br />
                  <LeaveEditHistoryTable
                    showApprovals={true}
                    leaveid={selectedLeave?.leaveid}
                    fromleaveviewmodal={true}
                  />
                  <br />
                </>
              )}
              <Segment.Group horizontal>
                <Segment><b>Applied Person Name: </b></Segment>
                <Segment>
                  {
                    (selectedLeave.userDetailsDTO)?.fullname || ''
                  }
                </Segment>
              </Segment.Group>
              <Segment.Group horizontal>
                <Segment ><b>Leave Type: </b></Segment>
                <Segment>
                  {LMSUtils.handleLeaveName(selectedLeave.leavetype)} ({selectedLeave.leavetype})
                </Segment>
              </Segment.Group>
              <Segment.Group horizontal>
                <Segment><b>Leave Status:</b></Segment>
                <Segment>
                  {
                    selectedLeave.leave_status
                  }
                </Segment>
              </Segment.Group>

              <Segment.Group horizontal>
                <Segment><b>Start Date: </b></Segment>
                <Segment>{datetimeutils.formatDateToDate(selectedLeave.start_date)}  {(selectedLeave.startdaysession)}</Segment>
                <Segment><b>End Date: </b></Segment>
                <Segment>{datetimeutils.formatDateToDate(selectedLeave.end_date)}  {(selectedLeave.lastdaysession)}</Segment>
              </Segment.Group>
              {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Grid columns='equal'>
                <Grid.Row>
                  <Grid.Column>
                    <Table compact striped celled selectable>
                      <Table.Header>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell >Prefix</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          (selectedLeave !== null && selectedLeave.prefixes !== undefined && selectedLeave.prefixes !== null && selectedLeave.prefixes.length !== 0) ? (selectedLeave.prefixes && selectedLeave.prefixes.map((pref, index) => {
                            return <>
                              <Table.Row textAlign='center' key={index}>
                                <Table.Cell>{pref}</Table.Cell>
                              </Table.Row>
                            </>
                          })) : (<Table.Cell textAlign='center'>No Prefixes Found</Table.Cell>)
                        }
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column>
                    <Table compact striped celled selectable>
                      <Table.Header>
                        <Table.Row textAlign='center' >
                          <Table.HeaderCell >Suffix</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          (selectedLeave.suffixes !== null && selectedLeave.suffixes !== undefined && selectedLeave.suffixes.length !== 0) ? (selectedLeave.suffixes && selectedLeave.suffixes.map((suff, index) => {
                            return <>
                              <Table.Row textAlign='center' key={index}>
                                <Table.Cell>{suff}</Table.Cell>
                              </Table.Row>
                            </>
                          })) : (<Table.Cell textAlign='center'>No Suffixes Found</Table.Cell>)
                        }
                      </Table.Body>

                    </Table>

                  </Grid.Column>
                </Grid.Row>

              </Grid>}
              {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                <Segment><b>Saturday/Sunday: </b></Segment>
                <Segment>{selectedLeave.satandsundays}</Segment>
                <Segment><b>National Holidays: </b></Segment>
                <Segment>{selectedLeave.holidays}</Segment>
              </Segment.Group>}
              {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                <Segment><b> Working Days  : </b></Segment>
                <Segment>{selectedLeave.appliedDuration}</Segment>
                <Segment><b> Applied Leaves: </b></Segment>
                <Segment>{selectedLeave.effectiveleaves}</Segment>
              </Segment.Group>}
              <br></br>
              <Grid columns='equal' stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h5' dividing>Out of Station Start Time: </Header>
                    <Segment.Group horizontal >
                      <Segment><b> Date  : </b></Segment>
                      <Segment>{
                        selectedLeave.outofstationstartdate == null ? 'NA' : datetimeutils.formatDateTimeToDate(selectedLeave.outofstationstartdate)
                      }</Segment>
                      <Segment><b> Time : </b></Segment>
                      <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationstartdate)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h5' dividing>Out of Station End Time: </Header>
                    <Segment.Group horizontal>
                      <Segment><b> Date  : </b></Segment>
                      <Segment>{selectedLeave.outofstationenddate == null ? 'NA' : datetimeutils.formatDateTimeToDate(selectedLeave.outofstationenddate)}</Segment>
                      <Segment><b> Time : </b></Segment>
                      <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationenddate)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Header as='h5'>Official Tour Details: </Header>
              <Table celled stackable textAlign='center'>

                <Table.Body>
                  <Table.Row>
                    <Table.Cell><b>Start Date Of Official Engagement</b></Table.Cell>
                    <Table.Cell >{officialDutyData.LDStartDate != null ? (datetimeutils.formatDateToDate(officialDutyData.LDStartDate) + ' ' + (officialDutyData.ldstartdaysession) || 'NA') : 'NA'}</Table.Cell>
                    <Table.Cell><b>End Date Of Official Engagement</b></Table.Cell>
                    <Table.Cell >{officialDutyData.LDStartDate != null ? (datetimeutils.formatDateToDate(officialDutyData.LDEndDate) + ' ' + (officialDutyData.ldlastdaysession) || 'NA') : 'NA'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><b>Places to be visited</b></Table.Cell>
                    <Table.Cell >{officialDutyData.placestobevisited || 'NA'}</Table.Cell>


                    <Table.Cell ><b>Purpose of visit</b></Table.Cell>
                    <Table.Cell >{officialDutyData.purposeofvisit || 'NA'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><b>Estimated Budget</b></Table.Cell>
                    <Table.Cell>{officialDutyData.estimatedbudget || 'NA'}</Table.Cell>
                    <Table.Cell><b>Budget Head from which<br />expenditure is to be met</b></Table.Cell>
                    <Table.Cell>{officialDutyData.budgethead || 'NA'}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell><b>Mode of Journey</b></Table.Cell>
                    <Table.Cell>{officialDutyData.modeofjourney || 'NA'}</Table.Cell>
                    <Table.Cell><b>Class of Journey</b></Table.Cell>
                    <Table.Cell>{officialDutyData.classofjourney || 'NA'}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <h4>Reason for leave : </h4>
              <Segment padded='very'>{selectedLeave.comments ? (selectedLeave.comments) : ("No Comments")}</Segment>
              {/* <h4>Address : </h4>
            <Segment className="ui modal-content" padded='very'>{selectedLeave.addressduringleave}</Segment> */}
              <Grid columns='equal' stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h5' >Address : </Header>
                    <Segment.Group horizontal >
                      <Segment>{selectedLeave.addressduringleave}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h5' >Availing LTC?: </Header>
                    <Segment.Group horizontal >
                      <Segment>{selectedLeave.isavailingltc ? 'Yes' : 'No'}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h5' >Encashed Leaves: </Header>
                    <Segment.Group horizontal >
                      <Segment>{selectedLeave.isavailingltc && selectedLeave.encashedleaves != null ? selectedLeave.encashedleaves : 'NA'}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid columns='equal' stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h5' dividing>Phone Number: </Header>
                    <Segment.Group horizontal >
                      <Segment>{selectedLeave.phonenoduringleave}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h5' dividing>Attachment: </Header>
                    {FileAttachmentUtils.renderAttachmentButton(selectedLeave.attachements_details, user)}


                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <h4>Remarks By Esablishment if any : </h4>
              <Segment padded='very'>{selectedLeave.remarks == null ? ('NA') : (selectedLeave.remarks)}</Segment>
              <h3>Delegations</h3>
              <Table compact striped celled selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Nature of Work</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Start Day</Table.HeaderCell>
                    <Table.HeaderCell width={1}>End Day</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status Updated On</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {

                    selectedLeave.leaveDelegations && selectedLeave.leaveDelegations.length > 0 ? (
                      selectedLeave.leaveDelegations && selectedLeave.leaveDelegations
                        .sort((p1, p2) => (p1.delegationsequence > p2.delegationsequence) ? 1 : (p1.delegationsequence < p2.delegationsequence) ? -1 : 0)
                        .map((delegations, index) => {
                          return <>
                            <Table.Row textAlign='center' key={index}>
                              <Table.Cell>{index + 1}</Table.Cell>
                              <Table.Cell>{delegations.userDetailsDTO.fullname}</Table.Cell>
                              <Table.Cell>{delegations.natureofwork}</Table.Cell>
                              <Table.Cell>{datetimeutils.formatDateToDate(delegations.startday)}</Table.Cell>
                              <Table.Cell>{datetimeutils.formatDateToDate(delegations.lastday)}</Table.Cell>
                              <Table.Cell>{(delegations.comments === null) ? ("-") : (delegations.comments)}</Table.Cell>
                              <Table.Cell className={LMSUtils.getClassColor(delegations.status)}>{delegations.status}</Table.Cell>
                              <Table.Cell >{datetimeutils.formatDateTimeToDateTime(delegations.updatedtime)}</Table.Cell>
                            </Table.Row>
                          </>
                        })) : (

                      <Table.Row>
                        <Table.Cell collapsing textAlign='center' colSpan='8'>No Delegations Are Found.</Table.Cell>
                      </Table.Row>

                    )}
                </Table.Body>
              </Table>
              <h3>Approvals</h3>
              <Table compact striped celled selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {selectedLeave && selectedLeave.leaveApprovals ? (
                    selectedLeave.leaveApprovals
                      .filter(t => t.approvaltype === 'APPLIED')
                      .sort((p1, p2) => p1.approversequence - p2.approversequence)
                      .map((Approvals, index) => (
                        <Table.Row textAlign='center' key={index}>
                          <Table.Cell>{Approvals.approversequence}</Table.Cell>
                          <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                          <Table.Cell className={LMSUtils.getClassColor(Approvals.status)}>
                            {Approvals.status || '-'}
                          </Table.Cell>
                          <Table.Cell>{Approvals.comments || '-'}</Table.Cell>
                          <Table.Cell>
                            {datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}
                          </Table.Cell>
                        </Table.Row>
                      ))
                  ) : (
                    'No Details Available'
                  )}

                </Table.Body>
              </Table>
              {(selectedLeave.leaveApprovals != null ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'CANCELLED').length > 0 ? true : false) : false) &&
                <>

                  <h3>Leave Cancellation Approvals</h3>

                  <Segment.Group horizontal>
                    <Segment ><b>Cancellation Initiation Time: </b></Segment>
                    <Segment>
                      {datetimeutils.formatDateTimeToDateTime(selectedLeave.cancelinittime)}
                    </Segment>
                  </Segment.Group>
                  <Segment.Group horizontal>
                    <Segment ><b>Reason for Cancellation Request: </b></Segment>
                    <Segment>
                      {selectedLeave.cancelinitncomments}
                    </Segment>
                  </Segment.Group>
                  <Table compact striped celled selectable>
                    <Table.Header>
                      <Table.Row textAlign='center'>
                        <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {
                        Object.values(selectedLeave.leaveApprovals != null ? selectedLeave.leaveApprovals.filter(t => (t.approvaltype === 'CANCELLED').length) > 0 : false) ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'CANCELLED')
                          .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                          .map((Approvals, index) => {
                            return <>

                              <Table.Row textAlign='center' key={index}>
                                <Table.Cell>{Approvals.approversequence}</Table.Cell>
                                <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                                <Table.Cell className={LMSUtils.getClassColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                                <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments)}</Table.Cell>
                                <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                              </Table.Row>
                            </>
                          })) : ("No Details Available")
                      }

                    </Table.Body>
                  </Table>
                </>
              }
              <br />
            </Container>
          </Modal.Content>

        </Modal.Description >
      </Modal.Content >
      {modalchatOpen && (
        <CommentsPage entityId={selectedLeaveId} entity={"Leave"} onClose={handleChatCloseModal} />
      )}


      <Modal.Actions style={{ backgroundColor: 'rgb(242, 242, 272)' }}>
        {/* <Button onClick={() => handleViewInNewTab(leaveId)}>View in New Tab</Button> */}
        {selectedLeave != null && selectedLeave.leave_status!=null&&
          selectedLeave.leave_status.includes('APPROVED') &&
          (selectedLeave.iscancelinitiated == null || selectedLeave.iscancelinitiated === false) ? (
          <Button onClick={()=>handleCancelModal(true)} secondary>
            Request for Leave Cancellation <Icon />
          </Button>
        ) : null
        }
      {cancelModalOpen&&(<LeaveOwnerOperations.ModalCancelConfirmation
        selectedLeave={selectedLeave}
        handleGetLeaves={handleGetLeaves}
        handleGetLeaveBalance={handleGetLeaveBalance}
        handleCancelModal={handleCancelModal}
        handleLeaveModal={handleClose}
  
      />)}
      {deleteModalOpen&&(<LeaveOwnerOperations.ModalDeleteConfirmation
        selectedLeave={selectedLeave}
        handleGetLeaves={handleGetLeaves}
        handleGetLeaveBalance={handleGetLeaveBalance}
        handleDeleteModal={handleDeleteModal}
        handleLeaveModal={handleClose}
  
      />)}
       {selectedLeave != null && selectedLeave.leave_status!=null&&
       (!selectedLeave.leave_status.includes('APPROVED') && !selectedLeave.leave_status.includes('REJECTED') && (!selectedLeave.leave_status.includes('CANCELLATION'))&&
       selectedLeave.isoldleave===false && selectedLeave.editedleaveid==null) ? (
              <Button content='Delete Leave' labelPosition='right' icon='trash' onClick={() => handleDeleteModal(true)} negative>
                {/* <Icon name='trash' /> */}
              </Button>
            ) : null}

        <Button content='Conversation' labelPosition='right' icon='chat' color="grey" onClick={() => handleChatOpenModal(leaveId)}></Button>

        <Button onClick={onClose} primary>
          Close <Icon name='chevron right' />
        </Button>
      </Modal.Actions>
    </Modal >
  )
}

export default LeaveDetailsModalToOperate;