const LMSUtils= {
    handleLeaveName,
    getClassColorJoining,
    getClassColor,
    getClassIcon
}

function handleLeaveName (name) {
    if (name === "CL") {
      return "Casual Leave";
    }
    else if (name === "HPL") {
      return "HalfPay Leave";
    }
    else if (name === "EL") {
      return "Earned Leave";
    }
    else if (name === "VL") {
      return "Vacation Leave"
    }
    else if (name === "SCL") {
      return "Special Casual Leave";
    }
    else if (name === "RH") {
      return "Restricted Holiday";
    }
    else if (name === "PROL") {
      return "Project Leave";
    }
    else if (name === "COL") {
      return "Commuted Leave";
    }
    else if (name === "SL") {
      return "Station Leave";
    }
    else if (name === "LD") {
      return "Leave on Duty";
    }
    else {
      return "No Leave Found"
    }
  };

  function getClassColorJoining  (color)  {
    if (color.includes("CANCELLATION REJECTED")) {
      return 'warning'
    }
    else if (color.includes("APPLIED") || color.includes("PENDING")) {
      return 'warning'
    }
    else if (color.includes("REJECTED")) {
      return 'negative'
    }
    else if (color === ""||color.toLowerCase().includes("submitted")||color.toLowerCase().includes('not applicable')) {
      return ''
    }
    else {
      return 'positive'
    }
  };

  function getClassColor  (color)  {
    if (color.includes("CANCELLATION REJECTED")) {
      return 'warning'
    }
    else if (color.includes("APPLIED") || color.includes("PENDING") || color.includes("SUBMITTED")) {
      return 'warning'
    }
    else if (color.includes("REJECTED")) {
      return 'negative'
    }
    else if (color === "") {
      return ''
    }
    else {
      return 'positive'
    }
  };

  function getClassIcon  (details) {
    if (details.includes("APPLIED") || details.includes("PENDING") || details.includes("SUBMITTED")) {
      return 'attention'
    }
    else if (details.includes("REJECTED")) {
      return 'close'
    }
    else if (details.includes("ACCEPT") || details.includes("APPROVE")) {
      return 'checkmark'
    }
    else if (details === null || details === undefined || details === '') {
      return null
    }
    else {
      return 'attention'
    }
  };

  export default LMSUtils;