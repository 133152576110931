import React, { useContext, useEffect, useState } from 'react';
import { Grid,Header,Container, Table, Divider, Modal, TextArea, Input, Radio, Form, Button, Pagination, Dropdown, Segment, Icon } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import datetimeutils from "../utils/DateTimeUtils";
import LMSUtils from "./LMSUtils";
import LeaveJoiningFormModal from './LeaveJoiningFormModal';
import LeaveDetailsModal from './LeaveDetailsModal';
import Toasts from '../misc/Toasts';
import LeaveDetailsModalToOperate from './LeaveDetailsModalToOperate';
import SubmitLeaveJoiningFormModal from './SubmitLeaveJoiningModal';
import LeaveEdit from './LeaveEdit'

const AppliedLeaveHistory = (props) => {
  const { handleGetLeaveBalance, HolidaysList, userdetails,LeaveBalanceList,restrictedHolidays } = props;
  const authContext = useContext(AuthContext);
  const user = authContext.getUser();
  const [leavesList, setLeavesList] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startEndQuery, setStartEndQuery] = useState('');
  const [appliedDate, setAppliedDate] = useState('');
  const [showLeaveJoiningForm, setLeaveJoiningForm] = useState(false);
  const [showLeaveDetails, setShowLeaveDetails] = useState(false);
  const [currentLeaveJoiningFormId, setLeaveJoiningFormId] = useState('');
  const [currentLeaveId, setLeaveId] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [error, setError] = useState('');
  const [editModal, setEditModal]=useState(false);
  const [showSubmitLeaveJoiningFormModal, setShowSubmitLeaveJoiningFormModal] = useState(false);


  const handleShowSubmitLeaveJoiningFormModal = (value) => {
    console.log(currentLeaveId)
    setShowSubmitLeaveJoiningFormModal(value);
  };

  const [filters, setFilters] = useState({
    page: 1,
    size: 10,
    sortField: 'applied_time',
    sortOrder: 'desc',
    leavestatus: 'all',
    leaveapplied_date: '2023-12-11T20:13:39.4141'
  });

  useEffect(() => {
    handleGetLeaves();
  }, [filters]);

  useEffect(() => {
    iipeerpapi.getUsers(user)
      .then(response => {
        if (response && Array.isArray(response.data)) {
          const mappedUsers = response.data.map(user1 => ({
            key: user1.username,
            value: user1.username,
            text: user1.fullname,
          }));
          setUsers(mappedUsers);
        } else {
          handleLogError("Invalid response data for users");
        }
      })
      .catch(error => {
        handleLogError(error);
      });
  }, []);

  const handleGetLeaves = () => {
    let currentFilters = { ...filters };



    if (selectedLeaveType.length > 0) {
      currentFilters.leavetype = selectedLeaveType;
    } else {
      delete currentFilters.leavetype;
    }

    if (startDate.length > 0) {
      currentFilters.start_date = startDate;
    } else {
      delete currentFilters.start_date;
    }

    if (endDate.length > 0) {
      currentFilters.end_date = endDate;
    } else {
      delete currentFilters.end_date;
    }

    if (startEndQuery.length > 0) {
      currentFilters.startendquery = startEndQuery;
    } else {
      delete currentFilters.startendquery;
    }

    if (appliedDate.length > 0) {
      currentFilters.leaveapplied_date = appliedDate;
    } else {
      delete currentFilters.leaveapplied_date;
    }

    iipeerpapi.getLeavesforAppliedPerson(user, currentFilters)
      .then(response => {
        setLeavesList(response.data);
        setTotalElements(response.data.totalElements);
      })
      .catch(error => {
        handleLogError(error);
      });
  };

  const handleSizePerPageChange = (event, data) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      size: data.value,
    }));
  };

  const handleSortFied = (event, data) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      sortField: data.value,
    }));
  };

  const handleSortOrder = (event, data) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      sortOrder: data.value,
    }));
  };

  const handleFilterStatus = (event, data) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      leavestatus: data.value,
    }));
  };



  const handlePageChange = (event, data) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      page: data.activePage,
    }));
  };


  const handleLeaveDropdown = (event, data) => {
    setSelectedLeaveType(data.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartEndRange = (e, { value }) => {
    setStartEndQuery(value);
  };

  const handleAppliedDate = (e) => {
    setAppliedDate(e.target.value);
  };

  const handleRefresh = () => {
    handleGetLeaves();
  };

  const handleClear = () => {
    setSelectedLeaveType([]);
    setStartDate('');
    setEndDate('');
    setStartEndQuery('');
    setAppliedDate('');
    setFilters(prevFilters => ({
      ...prevFilters,
      appliedPersonUsername: undefined,
      leavetype: undefined,
      start_date: undefined,
      end_date: undefined,
      startendquery: undefined,
      leaveapplied_date: undefined,
    }));
  };

  const handleShowLeaveJoiningForm = (leavejoiningformid) => {
    setLeaveJoiningFormId(leavejoiningformid);
    setLeaveJoiningForm(true);
  }

  const handleShowLeaveDetails = (leaveid) => {
    setLeaveId(leaveid);
    setShowLeaveDetails(true);
  }

  const handleEditModalOpen = (leaveid) => {
    setLeaveId(leaveid)
    setEditModal(true)
  };
  const handleEditModalClose = () => {
    setEditModal(false)
  }

  const handleCloseLeaveViewModal = () => {
    setLeaveId(null);
    setShowLeaveDetails(false);
  };

  const handleCloseModal = () => {
    setLeaveJoiningFormId(null);
    setLeaveJoiningForm(false);
  };

  const handleOpen = (leaveid) => {
    setLeaveId(leaveid);
    setIsOpen(true);
  }




  return (
    <>
      <Segment>
        <Form>
          <Form.Group widths='equal'>

            {/* <Form.Field>
                <label>Leave Approval Person Name</label>
                <Dropdown
                  placeholder='Search by name'
                  fluid
                  multiple
                  search
                  selection
                  options={users}
                  value={selectedApprovalUsernames}
                  onChange={handleApprovalNamesDropdown}
                />
              </Form.Field> */}
            <Form.Field>
              <label>Leave Type</label>
              <Dropdown
                placeholder='Search by Leave Type'
                fluid
                multiple
                search
                selection
                options={[
                  { key: 'CL', text: 'CL', value: 'CL' },
                  { key: 'COL', text: 'COL', value: 'COL' },
                  { key: 'EL', text: 'EL', value: 'EL' },
                  { key: 'HPL', text: 'HPL', value: 'HPL' },
                  { key: 'RH', text: 'RH', value: 'RH' },
                  { key: 'SCL', text: 'SCL', value: 'SCL' },
                  { key: 'VL', text: 'VL', value: 'VL' },
                  { key: 'SL', text: 'SL', value: 'SL' },
                  { key: 'LD', text: 'LD', value: 'LD' },
                  { key: 'PROL', text: 'PROL', value: 'PROL' }
                ]}
                value={selectedLeaveType}
                onChange={handleLeaveDropdown}
              />
            </Form.Field>
            <Form.Field>
              <label>Applied Date</label>
              <Input
                type="date"
                name="date"
                value={appliedDate}
                onChange={handleAppliedDate}
              />
            </Form.Field>
          </Form.Group>



          <Form.Group widths='equal' >
            <Form.Field>
              <label>Leave Availing Start Date</label>
              <Input
                type="date"
                name="date"
                value={startDate}
                onChange={handleStartDateChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Select Range</label>

              <Form.Field>
                <Radio
                  label="Equals"
                  name="equals"
                  value="equals"
                  checked={startEndQuery === 'equals'}
                  onChange={handleStartEndRange}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Between"
                  name="between"
                  value="between"
                  checked={startEndQuery === 'between'}
                  onChange={handleStartEndRange}
                />
              </Form.Field>
            </Form.Field>
            <Form.Field>
              <label>Leave Availing End Date</label>
              <Input
                type="date"
                name="date"
                value={endDate}
                onChange={handleEndDateChange}
              />
            </Form.Field>
          </Form.Group>


          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <Button onClick={handleRefresh} color="green">Refresh</Button>
            <Button onClick={handleClear} style={{ marginLeft: '10px' }}>Clear</Button>
          </div>

        </Form>

      </Segment>
      <Divider />

      <Grid>
        <Grid.Row columns={3} style={{ marginLeft: '15px', marginRight: '15px', justifyContent: 'space-between' }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            Records Per Page :
            <Dropdown
              options={[
                { key: '10', text: '10', value: 10 },
                { key: '25', text: '25', value: 25 },
                { key: '50', text: '50', value: 50 },
                { key: '100', text: '100', value: 100 },
              ]}
              value={filters.size}
              onChange={handleSizePerPageChange}
              style={{ fontWeight: 'bold', marginLeft: '3px' }}
              icon="angle down"
            />
            <span style={{ marginLeft: '15px' }}>Total count: <strong>{totalElements}</strong></span>
          </span>

          <span style={{ display: 'flex', alignItems: 'center' }}>
            Sort By :
            <Dropdown
              options={[
                { key: 'leavetype', text: 'Leave Type', value: 'leavetype' },
                { key: 'start_date', text: 'Start Date', value: 'start_date' },
                { key: 'end_date', text: 'End Date', value: 'end_date' },
                { key: 'effectiveleaves', text: 'Effective Leaves', value: 'effectiveleaves' },
                { key: 'applied_duration', text: 'Working days', value: 'applied_duration' },
                { key: 'applied_time', text: 'Applied Date', value: 'applied_time' },
                { key: 'leave_status', text: 'Leave Status', value: 'leave_status' },
              ]}
              value={filters.sortField}
              onChange={handleSortFied}
              style={{ fontWeight: 'bold', marginLeft: '3px', marginRight: '15px' }}
              icon="angle down"
            />

            Order :
            <Dropdown
              options={[
                { key: 'desc', text: 'DESC', value: 'desc' },
                { key: 'asc', text: 'ASC', value: 'asc' },
              ]}
              value={filters.sortOrder}
              onChange={handleSortOrder}
              style={{ fontWeight: 'bold', marginLeft: '3px' }}
              icon="angle down"
            />
          </span>

          {/* <span>
            <Dropdown
              text='Filter Posts'
              icon='filter'
              floating
              labeled
              button
              className='icon'
              options={[
                { key: 'all', text: 'All', value: 'all' },
                { key: 'APPROVED BY ALL APPROVERS', text: 'Approved by all Approvers', value: 'APPROVED BY ALL APPROVERS' },
                { key: 'APPROVED BY 1st APPROVER', text: 'Approved by 1st Approvers', value: 'APPROVED BY 1st APPROVER' },
                { key: 'PENDING AT 1ST APPROVER', text: 'Pending at 1st Approver', value: 'PENDING AT 1ST APPROVER' },
                { key: 'PENDING AT DELEGATIONS', text: 'Pending at Delegations', value: 'PENDING AT DELEGATIONS' },
                { key: 'CANCELLATION APPROVED BY ALL APPROVER', text: 'Cancellation Approved By Al Approver', value: 'CANCELLATION APPROVED BY ALL APPROVERS' },
                { key: 'REJECTED BY 1st APPROVER', text: 'Rejected by 1st Approver', value: 'REJECTED BY 1st APPROVER' },
              ]}
              value={filters.leavestatus} 
              onChange={handleFilterStatus}
              style={{ fontWeight: 'bold', marginLeft: '3px' }}

            />
          </span> */}
        </Grid.Row>
      </Grid>


      <Table compact striped celled selectable >
        <Table.Header>
          <Table.Row textAlign='center'>
            <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
            <Table.HeaderCell width={2}>Leave Type</Table.HeaderCell>
            <Table.HeaderCell width={3}>Start Date</Table.HeaderCell>
            <Table.HeaderCell width={3}>End Date</Table.HeaderCell>
            <Table.HeaderCell width={2}>Effective Leaves</Table.HeaderCell>
            <Table.HeaderCell width={2}>Working days </Table.HeaderCell>
            <Table.HeaderCell width={3}>Applied Time </Table.HeaderCell>
            <Table.HeaderCell width={4}>Leave Status</Table.HeaderCell>
            <Table.HeaderCell width={3}>Joining Form Status</Table.HeaderCell>
            <Table.HeaderCell width={1}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body >
          {leavesList.content && leavesList.content.length > 0 ? (
            leavesList.content.map((Leave, index) => (
              <Table.Row textAlign='center' key={index}>
                <Table.Cell>{(leavesList.pageable.pageNumber) * leavesList.pageable.pageSize + index + 1}</Table.Cell>
                <Table.Cell>{Leave.leavetype}</Table.Cell>
                <Table.Cell>{datetimeutils.formatDateToDate(Leave.start_date)}</Table.Cell>
                <Table.Cell>{datetimeutils.formatDateToDate(Leave.end_date)}</Table.Cell>
                <Table.Cell>
    <div>
      {((Leave.leavetype === 'SL' || Leave.leavetype === 'LD') && (Leave.isavailingltc==null ||Leave.isavailingltc===false)) ? (
        'NA'
      ) : (Leave.isavailingltc==null ||Leave.isavailingltc===false ||Leave.encashedleaves==null||Leave.encashedleaves===0 )? (
        <><b>{Leave.effectiveleaves}</b></>
      ) : (
        <>{Leave.leavetype}: <b>{Leave.effectiveleaves}</b></>
      )}
    </div>
    {(Leave.isavailingltc === true && Leave.encashedleaves!=null &&Leave.encashedleaves >0) && (
      <div>
        EL(Encashing): {Leave.encashedleaves}
      </div>
    )}
  </Table.Cell>
                <Table.Cell>{Leave.leavetype === 'SL' || Leave.leavetype === 'LD' ? 'NA' : (<><b>{Leave.applied_duration} </b>Day(s)</>)}</Table.Cell>
                <Table.Cell>{datetimeutils.formatDateTimeToDateTime(Leave.applied_time)}</Table.Cell>
                <Table.Cell className={LMSUtils.getClassColor(Leave.leave_complete_status)}>
                  {Leave.leave_complete_status !== null && (
                    <Icon name={LMSUtils.getClassIcon(Leave.leave_complete_status)} />
                  )}
                  {Leave.leave_complete_status}
                </Table.Cell>
                <Table.Cell className={LMSUtils.getClassColorJoining(Leave.leavejoiningformcompletestatus === null ? '' : Leave.leavejoiningformcompletestatus)}>

                  {!(Leave.leavejoiningformcompletestatus.toLowerCase().includes("submitted") || Leave.leavejoiningformcompletestatus.toLowerCase().includes('not applicable')) && (
                    <Icon name={LMSUtils.getClassIcon(Leave.leavejoiningformcompletestatus)} />
                  )}

                  {Leave.leavejoiningformcompletestatus === null ? '-' : (Leave.leavejoiningformcompletestatus.includes('Yet to be Submitted') === true ? (<Button color='blue' onClick={()=>{setLeaveId(Leave.leaveid);handleShowSubmitLeaveJoiningFormModal(true);}}>Submit Leave Joining Form</Button>) : Leave.leavejoiningformcompletestatus)}
                </Table.Cell>
                <Table.Cell>
                  <Button.Group color='blue'>
                    <Dropdown
                      icon='angle right'
                      floating
                      button
                      className='icon'
                    >
                      <Dropdown.Menu>

                        {Leave.leaveid && (
                          <Dropdown.Item onClick={() => handleShowLeaveDetails(Leave.leaveid)}>
                          {
                            
                          } View Leave Details
                          </Dropdown.Item>
                        )}
                        {(Leave.leavetype!=='SL'&&Leave.leavetype!=='LD'&&!Leave.leave_status.toLowerCase().includes("delegations") && 
                !Leave.leave_status.toLowerCase().includes("cancellation") && 
                !Leave.leave_status.toLowerCase().includes("rejected"))&&(<Dropdown.Item onClick={() => handleEditModalOpen(Leave.leaveid)}>
            
                Edit Leave</Dropdown.Item>)} 
                        {Leave.leavejoiningformid && (
                          <Dropdown.Item onClick={() => handleShowLeaveJoiningForm(Leave.leavejoiningformid)}>
                            View LeaveJoining Form
                          </Dropdown.Item>
                        )}


                      </Dropdown.Menu>
                    </Dropdown>
                  </Button.Group>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={9} textAlign='center'>No Details are Found</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          activePage={filters.page}
          totalPages={Math.ceil(totalElements / filters.size)}
          onPageChange={handlePageChange}
        />
      </div>
      {showLeaveDetails && (
        <LeaveDetailsModalToOperate
          leaveId={currentLeaveId}
          onClose={handleCloseLeaveViewModal}
          fromleaveviewmodal = {false}
          leaveowner={true}
          handleGetLeaves={handleGetLeaves}
          handleGetLeaveBalance={handleGetLeaveBalance}
        />
      )}

      {showLeaveJoiningForm && (
        <LeaveJoiningFormModal
          leaveowner={true}
          leavejoiningformid={currentLeaveJoiningFormId}
          handleGetLeaves={handleGetLeaves}
          onClose={handleCloseModal}
        />
      )}

      {showSubmitLeaveJoiningFormModal&&(<SubmitLeaveJoiningFormModal

        leaveowner={true}
        handleShowSubmitLeaveJoiningFormModal={handleShowSubmitLeaveJoiningFormModal}
        leaveId={currentLeaveId}
        handleGetLeaves={handleGetLeaves}
        userdetails={userdetails}
      
      />)}
       {
        <>
        <Modal
          closeIcon
          open={editModal}
          onClose={handleEditModalClose}
          size='fullscreen'
          closeOnDimmerClick={false}

        >
          <Header textAlign='center' as='h2' color='blue'>Edit Leave Details</Header>
          <Modal.Content image scrolling >
            <Container>

              <Modal.Description>
               
              {(editModal===true)&&<LeaveEdit 
            leaveid = {currentLeaveId}
            userdetails={userdetails}
            users={users}
            HolidaysList={HolidaysList}
            handleGetLeaveBalance={handleGetLeaveBalance}
            handleGetLeaves={handleGetLeaves}
            handleEditModalClose= {handleEditModalClose}
            restrictedHolidays={restrictedHolidays}
            LeaveBalanceList={LeaveBalanceList}
          />}
         
         </Modal.Description >
            </Container>
          </Modal.Content >
    </Modal>
    </>
       }

    </>
  )
}

export default AppliedLeaveHistory;