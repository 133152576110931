import React, { useState, useContext, useEffect } from 'react';
import { Grid, Form, Table } from 'semantic-ui-react';
import { Dropdown } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import AuthContext from '../context/AuthContext';

const CompleteLeaveBalance = ({ users }) => {
  const [leaveBalanceList, setLeaveBalanceList] = useState(null);
  const [isLeaveBalanceLoading, setIsLeaveBalanceLoading] = useState(false);
  const Auth = useContext(AuthContext);

  const handleGetLeaveBalance = (username) => {
    setIsLeaveBalanceLoading(true);
    const user = Auth.getUser();

    iipeerpapi.getLeaveBalanceByUsername(user, username)
      .then(response => {
        setLeaveBalanceList(response.data);
      })
      .catch(error => {
        setLeaveBalanceList([]);
        handleLogError(error);
      })
      .finally(() => {
        setIsLeaveBalanceLoading(false);
      });
  };

  const handleInputChange = (e, { value }) => {
    handleGetLeaveBalance(value);
  };

  const getUsersList = () => {
    const currentUsername = Auth.getUser().data.preferred_username;
    return users
      .filter(user => user.username !== currentUsername)
      .map(user => ({
        key: user.username,
        text: user.fullname,
        value: user.username,
      }));
  };

  const renderLeaveBalanceList = () => {
    if (!leaveBalanceList || leaveBalanceList.length === 0) {
      return (
        <Table.Row key={1}>
          <Table.Cell collapsing textAlign='center' colSpan='5'>No Leave Balance Are Found.</Table.Cell>
        </Table.Row>
      );
    }

    return leaveBalanceList.map((leaveBalance, index) => (
      <Table.Row textAlign='center' key={index}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{leaveBalance.leavetype}</Table.Cell>
        <Table.Cell>{leaveBalance.remaining}</Table.Cell>
      </Table.Row>
    ));
  };

  const usersList = getUsersList();

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={3} centered>
          <Grid.Column>
            <Form>
              <Form.Group widths='equal'>
                <Form.Field>
                  <label>Name</label>
                  <Dropdown
                    closeOnEscape
                    id='username'
                    onChange={handleInputChange}
                    clearable
                    placeholder='Person Name'
                    search
                    fluid
                    selection
                    options={usersList}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table compact striped celled selectable>
        <Table.Header>
          <Table.Row textAlign='center'>
            <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
            <Table.HeaderCell width={3}>Leave Type</Table.HeaderCell>
            <Table.HeaderCell width={3}>Remaining Leaves</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderLeaveBalanceList()}
        </Table.Body>
      </Table>
    </>
  );
};

export default CompleteLeaveBalance;