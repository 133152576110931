import React, { useState, useContext, useMemo } from 'react';
import { Grid, Form, Table, Button, ButtonGroup, Dropdown, Icon, Message, Segment, Input } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import AuthContext from '../context/AuthContext';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Toasts from '../misc/Toasts';


const ViewLeaveBalance = ({ users }) => {
  const [leaveBalanceList, setLeaveBalanceList] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState('person');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [sortColumn, setSortColumn] = useState('fullname');
  const [sortDirection, setSortDirection] = useState('ascending');
  const [formError, setFormError] = useState('');
  const Auth = useContext(AuthContext);

  const roleOptions = [
    { key: 'NTS', text: 'NTS', value: 'NTS' },
    { key: 'Faculty', text: 'Faculty', value: 'Faculty' },
  ];

  const getUserOptions = () => {
    return users.map(user => ({
      key: user.username,
      text: user.fullname,
      value: user.username,
    }));
  };

  const handleViewModeChange = (mode) => {
    setViewMode(mode);
    setSelectedUsers([]);
    setSelectedRoles([]);
    setFormError('');
  };

  const handleYearChange = (e) => {
    setSelectedYear(parseInt(e.target.value, 10));
  };

  const validateForm = () => {
    if (viewMode === 'person' && selectedUsers.length === 0) {
      setFormError('Please select at least one user');
      return false;
    }
    if (viewMode === 'role' && selectedRoles.length === 0) {
      setFormError('Please select at least one role');
      return false;
    }
    setFormError('');
    return true;
  };

  const handleRefresh = () => {
    if (!validateForm()) return;

    setIsLoading(true);
    const user = Auth.getUser();
    let formData = new FormData();

    formData.append('year', selectedYear);

    if (viewMode === 'person') {
      formData.append('viewmode', "person");
      formData.append('usernames', selectedUsers);
    } else if (viewMode === 'role') {
      formData.append('viewmode', "role");
      formData.append('roleslist', selectedRoles);
    } else if (viewMode === 'all') {
      formData.append('viewmode', "role");
      formData.append('roleslist', ['NTS', 'Faculty']);
    }

    iipeerpapi.getleavebalanceforadmin(user, formData)
      .then(handleApiResponse)
      .catch(handleApiError)
      .finally(() => setIsLoading(false));
  };

  const handleApiResponse = (response) => {
    const allLeaveTypes = new Set();
    const mappedData = response.data.reduce((acc, item) => {
      const user = users.find(u => u.username === item.username);
      if (!user) return acc;
      
      allLeaveTypes.add(item.leavetype);
      
      const existingEntry = acc.find(entry => entry.username === item.username);
      if (existingEntry) {
        existingEntry[item.leavetype] = item.remaining;
      } else {
        acc.push({
          username: item.username,
          fullname: user.fullname,
          year: item.year,
          [item.leavetype]: item.remaining
        });
      }
      return acc;
    }, []);
    
    setLeaveTypes(Array.from(allLeaveTypes).sort());
    setLeaveBalanceList(mappedData);
  };

  const handleApiError = (error) => {
    setLeaveBalanceList([]);
    handleLogError(error);
    Toasts.notifyError('Error fetching leave balance data');
  };

  const handleSort = (clickedColumn) => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection('ascending');
    } else {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
    }
  };

  const handleDownload = (format) => {
    if (format === 'csv') {
      downloadCSV();
    } else if (format === 'excel') {
      downloadExcel();
    }
  };

  const downloadOptions = [
    { key: 'csv', text: 'CSV', value: 'csv' },
    { key: 'excel', text: 'Excel', value: 'excel' },
  ];
  const downloadCSV = () => {
    const headers = ['S.No', 'Full Name', 'Username', 'Year', ...leaveTypes];
    const csvContent = [
      headers.join(','),
      ...sortedLeaveBalanceList.map((item, index) => 
        [
          index + 1,
          item.fullname,
          item.username,
          item.year,
          ...leaveTypes.map(type => item[type] !== undefined ? item[type] : 'N/A')
        ].join(',')
      )
    ].join('\n');
  
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, "leave_balance.csv");
  };
  
  const downloadExcel = () => {
    const headers = ['S.No', 'Full Name', 'Username', 'Year', ...leaveTypes];
    const data = sortedLeaveBalanceList.map((item, index) => [
      index + 1,
      item.fullname,
      item.username,
      item.year,
      ...leaveTypes.map(type => item[type] !== undefined ? item[type] : 'N/A')
    ]);
  
    const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Leave Balance");
    XLSX.writeFile(wb, "leave_balance.xlsx");
  };

  const sortedLeaveBalanceList = useMemo(() => {
    return [...leaveBalanceList].sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === 'ascending' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === 'ascending' ? 1 : -1;
      return 0;
    });
  }, [leaveBalanceList, sortColumn, sortDirection]);

  const renderLeaveBalanceTable = () => {
    if (sortedLeaveBalanceList.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={3 + leaveTypes.length} textAlign="center">No Leave Balance Found</Table.Cell>
        </Table.Row>
      );
    }

    return sortedLeaveBalanceList.map((item, index) => (
      <Table.Row key={item.username}>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>{item.fullname}</Table.Cell>
        
        <Table.Cell>{item.year}</Table.Cell>
        {leaveTypes.map(type => (
          <Table.Cell key={type}>{item[type] !== undefined ? item[type] : 'N/A'}</Table.Cell>
        ))}
      </Table.Row>
    ));
  };

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={16}>
          <Segment>
            <Form error={!!formError}>
              <Grid centered>
                <Grid.Row>
                  <Grid.Column width={16} textAlign="center">
                    <ButtonGroup>
                      <Button color={viewMode === 'person' ? 'green' : undefined} onClick={() => handleViewModeChange('person')}>Person</Button>
                      <Button color={viewMode === 'role' ? 'green' : undefined} onClick={() => handleViewModeChange('role')}>Role</Button>
                      <Button color={viewMode === 'all' ? 'green' : undefined} onClick={() => handleViewModeChange('all')}>All</Button>
                    </ButtonGroup>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={8}>
                    {viewMode === 'person' && (
                      <Form.Field required>
                        <label>Select Users</label>
                        <Dropdown
                          placeholder='Select Users'
                          fluid
                          multiple
                          search
                          selection
                          options={getUserOptions()}
                          onChange={(e, { value }) => setSelectedUsers(value)}
                        />
                      </Form.Field>
                    )}
                    {viewMode === 'role' && (
                      <Form.Field required>
                        <label>Select Roles</label>
                        <Dropdown
                          placeholder='Select Roles'
                          fluid
                          multiple
                          selection
                          options={roleOptions}
                          onChange={(e, { value }) => setSelectedRoles(value)}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <label>Select Year</label>
                      <Input
                        type='number'
                        value={selectedYear}
                        onChange={handleYearChange}
                      />
                    </Form.Field>
                    <Message error content={formError} />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Button primary onClick={handleRefresh} loading={isLoading}>
                      <Icon name='refresh' /> Refresh
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          {isLoading ? (
            <Message icon>
              <Icon name='circle notched' loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                We are fetching that content for you.
              </Message.Content>
            </Message>
          ) : (
            <div>
           <Dropdown
  button
  className='icon'
  floating
  labeled
  icon='download'
  options={downloadOptions}
  search
  text='Download'
  onChange={(e, { value }) => handleDownload(value)}
  floated='right'
/>
            <Table celled sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>S.No.</Table.HeaderCell>
                  <Table.HeaderCell sorted={sortColumn === 'fullname' ? sortDirection : null} onClick={() => handleSort('fullname')}>Name</Table.HeaderCell>
                  <Table.HeaderCell sorted={sortColumn === 'year' ? sortDirection : null} onClick={() => handleSort('year')}>Year</Table.HeaderCell>
                  {leaveTypes.map(type => (
                    <Table.HeaderCell key={type} sorted={sortColumn === type ? sortDirection : null} onClick={() => handleSort(type)}>{type}</Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {renderLeaveBalanceTable()}
              </Table.Body>
            </Table>
            </div>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ViewLeaveBalance;