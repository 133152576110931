import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import PrivateRoute from './components/misc/PrivateRoute';
import Navbar from './components/misc/Navbar';
import Footerbar from './components/misc/Footerbar';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Loader } from 'semantic-ui-react';
import LMSPage from './components/LMS/LMSPage';
import LeaveModalPage from './components/LMS/LeaveModalPage';

// Lazy load components
const Home = lazy(() => import('./components/home/Home'));
const Login = lazy(() => import('./components/home/Login'));
const LMSTab = lazy(() => import('./components/LMS/LMSTab'));
const Feedback = lazy(() => import('./components/home/Feedback'));
const BillsPage = lazy(() => import('./components/TBA/BillsPage'));
const Forgotpassword = lazy(() => import('./components/home/Forgotpassword'));
const FirstPage = lazy(() => import('./components/MoM/firstPage'));
const Changepassword = lazy(() => import('./components/home/Changepassword'));
const Customapproval = lazy(() => import('./components/MoM/customapproval'));
const Attendeeapproval = lazy(() => import('./components/MoM/attendeeapproval'));
const LeaveDelegationAcceptance = lazy(() => import('./components/LMS/LeaveDelegationAcceptance'));

function LoginWithRedirect() {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();
  
  const redirectPath = searchParams.get('redirect') || '/home';

  if (isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Login />;
}

function App() {
  const location = useLocation();

  return (
    <AuthProvider>
      <Navbar />
      <Suspense fallback={<Loader active inline="centered" />}>
        <Routes>
          <Route path='/' element={<Navigate to="/login" replace />} />
          <Route path='/home' element={<Home />} />
          <Route 
            path='/login' 
            element={<LoginWithRedirect />} 
          />
          <Route path="/viewleave/:leaveId" element={<LeaveModalPage />} />
          <Route path='/iipemomapproval/:id' element={<Customapproval />} />
          <Route path="/lms/*" element={
            <PrivateRoute>
              <Routes>
                <Route path="/" element={<LMSPage />} />
                
                <Route path=":tabName/*" element={<LMSPage />} />
                <Route path="leavedelegationacceptance/:delegationid" element={<LeaveDelegationAcceptance />} />
              </Routes>
            </PrivateRoute>
          } />
          
          <Route path='/lms/leavedelegationacceptance/:delegationid' element={<LeaveDelegationAcceptance />} />
          <Route path='/iipeapproval/:aid' element={<Attendeeapproval />} />
          <Route path="/changepassword" element={<Changepassword />} />
          <Route path="/forgotpassword" element={<Forgotpassword />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/mom" element={<FirstPage />} />
          <Route path="/bills" element={<PrivateRoute><BillsPage /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
      <Footerbar />
      <ToastContainer />
    </AuthProvider>
  );
}

export default function Root() {
  return (
    <Router basename='/estb'>
      <App />
    </Router>
  );
}