import React, { Component } from 'react'
import { Grid, Form, Button, Input, Table, Header,Container } from 'semantic-ui-react'
import { Dropdown, Segment, Image, Modal, Icon, Pagination } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import 'react-toastify/dist/ReactToastify.css';
import Toasts from '../misc/Toasts'
import datetimeutils from "../utils/DateTimeUtils";
import LeaveEditHistoryTable from './LeaveEditHistoryTable';

class LeaveApprovals extends Component {
  static contextType = AuthContext

  getYears() {
    const current = new Date()

    return [
      // { key: 1, text: 'Select', value: 1 },
      { key: 2, text: current.getFullYear() - 1, value: current.getFullYear() - 1 },
      { key: 3, text: current.getFullYear(), value: current.getFullYear() },
      { key: 4, text: current.getFullYear() + 1, value: current.getFullYear() + 1 },
    ]
  }
  state = {
    LeavesList: [],
    Years: [],
    Year: new Date().getFullYear(),
    isLeavesLoading: false,
    isLoggedIn: false,
    showModal1: false,
    showModal2: false,
    comments: '',
    accepted: false,
    disable: '',
    reqid: '',
    currentPage: 1,
    entriesPerPage: 10
  }


  handleDownload = async (fileName) => {
    const Auth = this.context
    const user = Auth.getUser()
    iipeerpapi.fileDownload(user, fileName)
      .then(response => {
        const filename = response.headers['content-disposition'].split('filename=')[1];
        const contenttype = response.headers['content-type'];
        const blob = new Blob([response.data]);
        //const blob = new Blob([response.data]);
        //blob.lastModifiedDate = new Date();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        handleLogError(error)
      })
      .finally(() => {

      })
  };

  renderAttachmentButton = () => {
    const { selectedLeave } = this.state;
    const attachmentDetails = selectedLeave.attachements_details;
    if (attachmentDetails) {
      return (
        <Button
          size='large'
          content='Download Attachment'
          icon='download'
          onClick={() => this.handleDownload(attachmentDetails)}
        />
      );
    } else {
      return (
        <p>No attachment available</p>
      );
    }
  };

  handlePageChange = (event, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  sortLeavesByUsername = (LeavesList, username) => {
    return LeavesList.slice().sort((a, b) => {
      const aApprovals = a.leaveApprovals.filter(approve => approve.username === username);
      const bApprovals = b.leaveApprovals.filter(approve => approve.username === username);
  
      if (aApprovals.length === 0 && bApprovals.length === 0) {
        return 0;
      }
  
      if (aApprovals.length === 0) {
        return 1;
      }
  
      if (bApprovals.length === 0) {
        return -1;
      }
  
      const statusOrder = {
        PENDING: 0,
        // APPROVED: 1,
        // REJECTED: 2,
      };
  
      const statusComparison = (aApprovals.some(approval => approval.status === 'PENDING')?0:1) - (bApprovals.some(approval => approval.status === 'PENDING')?0:1);
      if (statusComparison !== 0) {
        return statusComparison;
      }
  
      // Within PENDING, APPROVED, and REJECTED statuses, sort based on specific criteria
      if (aApprovals.some(approval => approval.status === 'PENDING')) {
        // Add your specific time-related criteria here
        const aTimeCriteria = a.leavecancellationtime || a.leaveapplied_time || a.cancellationtime;
        const bTimeCriteria = b.leavecancellationtime || b.leaveapplied_time || b.cancellationtime;
  
        // Customize this comparison based on your criteria
        const timeComparison = new Date(aTimeCriteria) - new Date(bTimeCriteria);
        if (timeComparison !== 0) {
          return timeComparison;
        }
      }
      if (aApprovals.some(approval => approval.status !== 'PENDING')) {
        // Add your specific time-related criteria here
        const aTimeCriteria = a.leavecancellationtime || a.leaveapplied_time || a.cancellationtime;
        const bTimeCriteria = b.leavecancellationtime || b.leaveapplied_time || b.cancellationtime;
  
        // Customize this comparison based on your criteria
        const timeComparison = new Date(aTimeCriteria) - new Date(bTimeCriteria);
        if (timeComparison !== 0) {
          return timeComparison;
        }
      }
  
      return 0; // If everything is equal, return 0
    });
  };

  renderTableRows = () => {
    const { currentPage, entriesPerPage } = this.state;
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    const { LeavesList, Year, isLoggedIn } = this.state
    const Auth = this.context
    const user = Auth.getUser()
    const username = user ? user.data.preferred_username : '';
    //const sortedLeaves = LeavesList.slice().sort((a, b) => new Date(b.leaveapplied_date) - new Date(a.leaveapplied_date));
    const sortedLeaves = this.sortLeavesByUsername(LeavesList, username);
    const displayedLeaves = sortedLeaves.slice(startIndex, endIndex);
    
    let newcounter=1000;
    if (displayedLeaves.length === 0) {
      return (
        <Table.Row key={newcounter++}>
          <Table.Cell collapsing textAlign='center' colSpan='8'>No Leaves Are Found For Your Approval</Table.Cell>
        </Table.Row>
      );
    } else {
      let counter = 1;
      return displayedLeaves.map((Leave,index) => {
        const leaveApprovals = Leave.leaveApprovals.filter(approve => approve.username === username);

        return (
          <Table.Row textAlign='center' key={index}>
            <Table.Cell>{startIndex + (counter++) }</Table.Cell>
            <Table.Cell>{(Leave.isoldleave==null||Leave.isoldleave===false)?'Latest':'Older'}</Table.Cell>
            <Table.Cell>{Leave.leavetype}</Table.Cell>
            <Table.Cell>{Leave.userDetailsDTO.fullname}</Table.Cell>
            <Table.Cell>{Leave.leavetype==='SL'|| Leave.leavetype==='LD'? 'NA': (<><b>{Leave.appliedDuration} </b>Day(s)</>)}</Table.Cell>
            <Table.Cell>
              {datetimeutils.formatDateToDate(Leave.start_date)}
            </Table.Cell>
            <Table.Cell>{datetimeutils.formatDateToDate(Leave.end_date)}</Table.Cell>
            <Table.Cell>{datetimeutils.formatDateTimeToDateTime(Leave.leaveapplied_date)} </Table.Cell>
            <Table.Cell>
              {
                (leaveApprovals.filter(approval=> approval.approvaltype==='CANCELLED').length>0?leaveApprovals.filter(approval=> approval.approvaltype==='CANCELLED'):
                leaveApprovals.filter(approval=> approval.approvaltype==='APPLIED')).map((approval,index)   => (
                  <Table.Row  key={index}>
                    <Table.Cell className={this.handleStatusColor(this.handleGetCurrentStatus(Leave))}>
                      <Icon name={this.getClassIcon(this.handleStatusColor(this.handleGetCurrentStatus(Leave)))} />
                      {this.handleGetCurrentStatus(Leave)}
                    </Table.Cell>
                  </Table.Row>
                ))
              }
            </Table.Cell>
            <Table.Cell>
              {
                (leaveApprovals.filter(approval=> approval.approvaltype==='CANCELLED').length>0?leaveApprovals.filter(approval=> approval.approvaltype==='CANCELLED'):
                leaveApprovals.filter(approval=> approval.approvaltype==='APPLIED')).map((approval,index) => (
                  <Table.Row key={index}>
                    
                    <Table.Cell>
                      <Button
                        color='blue'
                        size='small'
                        onClick={() => this.handleModal1Open(Leave, approval.status, approval.uid)}
                      // icon='trash'
                      // disabled={user.username === 'admin'}
                      // onClick={() => handleDeleteUser(user.username)}
                      >VIEW<Icon name="right arrow" /></Button>
                    </Table.Cell>
                  </Table.Row>

                ))
              }
            </Table.Cell>
          </Table.Row>
        )
      })
    }

  }
  renderPaginationControls = () => {
    const { currentPage, entriesPerPage, LeavesList } = this.state;
    const totalPages = Math.ceil(LeavesList.length / entriesPerPage);

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  };
  acceptApproval = () => {
    this.setState({
      accepted: true,
      showModal1: false

    })
  }

  rejectApproval = () => {
    this.setState({
      accepted: false,
      showModal2: true
    })
  }
  componentDidMount() {
    this.handleGetLeaves()
  }
  getClassColor = (color) => {
    if (color.includes("APPLIED") || color.includes("PENDING") || color.includes("SUBMITTED")) {
      return 'warning'
    }
    else if (color.includes("REJECTED")) {
      return 'negative'
    }
    else if (color === "") {
      return ''
    }
    else {
      return 'positive'
    }
  }

  handleGetLeaves() {
    const Auth = this.context
    const user = Auth.getUser()

    this.setState({ is: true })
    iipeerpapi.getApprovalLeaves(user)
      .then(response => {
        this.setState({ LeavesList: response.data })
      })
      .catch(error => {
        handleLogError(error)
      })
      .finally(() => {
        this.setState({ isLeavesLoading: false })
      })
  }

  handleInputChange = (e, { id, value }) => {
    this.setState({ [id]: value })
  }

  approveorreject = { status: '' };

  handleStatusColor(color) {
    color.toUpperCase()
    if (color.includes("PENDING") || color.includes("SUBMITTED") || color.includes("APPLIED")) {
      return "warning"
    }
    else if (color.includes("REJECTED")) {
      return "negative"
    }
    else if (color === "") {
      return '';
    }
    else {
      return "positive"
    }
  }
  getClassIcon = (details) => {
    if (details === "positive") {
      return 'checkmark'
    }
    else if (details === "negative") {
      return 'close'
    }
    else {
      return 'attention'
    }
  }

  handleModal1Open = (leave, isDisabled, userId) => {
    const approvalIDs = leave.leaveApprovals.map((approval,index) => ({
      key:index,
      uid: approval.uid,
      username: approval.username
    }))
    if (isDisabled === "APPROVED" || isDisabled === "REJECTED" || isDisabled === "") {
      isDisabled = true;
    }
    else {
      isDisabled = false;
    }
    this.setState({
      selectedLeave: leave,
      showModal1: true,
      approvalIDs: approvalIDs,
      disable: isDisabled,
      reqid: userId
    });
  };

  handleGetCurrentStatus = (leave) => {
    const Auth = this.context
    const user = Auth.getUser()

    const currentusername = user ? user.data.preferred_username:''
    const cancelapprovals = leave.leaveApprovals.filter(approval=> approval.approvaltype==='CANCELLED' && approval.username===currentusername)
    const appliedapprovals = leave.leaveApprovals.filter(approval=> approval.approvaltype==='APPLIED' && approval.username===currentusername)
    if(cancelapprovals.length==1 ){
      if (cancelapprovals[0].status===''){
        return appliedapprovals[0].status
      }
      else{
        return cancelapprovals[0].status
      }
    }
    else{
      return appliedapprovals[0].status
    }
  };

  handleDate(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    return req_date.toLocaleDateString()
    //return req_date.toISOString().substring(0, 10)
  }

  handleTimeOld(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    // const timeComponent = req_date.toISOString().substring(11, 16);
    const timeComponent = req_date.toLocaleTimeString();
    const [hours, minutes] = timeComponent.split(":");
    let period = "am";
    let hours12 = parseInt(hours, 10);

    if (hours12 === 0) {
      hours12 = 12;
    } else if (hours12 >= 12) {
      period = "pm";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }
    return `${hours12}:${minutes} ${period}`;
  }

  handleTime(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    //const timeComponent = req_date.toISOString().substring(11, 16);
    const timeComponent = req_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return timeComponent;
  }

  handleAPICall = () => {
    const { selectedLeave, approvalIDs, comments, reqid } = this.state;
    const Auth = this.context
    const user = Auth.getUser()
    const username = user ? user.data.preferred_username : '';
    const requiredId = approvalIDs.filter(approval => approval.uid === reqid);
    const payLoad = {
      "comments": comments,
      "status": this.approveorreject.status === 'APPROVED' ? "APPROVED" : "REJECTED"
    };
    iipeerpapi.updateApproval(user, payLoad, reqid)
      .then(response => {
        const resp = response.data;
        this.setState({ approvalResponse: response.data })
        if (resp.status === "APPROVED") {
          if(resp.approvaltype === 'CANCELLED'){
            Toasts.notifySuccess("Leave Cancellation Approved Successfully");
          }
          else if(resp.approvaltype === "APPLIED"){
            Toasts.notifySuccess("Leave Approved Successfully");
          }
        }
        else {
          if(resp.approvaltype === 'CANCELLED'){
            Toasts.notifySuccess("Leave Cancellation Rejected Successfully");
          }
          else if(resp.approvaltype === "APPLIED"){
            Toasts.notifySuccess("Leave Rejected Successfully");
          }

        }
        this.handleGetLeaves()
      })
      .catch(error => {
        Toasts.notifyError("Something Went Wrong!")
      })
    this.handleModal1Close();
  }
  handleModal1Close = () => {
    this.setState({
      selectedLeave: null,
      showModal1: false,
      approvalId: [],
      comments: '',
      showModal1: false,
      approvalId: [],
      comments: ''
    })
  }
  formatDate = (dateString) => {
    if(dateString==null){
      return '';
    }
    const date = new Date(dateString)
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear();
    return `${day}-${month}-${year}`
  }
  handleName = (name) => {
    if (name === "CL") {
      return "Casual Leave";
    }
    else if (name === "HPL") {
      return "HalfPay Leave";
    }
    else if (name === "EL") {
      return "Earned Leave";
    }
    else if (name === "VL") {
      return "Vacation Leave"
    }
    else if (name === "SCL") {
      return "Special Casual Leave";
    }
    else if (name === "RH") {
      return "Restricted Holiday";
    }
    else if (name === "PROL") {
      return "Project Leave";
    }
    else if (name === "COL") {
      return "Commuted Leave";
    }
    else if (name === "SL") {
      return "Station Leave";
    }
    else if (name === "LD") {
      return "Leave on Duty";
    }
    else {
      return "No Leave Found"
    }
  };

  ModalExampleModal = () => {
    //const [open, setOpen] = React.useState(false)
    const { selectedLeave, disable } = this.state;
    const officialDutyData = selectedLeave?.additionalfields?.officialdutydata?.fieldsandvalues || {};
    if (!selectedLeave) {
      return null
    }
    return (
      <>
        <Modal
          closeIcon
          open={this.state.showModal1}
          onClose={this.handleModal1Close}
          size='fullscreen'
        // onOpen={() => setOpen(true)}
        // trigger={<Button>Show Modal</Button>}
        >
          <Header textAlign='center' as='h2' style={{ backgroundColor: 'rgb(242, 242, 272)' }}>Leave Details</Header>
          <Modal.Content image scrolling>

            {/* <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='small' circular wrapped /> */}

            <Modal.Description>

              <Modal.Content>
                <Container>
       
                <>
                  <br />
                  <LeaveEditHistoryTable
                    showApprovals={true}
                    leaveid={selectedLeave?.leaveid}
                    fromleaveviewmodal={false}
                  />
                  <br />
                </>
              

                <Segment.Group horizontal>
                  <Segment><b>Applied Person Name: </b></Segment>
                  <Segment>
                    {
                      selectedLeave.userDetailsDTO.fullname
                    }
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment><b>Leave Type: </b></Segment>
                  <Segment>
                    {
                      this.handleName(selectedLeave.leavetype) + " " + "(" + selectedLeave.leavetype + ")"
                    }
                  </Segment>
                </Segment.Group>
                <Segment.Group horizontal>
                  <Segment><b>Leave Status:</b></Segment>
                  <Segment>
                    {
                      selectedLeave.leave_status
                    }
                  </Segment>
                </Segment.Group>
                {selectedLeave.leavetype !== 'LD' &&
                <Segment.Group horizontal>
                  <Segment><b>Start Date: </b></Segment>
                  <Segment>
                    {
                      datetimeutils.formatDateToDate(selectedLeave.start_date)
                    }
                    {
                      selectedLeave.startdaysession
                    }
                  </Segment>
                  <Segment><b>End Date: </b></Segment>
                  <Segment>
                    {
                      datetimeutils.formatDateToDate(selectedLeave.end_date)
                    } {
                      selectedLeave.lastdaysession
                    }
                  </Segment>
                </Segment.Group>}
                {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Grid columns='equal'>
                  <Grid.Row>
                    <Grid.Column>
                      <Table compact striped celled selectable>
                        <Table.Header>
                          <Table.Row textAlign='center'>
                            <Table.HeaderCell >Prefix</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                            (selectedLeave.prefixes !== null && selectedLeave.prefixes.length !== 0) ? (selectedLeave.prefixes && selectedLeave.prefixes.map((pref,index) => {
                              return <>
                                <Table.Row textAlign='center' key={index}>
                                  <Table.Cell>{pref}</Table.Cell>
                                </Table.Row>
                              </>
                            })) : (<Table.Cell textAlign='center'>No Prefixes Found</Table.Cell>)
                          }
                        </Table.Body>
                      </Table>
                    </Grid.Column>
                    <Grid.Column>
                      <Table compact striped celled selectable>
                        <Table.Header>
                          <Table.Row textAlign='center'>
                            <Table.HeaderCell >Suffix</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {
                            (selectedLeave.suffixes !== null && selectedLeave.suffixes.length !== 0) ? (selectedLeave.suffixes && selectedLeave.suffixes.map((suff,index) => {
                              return <>
                                <Table.Row textAlign='center' key={index}>
                                  <Table.Cell>{suff}</Table.Cell>
                                </Table.Row>
                              </>
                            })) : (<Table.Cell textAlign='center'>No Suffixes Found</Table.Cell>)
                          }
                        </Table.Body>

                      </Table>

                    </Grid.Column>
                  </Grid.Row>

                </Grid>}
                {selectedLeave.leavetype !== 'SL' &&  selectedLeave.leavetype !== 'LD' &&
                <Segment.Group horizontal>
                  <Segment><b>Saturday/Sunday: </b></Segment>
                  <Segment>{selectedLeave.satandsundays}</Segment>
                  <Segment><b>National Holidays: </b></Segment>
                  <Segment>{selectedLeave.holidays}</Segment>
                </Segment.Group>}
                {selectedLeave.leavetype !== 'SL' &&  selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                  <Segment><b> Applied Leaves  : </b></Segment>
                  <Segment>{selectedLeave.appliedDuration}</Segment>
                  <Segment><b>Effective Leaves: </b></Segment>
                  <Segment>{(selectedLeave.effectiveleaves === null) ? (0) : (selectedLeave.effectiveleaves)}</Segment>
                </Segment.Group>}
                <br></br>
                <Grid columns='equal' stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h5' dividing>Out of Station Start Time: </Header>
                      <Segment.Group horizontal >
                        <Segment><b> Date  : </b></Segment>
                        <Segment>{
                          selectedLeave.outofstationstartdate==null?'NA':datetimeutils.formatDateTimeToDate(selectedLeave.outofstationstartdate)
                        }</Segment>
                        <Segment><b> Time : </b></Segment>
                        <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationstartdate)}</Segment>
                      </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                      <Header as='h5' dividing>Out of Station End Time: </Header>
                      <Segment.Group horizontal>
                        <Segment><b> Date  : </b></Segment>
                        <Segment>{selectedLeave.outofstationenddate==null?'NA':datetimeutils.formatDateTimeToDate(selectedLeave.outofstationenddate)}</Segment>
                        <Segment><b> Time : </b></Segment>
                        <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationenddate)}</Segment>
                      </Segment.Group>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Header as='h5'>Official Tour Details: </Header>
                <Table celled stackable textAlign='center'>

                  <Table.Body>
                  <Table.Row>
                      <Table.Cell><b>Start Date Of Official Engagement</b></Table.Cell>
                      <Table.Cell >{officialDutyData.LDStartDate!=null?(datetimeutils.formatDateToDate(officialDutyData.LDStartDate)+' '+  (officialDutyData.ldstartdaysession) || 'NA'):'NA'}</Table.Cell>
                      <Table.Cell><b>End Date Of Official Engagement</b></Table.Cell>
                      <Table.Cell >{officialDutyData.LDStartDate!=null?(datetimeutils.formatDateToDate(officialDutyData.LDEndDate)+' '+  (officialDutyData.ldlastdaysession) || 'NA'):'NA'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Places to be visited</b></Table.Cell>
                      <Table.Cell >{officialDutyData.placestobevisited || 'NA'}</Table.Cell>


                      <Table.Cell><b>Purpose of visit</b></Table.Cell>
                      <Table.Cell>{officialDutyData.purposeofvisit || 'NA'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Estimated Budget</b></Table.Cell>
                      <Table.Cell>{officialDutyData.estimatedbudget || 'NA'}</Table.Cell>
                      <Table.Cell><b>Budget Head from which<br />expenditure is to be met</b></Table.Cell>
                      <Table.Cell>{officialDutyData.budgethead || 'NA'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Mode of Journey</b></Table.Cell>
                      <Table.Cell>{officialDutyData.modeofjourney || 'NA'}</Table.Cell>
                      <Table.Cell><b>Class of Journey</b></Table.Cell>
                      <Table.Cell>{officialDutyData.classofjourney || 'NA'}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <h4>Applicant Comments : </h4>
                <Segment padded='very'>
                  {
                    selectedLeave.comments ? (selectedLeave.comments) : ("No Comments Mentioned")
                  }
                </Segment>
                {/* <h4>Address : </h4>
                <Segment style={{ wordBreak: 'break-word' }} className="ui modal-content" padded='very'>{
                  selectedLeave.addressduringleave
                }</Segment> */}
                <Grid columns='equal' stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h5' >Address : </Header>
                      <Segment.Group horizontal >
                        <Segment>{selectedLeave.addressduringleave}</Segment>
                      </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                    <Header as='h5' >Availing LTC?: </Header>
                      <Segment.Group horizontal >
                        <Segment>{selectedLeave.isavailingltc?'Yes':'No'}</Segment>
                      </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                    <Header as='h5' >Encashed Leaves: </Header>
                    <Segment.Group horizontal >
                      <Segment>{selectedLeave.isavailingltc&&selectedLeave.encashedleaves!=null ? selectedLeave.encashedleaves : 'NA'}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Grid columns='equal' stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as='h5' dividing>Phone Number: </Header>
                      <Segment.Group horizontal >
                        <Segment>{selectedLeave.phonenoduringleave}</Segment>
                      </Segment.Group>
                    </Grid.Column>
                    <Grid.Column>
                      <Header as='h5' dividing>Attachement: </Header>
                      {this.renderAttachmentButton()}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <h4>Remarks By Esablishment if any : </h4>
                <Segment padded='very'>{selectedLeave.remarks==null ? ('NA') : (selectedLeave.remarks)}</Segment>
                <h3>Delegations</h3>
                <Table compact striped celled selectable>
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                      <Table.HeaderCell width={3}>Nature of Work</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Start Day</Table.HeaderCell>
                      <Table.HeaderCell width={2}>End Day</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      selectedLeave.leaveDelegations && selectedLeave.leaveDelegations.length > 0 ? (
                      selectedLeave.leaveDelegations && selectedLeave.leaveDelegations.
                      sort( (p1, p2) => (p1.delegationsequence > p2.delegationsequence) ? 1 : (p1.delegationsequence < p2.delegationsequence) ? -1 : 0)
                      .map((delegations, index) => {
                        return <>
                          <Table.Row textAlign='center' key={index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{delegations.userDetailsDTO.fullname}</Table.Cell>
                            <Table.Cell>{delegations.natureofwork}</Table.Cell>
                            <Table.Cell>{datetimeutils.formatDateToDate(delegations.startday)}</Table.Cell>
                            <Table.Cell>{datetimeutils.formatDateToDate(delegations.lastday)}</Table.Cell>
                            <Table.Cell>{delegations.comments}</Table.Cell>
                            <Table.Cell className={this.handleStatusColor(delegations.status)}>{delegations.status}</Table.Cell>
                            <Table.Cell >{datetimeutils.formatDateTimeToDateTime(delegations.updatedtime)}</Table.Cell>
                          </Table.Row>
                        </>
                      })):(
                        
                      <Table.Row>
      <Table.Cell collapsing textAlign='center' colSpan='8'>No Delegations Are Found.</Table.Cell>
    </Table.Row>
    
    )}
                  </Table.Body>
                </Table>
                <h3>Approvals</h3>
                <Table compact striped celled selectable>
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {
                      Object.values(selectedLeave.leaveApprovals != null ? selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'APPLIED') : false) ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'APPLIED')
                        .sort( (p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                        .map((Approvals,index) => {
                        return <>
                          <Table.Row textAlign='center' key={index}>
                            <Table.Cell>{Approvals.approversequence}</Table.Cell>
                            <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                            <Table.Cell className={this.handleStatusColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                            <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments
                            )}</Table.Cell>
                            <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                          </Table.Row>
                        </>
                      })) : ("No Details Available")
                    }
                  </Table.Body>
                </Table>
                {(selectedLeave.leaveApprovals != null ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'CANCELLED').length > 0 ? true : false) : false) &&
                  <>
                    
                    <h3>Leave Cancellation Approvals</h3>

                    <Segment.Group horizontal>
                      <Segment ><b>Cancellation Initiation Time: </b></Segment>
                      <Segment>
                        {datetimeutils.formatDateTimeToDateTime(selectedLeave.cancelinittime)}
                      </Segment>
                    </Segment.Group>
                    <Segment.Group horizontal>
                      <Segment ><b>Reason for Cancellation Request: </b></Segment>
                      <Segment>
                        {selectedLeave.cancelinitncomments}
                      </Segment>
                    </Segment.Group>
                    <Table compact striped celled selectable>
                      <Table.Header>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                          <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                          <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                          <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          Object.values(selectedLeave.leaveApprovals != null ? selectedLeave.leaveApprovals.filter(t => (t.approvaltype === 'CANCELLED').length) > 0 : false) ? (selectedLeave.leaveApprovals.filter(t => t.approvaltype === 'CANCELLED')
                            .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                            .map((Approvals, index) => {
                              return <>

                                <Table.Row textAlign='center' key={index}>
                                  <Table.Cell>{Approvals.approversequence}</Table.Cell>
                                  <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                                  <Table.Cell className={this.getClassColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                                  <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments)}</Table.Cell>
                                  <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                                </Table.Row>
                              </>
                            })) : ("No Details Available")
                        }

                      </Table.Body>
                    </Table>
                  </>
                }
                <br />
                </Container>
              </Modal.Content>
            </Modal.Description>
          </Modal.Content>

          <Modal.Actions>
            <Form onSubmit={() => { this.handleAPICall() }}>
              <Form.TextArea disabled={disable} rows={4} label='Comments' placeholder='Enter your Comments' required
                id='comments'
                // value={this.state.comments}
                onChange={this.handleInputChange} />
              <Button disabled={disable} floated="left" type="submit" labelPosition='right' content='Reject' icon='close' negative
                onClick={() => { this.approveorreject.status = 'REJECTED' }}></Button>
              <Button disabled={disable} type="submit" content="Approve / Recommend" labelPosition='right' icon='checkmark' positive
                onClick={() => { this.approveorreject.status = 'APPROVED' }}></Button>
            </Form>
          </Modal.Actions>
        </Modal >
      </>

    )
  }

  render() {


    return (
      <>

        <Grid stackable >
          <Grid.Row columns={3} centered>
            <Grid.Column >
              {/* <Form onSubmit={handleSearchOrder}> */}
              {/* <Form >
             
                <Form.Field inline>
                  <label>Year</label>
                  <Dropdown
                    closeOnEscape
                
                    id='Year'
                    selection
                    options={this.getYears()}
                    defaultValue={this.getYears()[1].value}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              
              </Form> */}
            </Grid.Column>

          </Grid.Row>
        </Grid>
        <Table compact striped celled selectable>
        <this.ModalExampleModal />
          <Table.Header>
            {/* <this.ModalExampleModal /> */}
            <Table.Row textAlign='center'>
              <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
              <Table.HeaderCell width={1}>Version</Table.HeaderCell>
              <Table.HeaderCell width={2}>Leave Type</Table.HeaderCell>
              <Table.HeaderCell width={3}>Applied Person</Table.HeaderCell>
              <Table.HeaderCell width={2}>Applied Duration</Table.HeaderCell>
              <Table.HeaderCell width={2}>Leave Start Date</Table.HeaderCell>
              <Table.HeaderCell width={2}>Leave End Date</Table.HeaderCell>
              <Table.HeaderCell width={2}>Applied Date </Table.HeaderCell>
              <Table.HeaderCell width={3}>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderTableRows()}
          </Table.Body>
        </Table>
        {this.renderPaginationControls()}
      </>
    )
  }

}

export default LeaveApprovals