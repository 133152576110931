import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import datetimeutils from "../utils/DateTimeUtils";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Slide } from "react-toastify";
import Toasts from '../misc/Toasts'
import { Grid, Modal, Table, Divider, Header,Input,Radio, Form, Button, Pagination, Dropdown, Segment, Icon,Container} from 'semantic-ui-react'
import React, { useContext, useEffect, useState } from 'react';


const FileAttachmentUtils = {
    handleFileUpload,
    handleDownload,
    renderAttachmentButton,

}


    async function  handleFileUpload (user) {
        // e.preventDefault()
      
        let fileId
        const formData = new FormData();
        formData.append('file', this.state.file);
        // formData.append('fileName', file.name);
        await iipeerpapi.fileUpload(user, formData)
          .then(response => {
            this.setState({ fileId: response.fileId })
            return new Promise(resolve => {
              resolve(response.fileId);
    
            });
          })
          .catch(error => {
            handleLogError(error)
          })
          .finally(() => {
            //this.setState({ isLeavesLoading: false })
          })
      }

      async function  handleDownload (fileName,user)  {

        iipeerpapi.fileDownload(user, fileName)
          .then(response => {
            const filename = response.headers['content-disposition'].split('filename=')[1];
            const contenttype = response.headers['content-type'];
            const blob = new Blob([response.data]);
            //const blob = new Blob([response.data]);
            //blob.lastModifiedDate = new Date();
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
    
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
          .catch(error => {
            handleLogError(error);
            Toasts.notifyError("Error in downloading file!")
          })
          .finally(() => {
    
          })
      };
      function renderAttachmentButton  (attachmentDetails,user) {
        //const { selectedLeave } = this.state;
        if (attachmentDetails) {
          return (
            <Button
              size='small'
              content='Download Attachment'
              icon='download'
              onClick={() => handleDownload(attachmentDetails,user)}
            />
          );
        } else {
          return (
            <p>No attachment available</p>
          );
        }
      };


export default FileAttachmentUtils;