import React, { Fragment, Component } from 'react'

import { Grid, Form, Button, Input, Table, Header ,Container} from 'semantic-ui-react'
import { Dropdown, Segment, Image, Modal, Icon, Pagination } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import "./lms.css";
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import { useAuth } from '../context/AuthContext'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Slide } from "react-toastify";
import Toasts from '../misc/Toasts'
import moment from 'moment/moment';
import datetimeutils from "../utils/DateTimeUtils";
import LeaveEditHistoryTable from './LeaveEditHistoryTable';


class LeaveDelegations extends Component {
  static contextType = AuthContext

  getYears() {
    const current = new Date()

    return [
      // { key: 1, text: 'Select', value: 1 },
      { key: 2, text: current.getFullYear() - 1, value: current.getFullYear() - 1 },
      { key: 3, text: current.getFullYear(), value: current.getFullYear() },
      { key: 4, text: current.getFullYear() + 1, value: current.getFullYear() + 1 },
    ]
  }
  state = {
    LeavesList: [],
    Years: [],
    Year: new Date().getFullYear(),
    isLeavesLoading: false,
    isLoggedIn: false,
    showModal: false,
    delegationId: [],
    comments: '',
    status: 'pending...',
    disable: '',
    reqId: '',
    currentPage: 1,
    entriesPerPage: 10
  }

  approveorreject = { status: '' };

  handlePageChange = (event, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  renderTableRows = () => {
    const { currentPage, entriesPerPage } = this.state;
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    const { LeavesList, Year, isLoggedIn } = this.state;
    let LeavesListTemp;
    const Auth = this.context;
    const user = Auth.getUser();
    const username = user ? user.data.preferred_username : '';

    // Sort the LeavesList by delegation startday in descending order (recent first)
    const sortedLeaves = LeavesList.slice().sort((a, b) => new Date(b.leaveapplied_date) - new Date(a.leaveapplied_date));
    const displayedLeaves = sortedLeaves.slice(startIndex, endIndex);
    if (displayedLeaves.length === 0) {
      return (
        <Table.Row key='1122332'>
          <Table.Cell collapsing textAlign='center' colSpan='8'>No Delegation Leaves Are Found</Table.Cell>
        </Table.Row>
      );
    } else {
      let counter = 1;
      let newcounter = 1;
      return displayedLeaves.map((Leave, index) => {
        const delegationStatus = Leave.leaveDelegations.filter(delegation => delegation.username === username);

        const isDisabled = '';
        let i = 1;
        return (
          delegationStatus.map((delegation, index) => {

            return (

              <Table.Row textAlign='center' key={index}>
                {(i++ === 1) &&
                  <>

                    <Table.Cell rowSpan={delegationStatus.length}>{startIndex + (counter++)} </Table.Cell>
                    <Table.Cell rowSpan={delegationStatus.length}>{this.handleName(Leave.leavetype) + " " + "(" + Leave.leavetype + ")"}</Table.Cell>
                    <Table.Cell rowSpan={delegationStatus.length}>{Leave.userDetailsDTO.fullname}</Table.Cell>
                    <Table.Cell rowSpan={delegationStatus.length}>{(Leave.leavetype==='SL'|| Leave.leavetype==='LD')? 'NA': (<><b>{Leave.effectiveleaves} </b>Day(s)</>)}</Table.Cell>
                    <Table.Cell rowSpan={delegationStatus.length}>{datetimeutils.formatDateTimeToDateTime(Leave.leaveapplied_date)} </Table.Cell>
                  </>
                }

                <Table.Cell>
                  {datetimeutils.formatDateToDate(delegation.startday)}
                </Table.Cell>
                <Table.Cell>
                  {datetimeutils.formatDateToDate(delegation.lastday)}
                </Table.Cell>
                <Table.Cell className={this.handleStatusColor(this.handleStatus(delegation.status))}>
                  <Icon name={this.getClassIcon(this.handleStatus(delegation.status))} />
                  {this.handleStatus(delegation.status)}
                </Table.Cell>

                <Table.Cell>
                  {/* <this.ModalExampleModal /> */}
                  <Button
                    color='blue'
                    size='small'
                    onClick={() => this.handleModalOpen(Leave, delegation.status, delegation.uid)}
                  // icon='trash'
                  // disabled={user.username === 'admin'}
                  // onClick={() => handleDeleteUser(user.username)}
                  >VIEW<Icon name="right arrow" /></Button>
                </Table.Cell>
              </Table.Row>

            )

          })
        );
      });
    }
  };


  renderPaginationControls = () => {
    const { currentPage, entriesPerPage, LeavesList } = this.state;
    const totalPages = Math.ceil(LeavesList.length / entriesPerPage);

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  };

  componentDidMount() {
    this.handleGetLeaves()
  }

  handleGetLeaves() {
    const Auth = this.context
    const user = Auth.getUser()

    this.setState({ is: true })
    iipeerpapi.getDelegatedLeaves(user)
      .then(response => {
        this.setState({ LeavesList: response.data })
      })
      .catch(error => {
        handleLogError(error)
      })
      .finally(() => {
        this.setState({ isLeavesLoading: false })
      })
  }

  handleInputChange = (e, { id, value }) => {
    this.setState({ [id]: value })
  }

  handleModalOpen = (leave, isDisabled, userId) => {
    const delegationIds = leave.leaveDelegations.map((delegation) => ({
      uid: delegation.uid,
      username: delegation.username
    }))
    if (isDisabled === "ACCEPTED" || isDisabled === "REJECTED") {
      isDisabled = true
    }
    else {
      isDisabled = false
    }
    this.setState(() => ({
      selectedLeave: leave,
      showModal: true,
      delegationId: delegationIds,
      disable: isDisabled,
      reqId: userId
    }));
  };
  handleAPICall = () => {
    const { selectedLeave, delegationId, comments, reqId } = this.state;
    const Auth = this.context
    const user = Auth.getUser()
    const requiredId = delegationId.filter(delegation => delegation.uid === reqId);
    const payLoad = {
      "comments": comments,
      "status": this.approveorreject.status === 'ACCEPTED' ? "ACCEPTED" : "REJECTED"
    };
    iipeerpapi.updateDelegation(user, payLoad, reqId)
      .then(response => {
        const resp = response.data;
        if (resp.status == "ACCEPTED") {
          Toasts.notifySuccess("Delegation Accepted Successfully!")
        }
        else {
          Toasts.notifySuccess("Delegation Rejected Successfully!")
        }
        this.handleGetLeaves();
      })
      .catch(error => {
      })
    this.handleModalClose();
  }
  handleModalClose = () => {
    this.setState({
      selectedLeave: null,
      showModal: false,
      delegationId: [],
      comments: ''
    })
  }

  handleDate(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    // return req_date.toISOString().substring(0, 10)
    return req_date.toLocaleDateString()
  }

  handleTimeOld(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    //const timeComponent = req_date.toISOString().substring(11, 16);
    const timeComponent = req_date.toLocaleString()
    const [hours, minutes] = timeComponent.split(":");
    
    let period = "am";
    let hours12 = parseInt(hours, 10);

    if (hours12 === 0) {
      hours12 = 12;
    } else if (hours12 >= 12) {
      period = "pm";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }
    return `${hours12}:${minutes} ${period}`;
  }

  handleTime(date) {
    if (date == null) {
      return "NA"
    }
    const req_date = new Date(date);
    //const timeComponent = req_date.toISOString().substring(11, 16);
    const timeComponent = req_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return timeComponent;
  }

  findDuration(start, end) {
    const startdate = new Date(start);
    const enddate = new Date(end);
    const timeDiff = enddate.getTime() - startdate.getTime();
    const days = Math.ceil(timeDiff / (1000 * 3600 * 24))
    return days + 1
  }

  formatDate = (dateString) => {
    if (dateString==null){
      return ''
    }
    const date = new Date(dateString)
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear();
    return `${day}-${month}-${year}`
  }

  handleStatusColor(color) {
    color.toUpperCase()
    if (color.includes("PENDING") || color.includes("SUBMITTED") || color.includes("APPLIED")) {
      return "warning"
    }
    else if (color.includes("REJECTED")) {
      return "negative"
    }
    else if (color === '') {
      return '';
    }
    else {
      return "positive"
    }
  }
  handleName = (name) => {
    if (name === "CL") {
      return "Casual Leave";
    }
    else if (name === "HPL") {
      return "HalfPay Leave";
    }
    else if (name === "EL") {
      return "Earned Leave";
    }
    else if (name === "VL") {
      return "Vacation Leave"
    }
    else if (name === "SCL") {
      return "Special Casual Leave";
    }
    else if (name === "RH") {
      return "Restricted Holiday";
    }
    else if (name === "PROL") {
      return "Project Leave";
    }
    else if (name === "COL") {
      return "Commuted Leave";
    }
    else if (name === "SL") {
      return "Station Leave";
  }
  else if (name === "LD") {
    return "Leave on Duty";
  }
    else {
      return "No Leave Found"
    }
  };
  handleStatus(str) {
    if (str === null) {
      return "PENDING"
    }
    return str.toUpperCase()
  }
  getClassIcon = (details) => {
    if (details === "APPROVED" || details === "ACCEPTED") {
      return 'checkmark'
    }
    else if (details === "REJECTED") {
      return 'close'
    }
    else {
      return 'attention'
    }
  }

  ModalExampleModal = () => {

    const { selectedLeave, disable } = this.state;
    const officialDutyData = selectedLeave?.additionalfields?.officialdutydata?.fieldsandvalues || {};
    if (!selectedLeave) {
      return null
    }
    return (
      <Modal

        closeIcon
        open={this.state.showModal}
        onClose={this.handleModalClose}
        size='fullscreen'
      // onOpen={() => setOpen(true)}
      // trigger={<Button>Show Modal</Button>}
      >
        <Modal.Header>Leave Details</Modal.Header>
        <Modal.Content image scrolling>

          {/* <Image src='https://react.semantic-ui.com/images/wireframe/square-image.png' size='small' circular wrapped /> */}
          <Container>
          <Modal.Description>

            <Modal.Content>
            <LeaveEditHistoryTable
            leaveid={selectedLeave?.leaveid}
            fromleaveviewmodal={false}
        />
              <Segment.Group horizontal>
                <Segment><b>Applied Person Name: </b></Segment>
                <Segment>
                  {
                    selectedLeave.userDetailsDTO.fullname
                  }
                </Segment>
              </Segment.Group>
              <Segment.Group horizontal>
                <Segment><b>Leave Type: </b></Segment>
                <Segment>
                  {
                    this.handleName(selectedLeave.leavetype) + " " + "(" + selectedLeave.leavetype + ")"
                  }
                </Segment>
              </Segment.Group>


              {selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                <Segment><b>Start Date: </b></Segment>
                <Segment>{datetimeutils.formatDateToDate(selectedLeave.start_date)}  {selectedLeave.startdaysession}</Segment>
                <Segment><b>End Date: </b></Segment>
                <Segment>{datetimeutils.formatDateToDate(selectedLeave.end_date)}  {selectedLeave.lastdaysession}</Segment>
              </Segment.Group>}
              {selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD' && <Grid columns='equal'>
                <Grid.Row>
                  <Grid.Column>
                    <Table compact striped celled selectable>
                      <Table.Header>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell >Prefix</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          (selectedLeave.prefixes != null && selectedLeave.prefixes.length !== 0) ? (selectedLeave.prefixes && selectedLeave.prefixes.map((pref, index) => {
                            return <Fragment key={index} >
                              <Table.Row  textAlign='center' key={index}>
                                <Table.Cell>{pref}</Table.Cell>
                              </Table.Row>
                            </Fragment>
                          })) : <Fragment key="11222" >
                            <Table.Row  textAlign='center' key="1122">
                              <Table.Cell   textAlign='center'>No Prefixes Found</Table.Cell> </Table.Row>
                          </Fragment>
                        }
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column>
                    <Table compact striped celled selectable>
                      <Table.Header>
                        <Table.Row textAlign='center'>
                          <Table.HeaderCell >Suffix</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {
                          (selectedLeave.suffixes != null && selectedLeave.suffixes.length !== 0) ? (selectedLeave.suffixes && selectedLeave.suffixes.map((suff, index) => {
                            return <>
                              <Table.Row textAlign='center' key={index}>
                                <Table.Cell>{suff}</Table.Cell>
                              </Table.Row>
                            </>
                          })) : (<Table.Cell textAlign='center'>No Suffixes Found</Table.Cell>)
                        }
                      </Table.Body>

                    </Table>

                  </Grid.Column>
                </Grid.Row>

              </Grid>}
              {selectedLeave.leavetype !== 'SL' &&  selectedLeave.leavetype !== 'LD' && <Segment.Group horizontal>
                <Segment><b>Saturday/Sunday: </b></Segment>
                <Segment>{selectedLeave.satandsundays}</Segment>
                <Segment><b>National Holidays: </b></Segment>
                <Segment>{selectedLeave.holidays}</Segment>
              </Segment.Group>}
              {selectedLeave.leavetype !== 'SL' &&  selectedLeave.leavetype !== 'LD'  && <Segment.Group horizontal>
                <Segment><b> Applied Leaves  : </b></Segment>
                <Segment>{selectedLeave.appliedDuration}</Segment>
                <Segment><b>Effective Leaves: </b></Segment>
                <Segment>{(selectedLeave.effectiveleaves === null) ? (0) : (selectedLeave.effectiveleaves)}</Segment>
              </Segment.Group>}
              <br></br>
              <Grid columns='equal' stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header as='h5' dividing>Out of Station Start Time: </Header>
                    <Segment.Group horizontal >
                      <Segment><b> Date  : </b></Segment>
                      <Segment>{
                        selectedLeave.outofstationstartdate == null ? 'NA' : datetimeutils.formatDateTimeToDate(selectedLeave.outofstationstartdate)
                      }</Segment>
                      <Segment><b> Time : </b></Segment>
                      <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationstartdate)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h5' dividing>Out of Station End Time: </Header>
                    <Segment.Group horizontal>
                      <Segment><b> Date  : </b></Segment>
                      <Segment>{selectedLeave.outofstationenddate == null ? 'NA' : datetimeutils.formatDateTimeToDate(selectedLeave.outofstationenddate)}</Segment>
                      <Segment><b> Time : </b></Segment>
                      <Segment>{datetimeutils.formatDateTimeToTime(selectedLeave.outofstationenddate)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Header as='h5'>Official Tour Details: </Header>
                <Table celled stackable textAlign='center'>

                  <Table.Body>
                  <Table.Row>
                      <Table.Cell><b>Start Date Of Official Engagement</b></Table.Cell>
                      <Table.Cell >{ officialDutyData.LDStartDate!=null?(datetimeutils.formatDateToDate(officialDutyData.LDStartDate)+' '+  (officialDutyData.ldstartdaysession) || 'NA'):'NA'}</Table.Cell>
                      <Table.Cell><b>End Date Of Official Engagement</b></Table.Cell>
                      <Table.Cell >{officialDutyData.LDStartDate!=null?(datetimeutils.formatDateToDate(officialDutyData.LDEndDate)+' '+  (officialDutyData.ldlastdaysession) || 'NA'):'NA'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Places to be visited</b></Table.Cell>
                      <Table.Cell >{officialDutyData.placestobevisited || 'NA'}</Table.Cell>


                      <Table.Cell><b>Purpose of visit</b></Table.Cell>
                      <Table.Cell>{officialDutyData.purposeofvisit || 'NA'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Estimated Budget</b></Table.Cell>
                      <Table.Cell>{officialDutyData.estimatedbudget || 'NA'}</Table.Cell>
                      <Table.Cell><b>Budget Head from which<br />expenditure is to be met</b></Table.Cell>
                      <Table.Cell>{officialDutyData.budgethead || 'NA'}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell><b>Mode of Journey</b></Table.Cell>
                      <Table.Cell>{officialDutyData.modeofjourney || 'NA'}</Table.Cell>
                      <Table.Cell><b>Class of Journey</b></Table.Cell>
                      <Table.Cell>{officialDutyData.classofjourney || 'NA'}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              <h4>Comments : </h4>
              <Segment padded='very'>{selectedLeave.comments ? (selectedLeave.comments) : ("No Comments")}</Segment>
              <h4>Address : </h4>
              <Segment className="ui modal-content" padded='very'>{selectedLeave.addressduringleave}</Segment>
              <h4>Phone Number : </h4>
              <Segment padded>{selectedLeave.phonenoduringleave}</Segment>
              <h3>Delegations</h3>
              <Table compact striped celled selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Person name</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Nature of Work</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Start Day</Table.HeaderCell>
                    <Table.HeaderCell width={2}>End Day</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    selectedLeave.leaveDelegations && selectedLeave.leaveDelegations.
                      sort((p1, p2) => (p1.delegationsequence > p2.delegationsequence) ? 1 : (p1.delegationsequence < p2.delegationsequence) ? -1 : 0)
                      .map((delegations, index) => {
                        return <>
                          <Table.Row textAlign='center' key={index}>
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{delegations.userDetailsDTO.fullname}</Table.Cell>
                            <Table.Cell>{delegations.natureofwork}</Table.Cell>
                            <Table.Cell>{datetimeutils.formatDateToDate(delegations.startday)}</Table.Cell>
                            <Table.Cell>{datetimeutils.formatDateToDate(delegations.lastday)}</Table.Cell>
                            <Table.Cell>{delegations.comments}</Table.Cell>
                            <Table.Cell className={this.handleStatusColor(delegations.status)}>{this.handleStatus(delegations.status)}</Table.Cell>
                            <Table.Cell >{datetimeutils.formatDateTimeToDateTime(delegations.updatedtime)}</Table.Cell>
                          </Table.Row>
                        </>
                      })
                  }

                </Table.Body>
               </Table>
           {/*   <h3>Approvals</h3>
              <Table compact striped celled selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Approver name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={3}>Comments</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    Object.values(selectedLeave.leaveApprovals) ? (selectedLeave.leaveApprovals
                      .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                      .map((Approvals, index) => {
                        return <>
                          <Table.Row textAlign='center' key={index} >
                            <Table.Cell>{Approvals.approversequence}</Table.Cell>
                            <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                            <Table.Cell className={this.handleStatusColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                            <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments)}</Table.Cell>
                            <Table.Cell >{this.formatDate(Approvals.updatedtime)+' '+this.handleTime(Approvals.updatedtime)}</Table.Cell>

                          </Table.Row>
                        </>
                      })) : ("No Details Available")
                  }

                </Table.Body>
              </Table> */}
              <br/>
            </Modal.Content>
          </Modal.Description>
          </Container>
        </Modal.Content >
        <Modal.Actions>
          <Form onSubmit={() => { this.handleAPICall() }} textAlign="left">


            <Form.TextArea disabled={disable} label="Comments" placeholder='Enter your Comments' required
              id='comments'
              // value={this.state.comments}
              onChange={this.handleInputChange} />

            <Button disabled={disable} floated="left" type="submit" labelPosition='right' content='Reject' icon='close' negative
              onClick={() => { this.approveorreject.status = 'REJECTED' }}></Button>
            <Button disabled={disable} type="submit"

              content="Accept" labelPosition='right' icon='checkmark' positive value
              onClick={() => { this.approveorreject.status = 'ACCEPTED' }}></Button>
          </Form>
        </Modal.Actions>
      </Modal >
    )
  }
  render() {

    return (
      <>

        <Grid stackable >
          <Grid.Row columns={3} centered>
            <Grid.Column >
              {/* <Form onSubmit={handleSearchOrder}> */}
              {/* <Form >
             
                <Form.Field inline>
                  <label>Year</label>
                  <Dropdown
                    closeOnEscape
                
                    id='Year'
                    selection
                    options={this.getYears()}
                    defaultValue={this.getYears()[1].value}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              
              </Form> */}
            </Grid.Column>

          </Grid.Row>
        </Grid>
        <this.ModalExampleModal />
        <Table compact striped celled structured selectable>
          <Table.Header>

            <Table.Row textAlign='center'>
              <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
              <Table.HeaderCell width={2}>Leave Type</Table.HeaderCell>
              <Table.HeaderCell width={3}>Applied Person</Table.HeaderCell>
              
              <Table.HeaderCell width={1}>Leave Duration</Table.HeaderCell>
              <Table.HeaderCell width={3}>Applied Date </Table.HeaderCell>
              <Table.HeaderCell width={2}>Delegation Start Date</Table.HeaderCell>
              <Table.HeaderCell width={2}>Delegation End Date</Table.HeaderCell>

              <Table.HeaderCell width={2}>Status</Table.HeaderCell>
              <Table.HeaderCell width={1}></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body >
            {this.renderTableRows()}
          </Table.Body>
        </Table>
        {this.renderPaginationControls()}
      </>
    )
  }
}

export default LeaveDelegations
