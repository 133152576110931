import React, { useState, useEffect, useContext, useRef } from 'react';
import { Message, Divider, Dropdown, TextArea } from 'semantic-ui-react';
import { Container, Header, Icon, Button, Segment, Grid, Tab, Form, Input, Radio, GridRow } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import AuthContext from '../context/AuthContext';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import Toasts from '../misc/Toasts';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import datetimeutils from "../utils/DateTimeUtils";
import FileAttachmentUtils from '../utils/FileAttachmentUtils';
import LeaveApplyForm from './LeaveApplyForm.js';


const LeaveApply = ({ userdetails, users, HolidaysList, LeaveBalanceList, handleGetLeaveBalance, 
  handleLoadLeaveHistory,loadLeaveHistory,restrictedHolidays }) => {

  //const modalContentRef = useRef(null);


  const Auth = useContext(AuthContext);
  const [detailsstate, setDetailsState] = useState({
    userdetails,
    users,
    HolidaysList,
    LeaveBalanceList,
    handleGetLeaveBalance,
    orderDescription: '',
    orderTextSearch: '',
    userUsernameSearch: '',
    isAdmin: true,
    isUsersLoading: false,
    isOrdersLoading: false,
    isLoggedIn: false,
    isApprover: true,
    isLeaveBalanceLoading: false,
    inputLeaveStartDate: null,
    inputLeaveEndDate: null,
    LDStartDate: null,
    LDEndDate: null,
    startdaysession: 'FN',
    lastdaysession: 'AN',
    ldstartdaysession: 'FN',
    ldlastdaysession: 'AN',
    numDays: 0,
    numSundays: 0,
    numSaturdays: 0,
    inputLeaveTypeError: false,
    error: false,
    error2: false,
    error3: false,
    inputLeavetype: '',
    leavePayload: {},
    numLeaveAppliedDays: 0,
    numleaveEffectiveDays: 0,
    numNationalHolidays: 0,
    inputLeaveComments: '',
    addressduringleave: '',
    inputLeavePhone: '',
    inputLeaveOutOfStation: false,
    inputLeaveOutOfStationStartDate: null,
    inputLeaveOutOfStationEndDate: null,
    inputLeaveDelegations: [],
    leaveApprovals: [],
    disableoutofstationdates: true,
    delegation1username: '',
    delegation1natureofwork: '',
    Delegation1StartDate: null,
    Delegation1EndDate: null,
    delegation2username: '',
    delegation2natureofwork: '',
    Delegation2StartDate: null,
    Delegation2EndDate: null,
    delegation3username: '',
    delegation3natureofwork: '',
    Delegation3StartDate: null,
    Delegation3EndDate: null,
    selectedValue: null,
    applySuccess: false,
    attachmentid: null,
    file: null,
    fileId: '',
    fileName: '',
    inputLeaveOutOfStationError: false,
    inputLeaveOutOfStationStartDateError: false,
    inputLeaveOutOfStationEndDateError: false,
    inputLeaveCommentsError: false,
    inputLeaveAddressError: false,
    inputLeavePhoneError: false,
    inputLeavePhoneLengthError: false,
    inputLeaveDelegation1Error: false,
    inputLeaveDelegation2Error: false,
    inputLeaveDelegation3Error: false,
    fillAllFieldsError: false,
    leavePrefixes: [],
    leaveSuffixes: [],
    isstationLeave: false,
    applyingforltc: false,
    officialtour: false,
    disableofficialtour: false,
    outofstationnotproper: false,
    offcialengdatesnotproper: false
  });



  const authContext = useContext(AuthContext);
  const user = authContext.getUser();


  useEffect(() => {
    const user = Auth.getUser();
    const isAdmin = user.data.rol[0] === 'ADMIN';
    const isLoggedIn = Auth.userIsAuthenticated();
    setDetailsState(prevState => ({ ...prevState, isAdmin, isLoggedIn }));
  }, [Auth]);



 
return (
  
     <LeaveApplyForm 

    userdetails={userdetails}
    users={users}
    HolidaysList={HolidaysList}
    handleGetLeaveBalance={handleGetLeaveBalance}
    loadLeaveHistory={loadLeaveHistory}
    LeaveBalanceList={LeaveBalanceList}
    handleLoadLeaveHistory={handleLoadLeaveHistory}
    restrictedHolidays={restrictedHolidays}
    /> 
  
);
};

export default LeaveApply;