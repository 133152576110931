import React, { useState, useEffect, useContext } from 'react';
import { Table, Button, Header, Divider } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import datetimeutils from "../utils/DateTimeUtils";
import LeaveUtils from './LeaveUtils';
import LMSUtils from './LMSUtils';
import AuthContext from '../context/AuthContext';

const LeaveEditHistoryTable = ({ leaveid, fromleaveviewmodal, showApprovals }) => {
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLeaveId, setCurrentLeaveId] = useState(null);
  const Auth = useContext(AuthContext);

  useEffect(() => {
    handleGetLeaveedithistory(leaveid);
  }, [leaveid]);

  const handleGetLeaveedithistory = async (leaveid) => {
    if (!leaveid) {
      setLeaveDetails([]);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const user = Auth.getUser();
      iipeerpapi.getleaveEditHistoryWithApprovals(user, leaveid)
        .then(response => {
          setLeaveDetails(response.data);
        })
        .catch(error => {
          handleLogError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      handleLogError(error);
      setLoading(false);
    }
  };

  const handleLeaveid = (leaveid) => {
    setCurrentLeaveId(leaveid);
  };

  

  const sortedLeaveDetails = Array.isArray(leaveDetails)
    ? [...leaveDetails].sort((a, b) => a.leaveeditedversion - b.leaveeditedversion)
    : [];

    if (sortedLeaveDetails.length === 1) {
      return null;
    }

  return (
    <>
      <h3>Leave Edit History</h3>
      {loading ? (
        <Table celled>
          <Table.Row>
            <Table.Cell colSpan="10" textAlign="center">Loading...</Table.Cell>
          </Table.Row>
        </Table>
      ) : sortedLeaveDetails.length === 0 ? (
        <Table celled>
          <Table.Row>
            <Table.Cell colSpan="10" textAlign="center">No leave update history found</Table.Cell>
          </Table.Row>
        </Table>
      ) : (
        sortedLeaveDetails.map((leave, index) => (
          <React.Fragment key={leave.leaveid}>
            {/* Section header for each leave record */}
            <Header as="h4" dividing>
              Leave Edit Version #{leave.leaveeditedversion}
            </Header>

            <Table compact striped celled selectable>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Leave Type</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Start Date</Table.HeaderCell>
                  <Table.HeaderCell width={2}>End Date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Effective Leaves</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Working Days</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                  {/* <Table.HeaderCell width={1}>Edit Version</Table.HeaderCell> */}
                  <Table.HeaderCell width={2}>Applied Time</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row textAlign="center">
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>{leave.leavetype}</Table.Cell>
                  <Table.Cell>{datetimeutils.formatDateToDate(leave.start_date)} {leave.start_session}</Table.Cell>
                  <Table.Cell>{datetimeutils.formatDateToDate(leave.end_date)} {leave.end_session}</Table.Cell>
                  <Table.Cell>{leave.effectiveLeaves}</Table.Cell>
                  <Table.Cell>{leave.appliedDuration}</Table.Cell>
                  <Table.Cell className={LMSUtils.getClassColor(leave.status)}>{leave.status}</Table.Cell>
                  {/* <Table.Cell>{leave.leaveeditedversion}</Table.Cell> */}
                  <Table.Cell>
                    {datetimeutils.formatDateTimeToDateTime(
                      leave.leaveeditedversion === 1 ? leave.leaveappliedtime : leave.leaveeditedtime
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      fluid
                      disabled={leave.leaveid === leaveid}
                      onClick={() => { setCurrentLeaveId(leave.leaveid) }}
                    >
                      {leave.leaveid === leaveid ? 'Currently Viewing' : 'View Leave'}
                    </Button>
                  </Table.Cell>
                </Table.Row>

                {/* Display approvals if showApprovals is true */}
                {showApprovals && leave.leaveApprovals && (
                  <Table.Row>
                    <Table.Cell colSpan="10">
                      <h4>Approvals</h4>
                      <Table compact striped celled selectable>
                        <Table.Header>
                          <Table.Row textAlign="center">
                            <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Comments</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {leave.leaveApprovals
                            .filter(t => t.approvaltype === 'APPLIED')
                            .sort((p1, p2) => p1.approversequence - p2.approversequence)
                            .map((approver, idx) => (
                              <Table.Row textAlign="center" key={idx}>
                                <Table.Cell>{approver.approversequence}</Table.Cell>
                                <Table.Cell>{approver.username}</Table.Cell>
                                <Table.Cell className={LMSUtils.getClassColor(approver.status)}>
                                  {approver.status || '-'}</Table.Cell>
                                <Table.Cell>{approver.comments || '-'}</Table.Cell>
                                <Table.Cell>
                                  {datetimeutils.formatDateTimeToDateTime(approver.updatedtime)}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>

            {/* Divider to create space between each leave update record */}
            <Divider hidden />
          </React.Fragment>
        ))
      )}
      {currentLeaveId != null && (
        <LeaveUtils
          key={currentLeaveId}
          leaveId={currentLeaveId}
          handleLeaveid={handleLeaveid}
          fromleaveviewmodal={fromleaveviewmodal === true}
        />
      )}
    </>
  );
};

export default LeaveEditHistoryTable;
