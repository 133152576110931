import { format ,parseISO,padStart} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

const datetimeutils = {
    formatDateToDate,
    formatDateToTime,
    formatDateTimeToDateTime,
    formatDateTimeToDate,
    formatDateToDay,
    formatDateTimeToTime,
    addDaysExcludingTimeAndFormatToDateTime,
    formatDateTimeToDateTimeWithConversion,
    formatDateTimeToDateWithOutConversion
}


function formatDateToDate(datestring)  {
    if (datestring == null) {
      return "NA"
    }
    const date = new Date(datestring);
    const formattedDate = format(date, 'dd-MMMM-yyyy (EEEE)');
    return formattedDate
}

function formatDateToDay(datestring) {
  if (datestring == null) {
      return "NA";
  }
  const date = new Date(datestring);
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const day = daysOfWeek[date.getDay()];
  return day;
}

function formatDateToTime(datestring)  {
    if (datestring == null) {
      return "NA"
    }
    const date = new Date(datestring);
    const timeString = format(date, 'hh:mm a');
    return timeString
}

function formatDateTimeToDateTime(datestring)  {
    if (datestring == null) {
      return "NA"
    }
    const date = new Date(datestring);

    const timeString = format(date, 'hh:mm a');
    const formattedDate = format(date, 'dd-MMMM-yyyy (EEEE)');

    return formattedDate+' '+timeString;
}


function formatDateTimeToDateTimeWithConversion(datestring)  {
  if (datestring == null) {
    return "NA"
  }
  const date = new Date(datestring);
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);
  
  const formattedDateTime = formatInTimeZone(date, 'Asia/Kolkata', 'dd-MMMM-yyyy hh:mm a', { locale: enUS });
  const timeString = format(date, 'hh:mm a');
  const formattedDate = format(date, 'dd-MMMM-yyyy (EEEE)');

  //return formattedDate+' '+timeString;
  return formattedDateTime ;
}

function formatDateTimeToDateWithOutConversion(datestring)  {
  if (datestring == null) {
    return "NA"
  }
  const date = new Date(datestring);
  //date.setHours(date.getHours() + 5);
  //date.setMinutes(date.getMinutes() + 30);
  
  const formattedDateTime = formatInTimeZone(date, 'Asia/Kolkata', 'dd-MMMM-yyyy', { locale: enUS });
  //const timeString = format(date, 'hh:mm a');
  const formattedDate = format(date, 'dd-MMMM-yyyy');

  //return formattedDate+' '+timeString;
  return formattedDateTime ;
}

function formatDateTimeToDate(datestring)  {
  if (datestring == null) {
    return "NA"
  }
  const date = new Date(datestring);
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const formattedDate = format(date, "dd-MMMM-yyyy (EEEE)", { locale: enUS });

  // console.log(datestring)
  
  // const dateObject = parseISO(datestring);
  // //console.log(dateObject)
  // const formattedDate = format(dateObject, "dd-MMMM-yyyy hh:mm a", { locale: enUS });
  return formattedDate;
}

function formatDateTimeToTime(datestring)  {
  if (datestring == null) {
    return "NA"
  }

  const date = new Date(datestring);
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);

  const formattedTime = format(date, "hh:mm a", { locale: enUS });

  return formattedTime;
}

function addDaysExcludingTimeAndFormatToDateTime(datestring,noofdays)  {
  if (datestring == null) {
    return "NA"
  }

  const date = new Date(datestring);
  date.setDate(date.getDate()+noofdays)
  date.setHours(0,0,0,0)
 

  const timeString = format(date, 'hh:mm a');
  const formattedDate = format(date, 'dd-MMMM-yyyy (EEEE)');
  return formattedDate+' '+timeString;

}





  // Format the Date object into the desired output format
  //const formattedDate = format(dateObject, "dd-MMMM-yyyy hh:mm a");

export default datetimeutils;