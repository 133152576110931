import React, { Component, } from 'react'
import { Grid, Form, Button, Table, Header, Divider, Radio, Pagination,Container } from 'semantic-ui-react'
import { Dropdown, Segment, Modal, Icon } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import AuthContext from '../context/AuthContext'
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast, Slide } from "react-toastify";
import Toasts from '../misc/Toasts';
import moment from 'moment';
import { useState } from "react";
import datetimeutils from "../utils/DateTimeUtils";

class JoiningForm extends Component {
    static contextType = AuthContext
    state = {
        currentPage: 1,
        entriesPerPage: 10,
        joiningList: [],
        joiningFormLoading: false,
        showModal: false,
        comments: "",
        disable: false
    }
    approveorreject = { status: '' };

    getJoiningForm() {

        const Auth = this.context
        const user = Auth.getUser()
        iipeerpapi.leavejoiningformsforapprover(user)
            .then(response => {
                this.setState({ joiningList: response.data })
            })
            .catch(error => {
                handleLogError(error)
            })
            .finally(() => {
                this.setState({ joiningFormLoading: false })
            })
    }
    componentDidMount() {
        this.getJoiningForm();
    }

    renderAttachmentButton = (attachmentDetails) => {
        const { selectedLeave } = this.state;
        //const attachmentDetails = selectedLeave.attachements_details;
        if (attachmentDetails) {
            return (
                <Button
                    size='small'
                    content='Download Attachment'
                    icon='download'
                    onClick={() => this.handleDownload(attachmentDetails)}
                />
            );
        } else {
            return (
                <p>No attachment available</p>
            );
        }
    };

    handleDownload = async (fileName) => {
        const Auth = this.context
        const user = Auth.getUser()
        iipeerpapi.fileDownload(user, fileName)
            .then(response => {
                const filename = response.headers['content-disposition'].split('filename=')[1];
                const contenttype = response.headers['content-type'];
                const blob = new Blob([response.data]);
                //const blob = new Blob([response.data]);
                //blob.lastModifiedDate = new Date();
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                handleLogError(error)
            })
            .finally(() => {

            })
    };

    formatDate = (dateString) => {
        if (dateString == null) {
            return ''
        }
        const date = new Date(dateString)
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' })
        const year = date.getFullYear();
        return `${day}-${month}-${year}`
    }

    handleTimeOld(date) {
        if (date == null) {
            return "NA"
        }
        const req_date = new Date(date);
        // const timeComponent = req_date.toISOString().substring(11, 16);
        const timeComponent = req_date.toLocaleTimeString();
        const [hours, minutes] = timeComponent.split(":");
        let period = "am";
        let hours12 = parseInt(hours, 10);

        if (hours12 === 0) {
            hours12 = 12;
        } else if (hours12 >= 12) {
            period = "pm";
            if (hours12 > 12) {
                hours12 -= 12;
            }
        }
        return `${hours12}:${minutes} ${period}`;
    }

    handleTime(date) {
        if (date == null) {
            return "NA"
        }
        const req_date = new Date(date);
        //const timeComponent = req_date.toISOString().substring(11, 16);
        const timeComponent = req_date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return timeComponent;
    }

    handleStatusColor(color) {
        color.toUpperCase()
        if (color.includes("PENDING") || color.includes("SUBMITTED") || color.includes("APPLIED")) {
            return "warning"
        }
        else if (color.includes("REJECTED")) {
            return "negative"
        }
        else if (color === '') {
            return '';
        }
        else {
            return "positive"
        }
    }

    handleStatus(str) {
        if (str === null) {
            return "PENDING"
        }
        return str.toUpperCase()
    }

    handleNewStatus = (joiningLeave) => {
        const Auth = this.context
        const user = Auth.getUser()
        const username = user ? user.data.preferred_username : '';
        const status = joiningLeave.leavejoiningapprovals && joiningLeave.leavejoiningapprovals.filter(join => join.username === username)[0].status
        //console.log(status)
        return status
    }
    getClassIcon = (details) => {
        if (details === "APPROVED" || details === "ACCEPTED") {
            return 'checkmark'
        }
        else if (details === "REJECTED") {
            return 'close'
        }
        else {
            return 'attention'
        }
    }

    handleModal3Open = (leave, isDisabled) => {

        if (isDisabled === "APPROVED" || isDisabled === "REJECTED") {
            isDisabled = true;
        }
        else {
            isDisabled = false;
        }
        this.setState({
            selectedLeave: leave,
            showModal3: true,
            disable: isDisabled,
        });
    };
    handleModal3Close = () => {
        this.setState({
            selectedLeave: null,
            showModal3: false
        })
    }
    acceptApproval = () => {
        this.setState({
            accepted: true,
            showModal3: false

        })
    }

    rejectApproval = () => {
        this.setState({
            accepted: false,
            showModal3: true
        })
    }

    handleInputChange = (e, { id, value }) => {
        this.setState({ [id]: value })
    }
    handleAPICall = () => {
        const { selectedLeave, approvalIDs, comments } = this.state;
        const Auth = this.context
        const user = Auth.getUser()
        const username = user ? user.data.preferred_username : '';
        const payLoad = {
            "comments": comments,
            "status": this.approveorreject.status === 'APPROVED' ? "APPROVED" : "REJECTED"
        };
        //console.log(reqId)
        const reqid = selectedLeave.leavejoiningapprovals.filter(id =>
            id.username == username
        )[0].uid
        iipeerpapi.leavejoiningApprovals(user, payLoad, reqid)
            .then(response => {
                const resp = response.data;
                this.setState({ approvalResponse: response.data })
                if (resp.status === "APPROVED") {
                    Toasts.notifySuccess("LeaveJoiningForm Approved Successfully");
                }
                else {
                    Toasts.notifySuccess("LeaveJoiningForm Rejected Successfully");
                }
                this.getJoiningForm()
            })
            .catch(error => {
                //console.log(error)
                Toasts.notifyError("Something Went Wrong!")
            })
        this.handleModal3Close();
    }

    handleName = (name) => {
        if (name === "CL") {
            return "Casual Leave";
        }
        else if (name === "HPL") {
            return "HalfPay Leave";
        }
        else if (name === "EL") {
            return "Earned Leave";
        }
        else if (name === "VL") {
            return "Vacation Leave"
        }
        else if (name === "SCL") {
            return "Special Casual Leave";
        }
        else if (name === "RH") {
            return "Restricted Holiday";
        }
        else if (name === "PROL") {
            return "Project Leave";
        }
        else if (name === "COL") {
            return "Commuted Leave";
        }
        else if (name === "SL") {
            return "Station Leave";
        }
        else {
            return "No Leave Found"
        }
    };

    ModalExampleScrollingContent = ({ LeavesList }) => {
        const { selectedLeave } = this.state;
        if (!selectedLeave) {
            return null;
        }
        return (

            <>

                <Modal closeIcon
                    open={this.state.showModal3}
                    size='large'
                    onClose={this.handleModal3Close}

                >

                    <Header textAlign='center' as='h2' color='blue'>Leave Joining Form</Header>
                    <Modal.Content image scrolling>
                    <Container>
                        <Modal.Description>
                            <Modal.Content>

                                {/* <Segment.Group horizontal>
                                    <Segment ><b>Leave Type: </b></Segment>
                                    <Segment>
                                        {this.handleName(selectedLeave.leavetype)} ({selectedLeave.leavetype})
                                    </Segment>
                                </Segment.Group> */}

                                <Segment.Group horizontal>
                                    <Segment ><b>Applied Person Name </b></Segment>
                                    <Segment>
                                        {selectedLeave.userDetailsDTO.fullname}
                                    </Segment>
                                </Segment.Group>
                                <Grid columns='equal' stackable>
                                    <Grid.Row>
                                        <Grid.Column>

                                            <Segment.Group horizontal >
                                                <Segment><b> Start Date  : </b></Segment>
                                                <Segment>{datetimeutils.formatDateToDate(selectedLeave.startdate)} ({selectedLeave.startdaysession})</Segment>
                                            </Segment.Group>
                                        </Grid.Column>
                                        <Grid.Column>

                                            <Segment.Group horizontal>
                                                <Segment><b> End Date  : </b></Segment>
                                                <Segment>{datetimeutils.formatDateToDate(selectedLeave.lastdate)} ({selectedLeave.lastdaysession})</Segment>
                                            </Segment.Group>
                                        </Grid.Column>

                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Segment.Group horizontal>
                                                <Segment><b> Joining Date  : </b></Segment>
                                                <Segment>{datetimeutils.formatDateToDate(selectedLeave.joiningdate)} ({selectedLeave.joiningdaysession})</Segment>
                                            </Segment.Group>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Header as='h5' dividing>Attachment: </Header>
                                            {this.renderAttachmentButton(selectedLeave.attachments_details)}


                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>


                                <h3>Approvals</h3>
                                <Table compact striped celled selectable>
                                    <Table.Header>
                                        <Table.Row textAlign='center'>
                                            <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Person Name</Table.HeaderCell>
                                            <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                                            <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {
                                            Object.values(selectedLeave.leavejoiningapprovals) ? (selectedLeave.leavejoiningapprovals
                                                .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                                                .map((Approvals, index) => {
                                                    return <>
                                                        <Table.Row textAlign='center' key={index}>
                                                            <Table.Cell>{index + 1}</Table.Cell>
                                                            <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                                                            <Table.Cell className={this.handleStatusColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                                                            <Table.Cell>{(Approvals.comments === null) ? ("-") : (Approvals.comments
                                                            )}</Table.Cell>
                                                            <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>

                                                        </Table.Row>
                                                    </>
                                                })) : ("No Details Available")
                                        }
                                    </Table.Body>
                                </Table>
                            </Modal.Content>
                        </Modal.Description>
                        </Container>
                    </Modal.Content >
                    <Modal.Actions style={{ backgroundColor: 'rgb(242, 242, 272)' }}>
                        <Form onSubmit={() => (this.handleAPICall())} >
                            <Form.TextArea rows={4} label='Comments' disabled={this.state.disable} placeholder='Enter your Comments' required
                                id='comments'
                                // value={this.state.comments}
                                onChange={this.handleInputChange} />
                            <Button disabled={this.state.disable} floated="left" type="submit" labelPosition='right' content='Reject' icon='close' negative
                                onClick={() => { this.approveorreject.status = 'REJECTED' }}></Button>
                            <Button disabled={this.state.disable} type="submit" content="Approve/Recommend" labelPosition='right' icon='checkmark' positive
                                onClick={() => { this.approveorreject.status = 'APPROVED' }}></Button>
                        </Form>
                    </Modal.Actions>
                </Modal>
            </>
        )
    }
    renderTableRows = () => {
        const { currentPage, entriesPerPage } = this.state;
        const startIndex = (currentPage - 1) * entriesPerPage;
        const endIndex = startIndex + entriesPerPage;
        const { joiningList } = this.state;
        //console.log(joiningList);
        const Auth = this.context
        const user = Auth.getUser()
        const username = user ? user.data.preferred_username : '';
        const sortedJoiningList = joiningList.slice().sort((a, b) => {
            // First, check if either item has a PENDING status
            const aHasPending = a.leavejoiningapprovals.some(approval => approval.username === username && approval.status === 'PENDING');
            const bHasPending = b.leavejoiningapprovals.some(approval => approval.username === username && approval.status === 'PENDING');
          
            if (aHasPending && !bHasPending) return -1;
            if (!aHasPending && bHasPending) return 1;
          
            // If both or neither are PENDING, sort by appliedtime
            return new Date(b.appliedtime) - new Date(a.appliedtime);
          });
        const displayedLeaves = sortedJoiningList.slice(startIndex, endIndex);
        let newcounter = 10001;
        if (displayedLeaves.length === 0) {
            return (
                <Table.Row key={newcounter++}>
                    <Table.Cell collapsing textAlign='center' colSpan='8'>No Details are found</Table.Cell>
                </Table.Row>
            );
        } else {
            let counter = 1;
            let newcounter = 1;
            return displayedLeaves.map((joiningListTemp, index) => {
                const joiningFormApprovals = joiningListTemp.leavejoiningapprovals.filter(approve => approve.username === username);
                return (
                    <Table.Row textAlign='center' key={counter}>
                        <Table.Cell>{startIndex + (counter++)}</Table.Cell>
                        <Table.Cell>{joiningListTemp.userDetailsDTO.fullname}</Table.Cell>
                        <Table.Cell>{datetimeutils.formatDateToDate(joiningListTemp.startdate)} ({joiningListTemp.startdaysession})</Table.Cell>
                        <Table.Cell>{datetimeutils.formatDateToDate(joiningListTemp.lastdate)} ({joiningListTemp.lastdaysession})</Table.Cell>
                        <Table.Cell>{datetimeutils.formatDateToDate(joiningListTemp.joiningdate)} ({joiningListTemp.joiningdaysession})</Table.Cell>
                        <Table.Cell >{datetimeutils.formatDateTimeToDateTime(joiningListTemp.createdtime)}</Table.Cell>
                        <Table.Cell>
                            {
                                joiningFormApprovals.map((approval, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell className={this.handleStatusColor(approval.status)}>
                                            <Icon name={this.getClassIcon(this.handleStatusColor(approval.status))} />
                                            {approval.status}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Cell>

                        <Table.Cell>

                            {
                                joiningFormApprovals.map((approval, index) => (
                                    <Table.Row key={index}>

                                        <Table.Cell>
                                            <Button
                                                color='blue'
                                                size='small'
                                                onClick={() => this.handleModal3Open(joiningListTemp, approval.status)}
                                            // icon='trash'
                                            // disabled={user.username === 'admin'}
                                            // onClick={() => handleDeleteUser(user.username)}
                                            >VIEW<Icon name="right arrow" /></Button>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                            }
                        </Table.Cell>
                    </Table.Row>
                );
            });
        }
    };

    handlePageChange = (event, { activePage }) => {
        this.setState({ currentPage: activePage });
    };


    renderPaginationControls = () => {
        const { currentPage, entriesPerPage, joiningList } = this.state;
        const totalPages = Math.ceil(joiningList.length / entriesPerPage);

        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Pagination
                    activePage={currentPage}
                    totalPages={totalPages}
                    onPageChange={this.handlePageChange}
                />
            </div>
        );
    };

    render() {
        return (
            <>

                <Grid stackable >
                    <Grid.Row columns={3} centered>
                        <Grid.Column >
                            {/* <Form onSubmit={handleSearchOrder}> */}
                            {/* <Form >
             
                <Form.Field inline>
                  <label>Year</label>
                  <Dropdown
                    closeOnEscape
                
                    id='Year'
                    selection
                    options={this.getYears()}
                    defaultValue={this.getYears()[1].value}
                    onChange={this.handleInputChange}
                  />
                </Form.Field>
              
              </Form> */}
                        </Grid.Column>

                    </Grid.Row>
                </Grid>
                <this.ModalExampleScrollingContent />
                <Table compact striped celled selectable>
                    <Table.Header>

                        <Table.Row textAlign='center'>
                            <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Applied Person Name</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Start Date</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Last Date</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Joining Date</Table.HeaderCell>
                            <Table.HeaderCell width={3}>Submitted Date </Table.HeaderCell>
                            <Table.HeaderCell width={3}>Status</Table.HeaderCell>
                            <Table.HeaderCell width={2}></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.renderTableRows()}
                    </Table.Body>
                </Table>
                {this.renderPaginationControls()}
            </>
        )
    }

}

export default JoiningForm