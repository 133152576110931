import React, { useContext, useEffect, useState } from 'react';
import { Grid, Modal, Table, Divider, Header, Input, Radio, Form, Button, Pagination, Dropdown, Segment, Icon } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import datetimeutils from "../utils/DateTimeUtils";
import LeaveUtils from './LeaveUtils';
import DeleteJoiningFormConfirmationModal from './DeleteJoiningFormConfirmationModal';
import LMSUtils from './LMSUtils';
import FileAttachmentUtils from '../utils/FileAttachmentUtils';

const LeaveJoiningFormModal = ({ leavejoiningformid, onClose,handleGetLeaves ,leaveowner }) => {
  const [open, setOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState([]);
  const [currentLeaveId, setCurrentLeaveId] = useState(null);
  const [currentLeaveJoiningId, setCurrentLeaveJoiningId] = useState(null);
  const [showDeleteLeaveJoiningConfirmModal, setShowDeleteLeaveJoiningConfirmModal] = useState(false);
  const authContext = useContext(AuthContext);
  const [showLeaveDetails, setShowLeaveDetails] = useState(false);
  const user = authContext.getUser();
  const handleGetLeaveJoiningForm = async (leavejoiningformid) => {
    try {
      iipeerpapi.getleavejoiningformV2(user, leavejoiningformid)
        .then(response => {
          setSelectedLeave(response.data);
          setOpen(true);
        })
        .catch(error => {
          handleLogError(error);
        })
    }
    catch (error) {
    }
  };

  const handleLeaveid = (leaveid) => {
    setCurrentLeaveId(leaveid);
  }
  const handleshowDeleteLeaveJoiningConfirmModal = (leavejoiningformid) => {
    setCurrentLeaveJoiningId(leavejoiningformid);
  }

  useEffect(() => {
    handleGetLeaveJoiningForm(leavejoiningformid)
  }, []);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <Modal
        closeIcon
        open={open}
        size='fullscreen'
        onClose={handleClose}>
        <Header textAlign='center' as='h2' color='blue'>Leave Joining Form</Header>
        <Modal.Content image scrolling >
          <Modal.Description>
            <Modal.Content>
              <Segment.Group horizontal>
                <Segment><b>Applied Person Name: </b></Segment>
                <Segment>
                  {
                    selectedLeave.fullName
                  }
                </Segment>
              </Segment.Group>
              <Table compact striped celled selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Leave Type</Table.HeaderCell>
                    <Table.HeaderCell width={2}>StartDate</Table.HeaderCell>
                    <Table.HeaderCell width={2}>EndDate</Table.HeaderCell>
                    <Table.HeaderCell width={1}>EffectiveLeaves</Table.HeaderCell>
                    <Table.HeaderCell width={1}>WorkingDays</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {

                    (selectedLeave.leaveDetails) ? (selectedLeave.leaveDetails
                      .sort((p1, p2) => (p1.start_date > p2.start_date) ? 1 : (p1.start_date < p2.start_date) ? -1 : 0)
                      .map((Approvals, index) => {
                        return <>
                          <Table.Row textAlign='center' key={Approvals.leaveid} >
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{Approvals.leavetype}</Table.Cell>
                            <Table.Cell>{datetimeutils.formatDateTimeToDate(Approvals.start_date)} {Approvals.start_session}</Table.Cell>
                            <Table.Cell>{datetimeutils.formatDateTimeToDate(Approvals.end_date)} {Approvals.end_session}</Table.Cell>
                            <Table.Cell>{Approvals.effectiveLeaves}</Table.Cell>
                            <Table.Cell>{Approvals.appliedDuration}</Table.Cell>
                            <Table.Cell>{Approvals.status}</Table.Cell>
                            <Table.Cell><Button
                              // icon='angle right'
                              floating
                              button
                              // className='icon'
                              onClick={() => { setCurrentLeaveId(Approvals.leaveid) }}>
                              View Leave
                            </Button></Table.Cell>
                          </Table.Row>
                        </>
                      })) : ("No Details Available")
                  }
                </Table.Body>
              </Table>

              <Grid columns='equal' stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Segment.Group horizontal >
                      <Segment><b> Start Date  : </b></Segment>
                      <Segment>{datetimeutils.formatDateToDate(selectedLeave.startdate)} {(selectedLeave.startDaySession)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Segment.Group horizontal>
                      <Segment><b> End Date  : </b></Segment>
                      <Segment>{datetimeutils.formatDateToDate(selectedLeave.lastdate)} {(selectedLeave.lastDaySession)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Segment.Group horizontal>
                      <Segment><b> Joining Date  : </b></Segment>
                      <Segment>{datetimeutils.formatDateToDate(selectedLeave.joiningDate)} {(selectedLeave.joiningDaySession)}</Segment>
                    </Segment.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Header as='h5' dividing>Attachment: </Header>
                    {FileAttachmentUtils.renderAttachmentButton(selectedLeave.attachments_details, user)}


                  </Grid.Column>
                </Grid.Row>

              </Grid>
              <h3>Approvals</h3>
              <Table compact striped celled selectable>
                <Table.Header>
                  <Table.Row textAlign='center'>
                    <Table.HeaderCell width={1}>Approver Sequence</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Person Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Status</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Comments</Table.HeaderCell>
                    <Table.HeaderCell width={2}>Status Updated On</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {

                    (selectedLeave.leavejoiningapprovals) ? (selectedLeave.leavejoiningapprovals
                      .sort((p1, p2) => (p1.approversequence > p2.approversequence) ? 1 : (p1.approversequence < p2.approversequence) ? -1 : 0)
                      .map((Approvals, index) => {
                        return <>
                          <Table.Row textAlign='center' >
                            <Table.Cell>{index + 1}</Table.Cell>
                            <Table.Cell>{Approvals.userDetailsDTO.fullname}</Table.Cell>
                            <Table.Cell className={LMSUtils.getClassColor(Approvals.status)}>{(Approvals.status === "") ? ("-") : (Approvals.status)}</Table.Cell>
                            <Table.Cell>{Approvals.comments}</Table.Cell>
                            <Table.Cell >{datetimeutils.formatDateTimeToDateTime(Approvals.updatedtime)}</Table.Cell>
                          </Table.Row>
                        </>
                      })) : ("No Details Available")
                  }
                </Table.Body>
              </Table>
              <br />

            </Modal.Content>

          </Modal.Description>
        </Modal.Content >
        <Modal.Actions style={{ backgroundColor: 'rgb(242, 242, 272)' }}>
          { (leaveowner===true)
          && (<Button disabled={ ( selectedLeave==null || selectedLeave.status==null || selectedLeave.status.includes("APPROVED"))} onClick={() => {
              handleshowDeleteLeaveJoiningConfirmModal(leavejoiningformid)
            
          }
          } negative>
            Delete Leave Joinging Form <Icon name='trash' />
          </Button>)
}
          <Button onClick={handleClose} primary >
            Close <Icon name='chevron right' />
          </Button>
        </Modal.Actions>
        {currentLeaveJoiningId != null && leaveowner!=null && leaveowner===true && selectedLeave!=null && selectedLeave.status!=null && !selectedLeave.status.includes("APPROVED")&& (
          <DeleteJoiningFormConfirmationModal
            key={currentLeaveJoiningId}
            currentLeaveJoiningId={currentLeaveJoiningId}
            handleshowDeleteLeaveJoiningConfirmModal={handleshowDeleteLeaveJoiningConfirmModal}
            handleGetLeaves={handleGetLeaves}
            handleClose = {handleClose}
                      />
        )}
        {currentLeaveId != null && (
          <LeaveUtils
            key={currentLeaveId}
            leaveId={currentLeaveId}
            handleLeaveid={handleLeaveid}
          />
        )}
      </Modal>

    </>
  )
}

export default LeaveJoiningFormModal;