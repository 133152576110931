
import React, { Component } from 'react'
import { Grid, Pagination, Form, Button, Input, Table } from 'semantic-ui-react'
import { Dropdown, Segment } from 'semantic-ui-react'
import { iipeerpapi } from '../misc/iipeerpapi'
import { handleLogError } from '../misc/Helpers'
import AuthContext from '../context/AuthContext'
import datetimeutils from "../utils/DateTimeUtils";
import LeaveDetailsModal from './LeaveDetailsModal';

class LeaveBalanceHistory extends Component {
  static contextType = AuthContext


  state = {
    LeaveBalanceList: this.props.LeaveBalanceList || [],
    LeaveBalanceHistory: [],
    currentPage: 1,
    entriesPerPage: 10,
    isAdmin: false,
    leaveid: null,
    showLeaveDetails: false,
    selectedleaveType: null
  }

  handleCloseLeaveViewModal = () => {
    this.setState({ leaveid: null });
    this.setState({ showLeaveDetails: false });
  };

  handleShowLeaveDetails = (leaveid) => {
    this.setState({ leaveid: leaveid });
    this.setState({ showLeaveDetails: true });
  }


  handleGetLeaveBalanceHistory = (leavetype) => {

    const Auth = this.context
    const user = Auth.getUser()


    iipeerpapi.getLeaveBalanceByHistory(user, leavetype)
      .then(response => {
        this.setState({ LeaveBalanceHistory: response.data })
      })
      .catch(error => {
        this.setState({ LeaveBalanceHistory: [] })
        handleLogError(error)
      })
      .finally(() => {
        this.setState({ isLeaveBalanceLoading: false })
      })
  }

  handleGetLeaveBalanceHistoryForAdmin = (username, leavetype) => {

    const Auth = this.context
    const user = Auth.getUser()


    iipeerpapi.getLeaveBalanceByHistoryForAdmin(user, username, leavetype)
      .then(response => {
        this.setState({ LeaveBalanceHistory: response.data })
      })
      .catch(error => {
        this.setState({ LeaveBalanceHistory: [] })
        handleLogError(error)
      })
      .finally(() => {
        this.setState({ isLeaveBalanceLoading: false })
      })
  }


  componentDidMount() {
    const Auth = this.context
    const user = Auth.getUser()
    let isAdmin = user.data.rol[0] === 'ADMIN' ? true : false
    //console.log("LeaveBalanceListcompon"+JSON.stringify(this.state.LeaveBalanceList))
    this.setState({ isAdmin: isAdmin })
    //this.state.HolidaysList = this.props.HolidaysList
    //console.log(this.props.HolidaysList)
    // this.setState({ Year: new Date().getFullYear() })
  }

  handleGetLeaveBalance = (username) => {

    const Auth = this.context
    const user = Auth.getUser()

    iipeerpapi.getLeaveBalanceByUsername(user, username)
      .then(response => {
        this.setState({ LeaveBalanceList: response.data })
        //console.log(JSON.stringify(response.data)+"resp")
      })
      .catch(error => {
        this.setState({ LeaveBalanceList: [] })
        this.setState({ LeaveBalanceHistory: [] })
        handleLogError(error)
      })
      .finally(() => {
        this.setState({ isLeaveBalanceLoading: false })
      })
  }


  handleInputChange = (e, { id, value }) => {
    const { isAdmin } = this.state;
    this.setState({ [id]: value });
    
    
    if(value==='' || value===null){
      this.setState({ LeaveBalanceHistory: [] });
      this.setState({ selectedleaveType: null })
    }
    if (id === "username" && value!=='' && value!==null) {
    
      
      this.setState({ selectedleaveType: null }); 
      this.handleGetLeaveBalance(value); }
    
    //else if (id === "username" && isAdmin === true && this.state.leavetype != null) this.handleGetLeaveBalanceHistoryForAdmin(this.state.username, this.state.leavetype)
    if (id === 'leavetype' && isAdmin === false && value!==''&&  value!==null ) {
      this.setState({ selectedleaveType: value })
      this.handleGetLeaveBalanceHistory(value)
    }
      if (id === 'leavetype' && isAdmin === true && value!=='' && value!==null){ 
        this.setState({ selectedleaveType: value })
        this.handleGetLeaveBalanceHistoryForAdmin(this.state.username, value)}

    
  }

  getUsersList = () => {

    const Auth = this.context
    let currentusername = Auth.getUser().data.preferred_username
    const { users } = this.props

    return (users.filter(user => {
      return user.username !== currentusername
    }).map(user => {

      return ({
        key: user.username,
        text: user.fullname,
        value: user.username,
      })
    }))
  };

  getLeaveTypeList = (LeaveTypeList) => {
    return LeaveTypeList.map(leavetype => {
      //console.log(leavetype);
      return ({
        key: leavetype,
        text: leavetype,
        value: leavetype,
      })
    })

  };
  handlePageChange = (event, { activePage }) => {
    this.setState({ currentPage: activePage });
  };

  renderTableRows = () => {
    const { currentPage, entriesPerPage, LeaveBalanceHistory } = this.state;
    const startIndex = (currentPage - 1) * entriesPerPage;
    const endIndex = startIndex + entriesPerPage;
    // const sortedLeaves = LeaveBalanceHistory.slice().sort((a, b) => new Date(b.leaveapplied_date) - new Date(a.leaveapplied_date));
    const LeaveBalanceHistoryList = LeaveBalanceHistory.slice(startIndex, endIndex);

    let LeaveBalanceHistoryTable;
    if (LeaveBalanceHistoryList == null || LeaveBalanceHistoryList.length === 0) {
      LeaveBalanceHistoryTable = (
        <Table.Row key={1}>
          <Table.Cell collapsing textAlign='center' colSpan='9'>No Leave Balance History Is Found.</Table.Cell>
        </Table.Row>
      )
    } else {
      let counter = 1
      LeaveBalanceHistoryTable = LeaveBalanceHistoryList.map((LeaveBalance, index) => {

        return (
          <Table.Row textAlign='center' key={index} className={
            (() => {
              if (LeaveBalance.addordeduct.includes('ADD')) {
                return 'positive';
              } else if (LeaveBalance.addordeduct.includes('DEDUCT')) {
                return 'negative';
              } else if (LeaveBalance.prevbalance > LeaveBalance.currentbalance) {
                return 'negative';
              } else {
                return 'positive';
              }
            })()
          }  >
            <Table.Cell>{startIndex + counter++}</Table.Cell>
            <Table.Cell>{LeaveBalance.leavetype}</Table.Cell>
            <Table.Cell>{LeaveBalance.addordeduct}</Table.Cell>
            <Table.Cell>{LeaveBalance.prevbalance}</Table.Cell>
            <Table.Cell>{LeaveBalance.addedordeductedleaves}</Table.Cell>
            <Table.Cell>{LeaveBalance.currentbalance}</Table.Cell>
            <Table.Cell>{LeaveBalance.reason}</Table.Cell>
            <Table.Cell>{LeaveBalance.remarks}</Table.Cell>
            <Table.Cell>{LeaveBalance.createdby}</Table.Cell>
            <Table.Cell>{LeaveBalance.leaveid === null ? 'NA' : <Button basic className={
        (() => {
          if (LeaveBalance.addordeduct.includes('ADD')) {
            return 'positive';
          } else if (LeaveBalance.addordeduct.includes('DEDUCT')) {
            return 'negative';
          } else if (LeaveBalance.prevbalance > LeaveBalance.currentbalance) {
            return 'negative';
          } else {
            return 'positive';
          }
        })()
      }  onClick={() => this.handleShowLeaveDetails(LeaveBalance.leaveid)}>
              View Leave Details 
            </Button>}
            </Table.Cell>
            <Table.Cell>{datetimeutils.formatDateTimeToDateTime(LeaveBalance.createdtime)}</Table.Cell>
          </Table.Row>
        )
      })
    }
    return LeaveBalanceHistoryTable;
  }

  renderPaginationControls = () => {
    const { currentPage, entriesPerPage, LeaveBalanceHistory } = this.state;
    const totalPages = Math.ceil(LeaveBalanceHistory.length / entriesPerPage);

    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          activePage={currentPage}
          totalPages={totalPages}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  };

  render() {

    const { showLeaveDetails } = this.state;
    const { LeaveBalanceList } = this.state;
    const { selectedleaveType } = this.state
    // const { LeaveBalanceHistory = {} } = this.state;
    const { isAdmin } = this.state;
    let LeaveTypeOptions = [];

    let LeaveTypeList = [];
    LeaveTypeList.push("All");
    LeaveBalanceList != null && LeaveBalanceList.length !== 0 && LeaveBalanceList.map((LeaveBalance) => {
      LeaveTypeList.push(LeaveBalance.leavetype);
    });

    LeaveTypeOptions = this.getLeaveTypeList(LeaveTypeList);
    // let LeaveBalanceHistory;





    let usersList = this.getUsersList()

    return (
      <>

        <Grid stackable >
          <Grid.Row columns={2} centered>
            <Grid.Column >
              <Form >
                <Form.Group widths='equal'>

                  {isAdmin === true && (<Form.Field>
                    <label>Person Name</label>

                    <Dropdown
                      closeOnEscape
                      id='username'
                      onChange={this.handleInputChange}
                      clearable
                      placeholder='Person Name  '
                      search
                      fluid
                      selection
                      options={usersList}

                    />
                  </Form.Field>)}
                  <Form.Field>
                    <label>Leave Type</label>

                    <Dropdown
                      closeOnEscape
                      id='leavetype'
                      onChange={this.handleInputChange}
                      clearable
                      placeholder='LeaveType  '
                      search
                      fluid
                      selection
                      options={LeaveTypeOptions}
                      value={selectedleaveType}
                    />
                  </Form.Field>

                </Form.Group>
              </Form>
            </Grid.Column>

          </Grid.Row>
        </Grid>
        <Table compact striped celled selectable>
          <Table.Header>
            <Table.Row textAlign='center'>
              <Table.HeaderCell width={1}>S.No.</Table.HeaderCell>
              <Table.HeaderCell width={1}>Leave Type</Table.HeaderCell>
              <Table.HeaderCell width={1}>ADD/DEDUCT</Table.HeaderCell>
              <Table.HeaderCell width={1}>Previous Balance</Table.HeaderCell>
              <Table.HeaderCell width={1}>No of Leaves</Table.HeaderCell>
              <Table.HeaderCell width={1}>Current Balance</Table.HeaderCell>
              <Table.HeaderCell width={3}>Reason</Table.HeaderCell>
              <Table.HeaderCell width={2}>Remarks</Table.HeaderCell>
              <Table.HeaderCell width={1}>Created due to</Table.HeaderCell>
              <Table.HeaderCell width={2}>Respective Leave</Table.HeaderCell>
              <Table.HeaderCell width={3}>Created Time</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderTableRows()}
          </Table.Body>
        </Table>
        {this.renderPaginationControls()}
        {showLeaveDetails && (<LeaveDetailsModal
          leaveId={this.state.leaveid}
          fromleaveviewmodal={false}
          onClose={this.handleCloseLeaveViewModal}
        />)}
      </>
    )
  }
}

export default LeaveBalanceHistory
