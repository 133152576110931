import React, { useState, useContext } from 'react';
import { Modal, Button, Form, TextArea, Message } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import Toasts from '../misc/Toasts';
import AuthContext from '../context/AuthContext';

const UseAuth = () => {
    const Auth = useContext(AuthContext);
    return Auth;
};

// Modal for Leave Deletion
function ModalDeleteConfirmation({ selectedLeave, handleGetLeaves, handleGetLeaveBalance,handleDeleteModal,handleLeaveModal }) {
  const [dlcommenterror, setDlcommenterror] = useState(false);
  const [dlcomment, setDlcomment] = useState('');
  const [deleteModal, setDeleteModal] = useState(true);
  
  const Auth = UseAuth(); // Call UseAuth at the top
  const user = Auth.getUser();
  
  const deleteLeavePayload = { leaveid: selectedLeave?.leaveid };

  const handledlcommentchange = (event) => {
    const newcomment = event.target.value;
    setDlcommenterror(false);
    setDlcomment(newcomment);
    deleteLeavePayload.deletereason = newcomment;
  };

  const handleDelete = () => {
    if (dlcomment.trim() === '') {
      setDlcommenterror(true);
    } else {
      
        const deleteLeavePayload = {
          leaveid: selectedLeave?.leaveid,
          deletereason: dlcomment.trim()
        };
      iipeerpapi.deleteLeaves(user, deleteLeavePayload)
        .then(() => {
          handleGetLeaves();
          Toasts.notifySuccess("Leave Deleted Successfully!");
          if (selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD') {
            handleGetLeaveBalance();
            Toasts.notifySuccess("Leave Balance Successfully Updated.");
          }
          handleLeaveModal()
        })
        .catch(error => {
          handleLogError(error);
          Toasts.notifyError("Something went wrong. Please try again!");
        });
        handleModalClose();
    }
  };

  const handleModalClose = () => {
    setDeleteModal(false);
    handleDeleteModal(false)
  };

  return (
    <Modal onClose={handleModalClose} open={deleteModal} size='small'>
      <Modal.Header>Delete Leave Confirmation</Modal.Header>
      <Modal.Content>
        <p>Please provide a reason for deleting leave!</p>
        <Form.Group widths='equal'>
          <Form.Field>
            <TextArea
              required
              placeholder="Enter Reason of Deleting Leave..."
              value={dlcomment}
              onChange={handledlcommentchange}
              style={{ width: '100%', boxSizing: 'border-box' }}
            />
          </Form.Field>
        </Form.Group>
        {dlcommenterror && <Message negative>Please provide a reason</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleDelete}>
          Confirm Delete Leave
        </Button>
        <Button negative onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

// Modal for Leave Cancellation
function ModalCancelConfirmation({ selectedLeave, handleGetLeaves, handleCancelModal, handleLeaveModal }) {
  const [clcommenterror, setClcommenterror] = useState(false);
  const [clcomment, setClcomment] = useState('');
  const [cancelModal, setCancelModal] = useState(true);
  
  const Auth = UseAuth();
  const user = Auth.getUser();

  const handlecommentchange = (event) => {
    const newcomment = event.target.value;
    setClcommenterror(false);
    setClcomment(newcomment);
  };

  const handleCancel = () => {
    if (clcomment.trim() === '') {
      setClcommenterror(true);
    } else {
      const cancelLeavePayload = {
        leaveid: selectedLeave?.leaveid,
        comments: clcomment.trim()
      };

      iipeerpapi.cancelLeave(user, cancelLeavePayload)
        .then(() => {
          handleLeaveModal()
          handleGetLeaves();
          Toasts.notifySuccess("Leave Cancellation Initiated Successfully!");
          if (selectedLeave.leavetype !== 'SL' && selectedLeave.leavetype !== 'LD') {
            Toasts.notifySuccess("Leave Balance will be updated after approval of cancellation.");
          }
        })
        .catch(error => {
          handleLogError(error);
          Toasts.notifyError("Something went wrong. Please try again!");
        });
      handleModalClose();
    }
  };

  const handleModalClose = () => {
    setCancelModal(false);
    handleCancelModal(false);
    
  };

  return (
    <Modal onClose={handleModalClose} open={cancelModal} size='small'>
      <Modal.Header>Cancel Confirmation</Modal.Header>
      <Modal.Content>
        <p>Please provide a reason for cancelling leave!</p>
        <Form.Group widths='equal'>
          <Form.Field>
            <TextArea
              required
              placeholder="Enter Reason of Cancellation..."
              value={clcomment}
              onChange={handlecommentchange}
              style={{ width: '100%', boxSizing: 'border-box' }}
            />
          </Form.Field>
        </Form.Group>
        {clcommenterror && <Message negative>Please provide a comment</Message>}
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleCancel}>
          Confirm Cancel Leave
        </Button>
        <Button negative onClick={handleModalClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

// Modal for Deleting Joining Form
function DeleteJoiningFormConfirmation({ selectedLeave, handleGetLeaves }) {
  const [deleteModal3, setDeleteModal3] = useState(false);

  const Auth = UseAuth(); // Call UseAuth at the top
  const user = Auth.getUser();

  const handleJoiningFormDelete = () => {
    iipeerpapi.deletejoiningform(user, selectedLeave.leavejoiningform.leavejoiningformid)
      .then(() => {
        handleGetLeaves();
        Toasts.notifySuccess("Joining form Deleted Successfully!");
      })
      .catch(error => {
        handleLogError(error);
        Toasts.notifyError("Something went wrong. Please try again!");
      });
    setDeleteModal3(false);
  };

  const handleModalClose = () => {
    setDeleteModal3(false);
  };

  return (
    <Modal onClose={handleModalClose} open={deleteModal3} size='small'>
      <Modal.Header>Delete Joining Form Confirmation</Modal.Header>
      <Modal.Content>
        <p>Are you sure you want to delete the leave joining form?</p>
      </Modal.Content>
      <Modal.Actions>
        <Button positive onClick={handleJoiningFormDelete}>
          Yes
        </Button>
        <Button negative onClick={handleModalClose}>
          No
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export const LeaveOwnerOperations = {
  ModalDeleteConfirmation,
  ModalCancelConfirmation,
  DeleteJoiningFormConfirmation
};
