import React, { useState, useContext, useMemo, useEffect } from 'react';
import { Message, Grid, Radio, ButtonGroup, Form, Table, Button, Icon, Input, Dropdown, Segment, Label, TextArea, Checkbox, } from 'semantic-ui-react';
import { iipeerpapi } from '../misc/iipeerpapi';
import { handleLogError } from '../misc/Helpers';
import AuthContext from '../context/AuthContext';
import Toasts from '../misc/Toasts';
import 'react-toastify/dist/ReactToastify.css';

const UpdateLeaveBalance = ({ users }) => {
  const Auth = useContext(AuthContext);

  const [leaveBalanceList, setLeaveBalanceList] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [formSubmission, setFormSubmission] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [viewMode, setViewMode] = useState('person');
  const [formError, setFormError] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [errorFields, setErrorFields] = useState(false);
  const [sortColumn, setSortColumn] = useState('fullname');
  const [sortDirection, setSortDirection] = useState('ascending');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');
  const [clickSelection, setClickSelection] = useState('view');
  const [previewLeaveBalanceList, setPreviewLeaveBalanceList] = useState(null);

  const [isConversionChecked, setConversionChecked] = useState(false);
  useEffect(() => {
    console.log('previewLeaveBalanceList changed:', previewLeaveBalanceList);
  }, [previewLeaveBalanceList]);

  const handleCheckboxChange = (e, { checked }) => {
    setConversionChecked(checked);
    if (checked === true) {
      setFormData(prev => ({
        ...prev,
        addordeduct: 'conversion',
        leavetype: '',
        addedordeductedleaves: 0 // Automatically set days to 0 when 'Reset' is chosen
      }));
    }
  };




  const [formData, setFormData] = useState({
    personorrole: '',
    rolename: [],
    username: [],
    leavetype: '',
    addordeduct: '',
    addedordeductedleaves: 0,
    reason: '',
    remarks: ''
  });


  const handleInputChange = (e, { id, value }) => {
    setFormData(prev => ({
      ...prev,
      [id]: id === 'addedordeductedleaves' && formData.addordeduct !== 'reset' ? parseFloat(value) : value,
      personorrole: viewMode
    }));
    console.log(value)
    if (id === "leavetype") setSelectedLeaveType(value);
    //if (id === "username") handleGetLeaveBalance(value[0]);
    if (id === "rolename") setSelectedRoles(value);
    if (id === "username") setSelectedUsers(value)
    if (id === 'addordeduct' && value === 'reset') {
      setFormData(prev => ({
        ...prev,
        addedordeductedleaves: 0 // Automatically set days to 0 when 'Reset' is chosen
      }));
    }
  };

  const handleApiResponse = (response) => {

    const allLeaveTypes = new Set();
    const mappedData = response.data.reduce((acc, item) => {
      const user = users.find(u => u.username === item.username);
      if (!user) return acc;
      if (isConversionChecked) {

      }
      allLeaveTypes.add(item.leavetype);

      const existingEntry = acc.find(entry => entry.username === item.username);
      if (existingEntry) {
        existingEntry[item.leavetype] = item.remaining;
      } else {
        acc.push({
          username: item.username,
          fullname: user.fullname,
          year: item.year,
          [item.leavetype]: item.remaining
        });
      }
      return acc;
    }, []);

    setLeaveTypes(Array.from(allLeaveTypes).sort());
    setLeaveBalanceList(mappedData);
  };



  const handleSort = (clickedColumn) => {
    if (sortColumn !== clickedColumn) {
      setSortColumn(clickedColumn);
      setSortDirection('ascending');
    } else {
      setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
    }
  };

  const sortedLeaveBalanceList = useMemo(() => {
    //console.log(leaveBalanceList)
    return [...leaveBalanceList]
      .sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'ascending' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'ascending' ? 1 : -1;
        return 0;
      });
  }, [leaveBalanceList, sortColumn, sortDirection]);

  const renderLeaveBalanceTable = () => {
    if (sortedLeaveBalanceList.length === 0) {
      return (
        <Table.Row>
          <Table.Cell colSpan={5} textAlign="center">No Leave Balance Found</Table.Cell>
        </Table.Row>
      );
    }

    return sortedLeaveBalanceList.map((item, index) => (
      <Table.Row key={item.username}>
        <Table.Cell textAlign="center">{index + 1}</Table.Cell>
        <Table.Cell textAlign="center">{item.fullname}</Table.Cell>

        <Table.Cell textAlign="center">{item.year}</Table.Cell>
        {(selectedLeaveType && !isConversionChecked) ? (
          <Table.Cell textAlign="center">
            {leaveTypes.includes(selectedLeaveType) ?
              (item[selectedLeaveType] !== undefined ? item[selectedLeaveType] : 'N/A') :
              'N/A'}
          </Table.Cell>
        ) : (
          leaveTypes.map(type => (
            <Table.Cell textAlign="center" key={type}>
              {item[type] !== undefined ? item[type] : 'N/A'}
            </Table.Cell>
          ))
        )}
      </Table.Row>
    ));
  };



  const validateForm = () => {
    if (viewMode === 'person' && selectedUsers.length === 0) {
      setFormError('Please select at least one user');
      return false;
    }
    if (viewMode === 'role' && selectedRoles.length === 0) {
      setFormError('Please select at least one role');
      return false;
    }
    setFormError('');
    return true;
  };

  const validatePreviewForm = () => {

    if (isConversionChecked === false) {
      if (formData.addordeduct === '') {
        setFormError('Please select Add/Deduct/Reset!');
        return false;
      }
      if (formData.leavetype === '') {
        setFormError('Please select Leave Type!');
        return false;
      }
      if (formData.addordeduct !== 'reset' && formData.addordeduct !== '' && formData.addedordeductedleaves === 0) {
        setFormError('Please mention number of leaves!');
        return false;
      }
      if (formData.addordeduct !== 'reset' && formData.addedordeductedleaves <= 0) {
        setFormError('Number of leaves must be greater than 0!');
        return false;
      }
    }
    if (formData.reason.trim() === '') {
      setFormError('Please provide reason!');
      return false;
    }
    if (formData.remarks.trim() === '') {
      setFormError('Please provide remarks!');
      return false;
    }

    setFormError('');
    return true;
  };



  const handlePreview = (e) => {
    setLeaveBalanceList([])
    setPreviewLeaveBalanceList([])
    e.preventDefault();
    if (!validateForm()) return;

    if (!validatePreviewForm()) return;
    //if (!validateForm()) return;

    setIsLoading(true);
    const user = Auth.getUser();


    iipeerpapi.previewupdateleavebalance(user, formData)
      .then((response) => {
        setPreviewLeaveBalanceList(response.data)
      })
      .catch(error => {
        setLeaveBalanceList([])
        setPreviewLeaveBalanceList([])
        Toasts.notifyError("Something went wrong!");


        handleLogError(error);
      })
      .finally(() => setIsLoading(false));
  };


  const handleUpdate = (e) => {
    setLeaveBalanceList([])
    setPreviewLeaveBalanceList([])
    e.preventDefault();
    if (!validateForm()) return;

    if (!validatePreviewForm()) return;
    //if (!validateForm()) return;

    setIsLoading(true);
    const user = Auth.getUser();
    setIsButtonDisabled(true);
    iipeerpapi.updateLeaveBalance(user, formData)
      .then(response => {
        setPreviewLeaveBalanceList(response.data)


        if (response.data)
          Toasts.notifySuccess("Leave Balance Updated Successfully!");
        else {
          setLeaveBalanceList([])
          setPreviewLeaveBalanceList([])
          Toasts.notifyError("Something went wrong!");
        }
      })
      .catch(error => {
        setLeaveBalanceList([])
        setPreviewLeaveBalanceList([])
        Toasts.notifyError("Something went wrong!");


        handleLogError(error);
      })
      .finally(() => {
        setIsButtonDisabled(false);
        setIsLoading(false);
      });


  };

  const handleRefresh = (e) => {
    e.preventDefault();
    setLeaveBalanceList([])
    setPreviewLeaveBalanceList([])
    if (!validateForm()) return;
    //if (!validateForm()) return;

    setIsLoading(true);
    const user = Auth.getUser();
    let formData = new FormData();

    formData.append('year', new Date().getFullYear());

    if (viewMode === 'person') {
      formData.append('viewmode', "person");
      formData.append('usernames', selectedUsers);
    } else if (viewMode === 'role') {
      formData.append('viewmode', "role");
      formData.append('roleslist', selectedRoles);
    } else if (viewMode === 'all') {
      formData.append('viewmode', "role");
      formData.append('roleslist', ['NTS', 'Faculty']);
    }


    iipeerpapi.getleavebalanceforadmin(user, formData)
      .then(handleApiResponse)
      .catch(error => {
        setLeaveBalanceList([])
        setPreviewLeaveBalanceList([])
        Toasts.notifyError("Something went wrong!");


        handleLogError(error);
      })
      .finally(() => setIsLoading(false));
  };


  const handleApiError = (error) => {
    setLeaveBalanceList([]);
    setPreviewLeaveBalanceList([])
    handleLogError(error);
    Toasts.notifyError('Error fetching leave balance data');
  };



  const getUsersList = () => {
    let currentUsername = Auth.getUser().data.preferred_username;
    return users.filter(user => user.username !== currentUsername)
      .map(user => ({
        key: user.username,
        text: user.fullname,
        value: user.username,
      }));
  };


  const Options = [
    { key: 'ADD', value: 'add', text: 'Add' },
    { key: 'DEDUCT', value: 'deduct', text: 'Deduct' },
    { key: 'RESET', value: 'reset', text: 'Reset' },
  ];

  const usersList = getUsersList();

  const dropdownOptions = [
    { key: 'NTS', text: 'NTS', value: 'NTS' },
    { key: 'Faculty', text: 'Faculty', value: 'Faculty' }
  ];


  const allLeaveTypes = [
    { key: 'CL', text: 'CL', value: 'CL' },
    { key: 'COL', text: 'COL', value: 'COL' },
    { key: 'EL', text: 'EL', value: 'EL' },
    { key: 'HPL', text: 'HPL', value: 'HPL' },
    { key: 'RH', text: 'RH', value: 'RH' },
    { key: 'SCL', text: 'SCL', value: 'SCL' },
    { key: 'VL', text: 'VL', value: 'VL' },
    { key: 'PROL', text: 'PROL', value: 'PROL' }
  ];




  const PreviewLeaveBalanceTable = ({ data, users, clickSelection, sortColumn, sortDirection }) => {
    const leaveTypes = useMemo(() => {
      if (!data || !data.Before) return [];
      const types = new Set();
      data.Before.forEach(item => types.add(item.leavetype));
      return Array.from(types);
    }, [data]);

    const sortedData = useMemo(() => {
      if (!data || !data.Before || !data.After || !data.Change || !users) return [];

      const processedData = {};

      // Process Before data
      data.Before.forEach(item => {
        if (!processedData[item.username]) {
          processedData[item.username] = { username: item.username };
        }
        processedData[item.username][`before_${item.leavetype}`] = item.remaining;
      });

      // Process Change data
      data.Change.forEach(item => {
        if (!processedData[item.username]) {
          processedData[item.username] = { username: item.username };
        }
        processedData[item.username][`change_${item.leavetype}`] = item.remaining;
      });

      // Process After data
      data.After.forEach(item => {
        if (!processedData[item.username]) {
          processedData[item.username] = { username: item.username };
        }
        processedData[item.username][`after_${item.leavetype}`] = item.remaining;
      });

      // Combine with user data and convert to array
      const combinedData = users
        .filter(user => processedData[user.username])
        .map(user => {
          return {
            ...user,
            ...processedData[user.username],
            year: data.Before[0]?.year || 'N/A'
          };
        });

      // Sort the data
      return combinedData.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortDirection === 'ascending' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortDirection === 'ascending' ? 1 : -1;
        return 0;
      });
    }, [data, users, sortColumn, sortDirection]);

    if (clickSelection !== 'preview' && clickSelection !== 'update') {
      return null;
    }

    const renderTableHeaders = () => (
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="center" rowSpan="2">S.no.</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" rowSpan="2">Name</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" rowSpan="2">Year</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan={leaveTypes.length}>Before Update</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan={leaveTypes.length}>Leaves Added/Deducted/Reset</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" colSpan={leaveTypes.length}>After Update</Table.HeaderCell>
        </Table.Row>
        <Table.Row  >
          {leaveTypes.map(type => (
            <Table.HeaderCell textAlign="center" key={`before_${type}`}>{type}</Table.HeaderCell>
          ))}
          {leaveTypes.map(type => (
            <Table.HeaderCell textAlign="center" key={`before_${type}`}>{type}</Table.HeaderCell>
          ))}
          {leaveTypes.map(type => (
            <Table.HeaderCell textAlign="center" key={`before_${type}`}>{type}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
    );

    const renderTableRows = () => (
      <Table.Body>
        {sortedData.map((item, index) => (
          <Table.Row key={item.username}>
            <Table.Cell textAlign="center">{index + 1}</Table.Cell>
            <Table.Cell textAlign="center">{item.fullname}</Table.Cell>
            <Table.Cell textAlign="center">{item.year}</Table.Cell>
            {leaveTypes.map(type => (
              <Table.Cell textAlign="center" key={`before_${type}`}>{item[`before_${type}`]}</Table.Cell>
            ))}
            {leaveTypes.map(type => (
              <Table.Cell key={`change_${type}`} textAlign="center">
                {item[`change_${type}`] > 0
                  ? `+${item[`change_${type}`]}`
                  : item[`change_${type}`] < 0
                    ? item[`change_${type}`]
                    : '0'}
              </Table.Cell>
            ))}
            {leaveTypes.map(type => (
              <Table.Cell textAlign="center" key={`after_${type}`}>{item[`after_${type}`]}</Table.Cell>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    );

    return (
      <Table celled>
        {renderTableHeaders()}
        {renderTableRows()}
      </Table>
    );
  };


  const handleViewModeChange = (mode) => {

    setViewMode(mode);
    setSelectedUsers([]);
    setSelectedRoles(mode == "all" ? dropdownOptions.map(option => option.value) : []);
    setFormError('');
  };

  return (
    <>
      <Grid stackable>
        {/* <Segment> */}
        <Grid.Row centered>

          <ButtonGroup>
            <Button color={viewMode === 'person' ? 'green' : undefined} onClick={() => handleViewModeChange('person')}>Person</Button>
            <Button color={viewMode === 'role' ? 'green' : undefined} onClick={() => handleViewModeChange('role')}>Role</Button>
            <Button color={viewMode === 'all' ? 'green' : undefined} onClick={() => handleViewModeChange('all')}>All</Button>
          </ButtonGroup>
        </Grid.Row>
        <Grid.Row centered>
          <Form.Field>
            <Checkbox
              //label={<strong>Converting VL balance to EL balance and resetting VL leaves?</strong>}
              label={<label><strong>Converting VL balance to EL balance and resetting VL leaves?</strong></label>}
              checked={isConversionChecked}
              onChange={handleCheckboxChange}
            />
          </Form.Field>
        </Grid.Row>
        <Grid.Row columns={2} centered >



          <Grid.Column>

            <Form error={!!formError}>



              <Form.Group widths='equal'>
                {viewMode === 'person' && (
                  <Form.Field required>
                    <label>Person</label>
                    <Dropdown
                      closeOnEscape
                      id='username'
                      onChange={handleInputChange}
                      clearable
                      placeholder='Person Name'
                      search
                      fluid
                      multiple
                      selection
                      options={usersList}
                      required
                    />
                  </Form.Field>
                )}
                {viewMode === 'role' && (
                  <Form.Field required>
                    <label>Role</label>
                    <Dropdown
                      closeOnEscape
                      id='rolename'
                      onChange={handleInputChange}
                      clearable
                      placeholder='Role Name'
                      search
                      fluid
                      selection
                      multiple
                      options={dropdownOptions}
                      required
                    />
                  </Form.Field>
                )}

                {!isConversionChecked && (<Form.Field required>
                  <label>Leave Type</label>
                  <Dropdown
                    closeOnEscape
                    id='leavetype'
                    onChange={handleInputChange}
                    clearable
                    placeholder='Leave Type'
                    search
                    fluid
                    selection
                    options={allLeaveTypes}
                    required
                  />
                </Form.Field>)}
              </Form.Group>
              {!isConversionChecked && (<Form.Group widths={'equal'}>
                <Form.Field required>
                  <label>Add/Deduct/Reset</label>
                  <Dropdown
                    closeOnEscape
                    id='addordeduct'
                    onChange={handleInputChange}
                    clearable
                    placeholder='Add/Deduct/Reset'
                    search
                    fluid
                    selection
                    options={Options}
                    required
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Number of Leaves</label>
                  {formData.addordeduct === 'reset' ? (
                    <Label color="red">
                      Number of leaves will be reset to 0
                    </Label>
                  ) : (
                    <Input
                      id='addedordeductedleaves'
                      type='number'
                      onChange={handleInputChange}
                      clearable
                      step="0.5"
                      placeholder='Number of Leaves'
                      search
                      fluid
                      required
                    />
                  )}
                </Form.Field>
              </Form.Group>)}
              <Form.Group widths={'equal'}>
                <Form.Field required>
                  <label>Reason</label>
                  <TextArea rows={5}
                    id='reason'
                    onChange={handleInputChange}
                    clearable
                    placeholder='Reason'
                    search
                    fluid
                    required
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Remarks</label>
                  <TextArea rows={5}
                    id='remarks'
                    onChange={handleInputChange}
                    clearable
                    placeholder='Remarks'
                    search
                    fluid
                    required
                  />
                </Form.Field>
              </Form.Group>
              {errorFields && (
                <Message error onDismiss={() => setErrorFields(false)} visible list={['Please fill all required fields']} />
              )}
              {!(formError === '') && (
                <Message error onDismiss={() => setFormError('')} visible content={formError} />
              )}
              <Button
                // type="submit"
                color='violet'
                fluid
                size='large'
                onClick={(event) => { setClickSelection('view'); handleRefresh(event) }}
                disabled={isButtonDisabled}
              >
                View Current Leave Balance
              </Button>
              <br></br>
              <Button
                // type="submit"
                color='violet'
                fluid
                size='large'
                onClick={(event) => { setClickSelection('preview'); handlePreview(event) }}
                disabled={isButtonDisabled}
              >
                Preview Before Update
              </Button>
              <br></br>

              <Button
                // type="submit"
                color='violet'
                fluid
                size='large'
                onClick={(event) => { setClickSelection('update'); handleUpdate(event) }}
                disabled={isButtonDisabled}
              >
                Update Leave Balance
              </Button>
            </Form>
          </Grid.Column>

        </Grid.Row>
        {/* </Segment> */}

        <Grid.Row>
          <Grid.Column width={16}>
            {isLoading ? (
              <Message icon>
                <Icon name='circle notched' loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  We are fetching that content for you.
                </Message.Content>
              </Message>
            ) : (
              <div>

                {clickSelection === 'view' && (<Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">S.No.</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" sorted={sortColumn === 'fullname' ? sortDirection : null} onClick={() => handleSort('fullname')}>Name</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" sorted={sortColumn === 'year' ? sortDirection : null} onClick={() => handleSort('year')}>Year</Table.HeaderCell>
                      {(selectedLeaveType && !isConversionChecked) ? (
                        <Table.HeaderCell textAlign="center"
                          sorted={sortColumn === selectedLeaveType ? sortDirection : null}
                          onClick={() => handleSort(selectedLeaveType)}
                        >
                          {selectedLeaveType}
                        </Table.HeaderCell>
                      ) : (
                        leaveTypes.map(type => (
                          <Table.HeaderCell textAlign="center"
                            key={type}
                            sorted={sortColumn === type ? sortDirection : null}
                            onClick={() => handleSort(type)}
                          >
                            {type}
                          </Table.HeaderCell>
                        ))
                      )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {renderLeaveBalanceTable()}
                  </Table.Body>
                </Table>)}
                {(clickSelection === 'preview' || clickSelection === 'update') &&
                  <PreviewLeaveBalanceTable
                    data={previewLeaveBalanceList}
                    users={users}
                    clickSelection={clickSelection}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                  />
                }
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default UpdateLeaveBalance;